const ReverseLogo = () => {
  return (
    <>
      <img
        src="./SVGs/SvgComponentReverse.js"
        alt="scholarsift logo"
        style={{ width: 300 }}
      />
    </>
  );
};

export default ReverseLogo;
