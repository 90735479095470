import { useEffect, useRef } from "react";
import ViewPort from "../Layout/ViewPort";
import useLocationState from "../../hooks/useLocationState";
import Slide from "../Layout/Splash/Slide";

const Contact = (props) => {
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    document.title = "ScholarSift - Contact us";
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.id = "twitScript";
    document.querySelector("body")?.appendChild(script);

    return () => {
      document.querySelector("body").removeChild(script);
    };
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const header = (
    <h1 style={{ fontWeight: 600 }}>
      Contact
      <span> us</span>
    </h1>
  );
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">
        Contact
        <span> us</span>
      </h1>
      <section>
        <p>
          Are you a law review editor or a legal scholar who would like to learn
          more about ScholarSift? We are actively engaging with our audience as
          we build out our platform.
        </p>
        <p>
          Reach out to us at{" "}
          <a href="mailto:inquiries@scholarsift.com">
            inquiries@scholarsift.com
          </a>{" "}
          for more information.
        </p>
        <a
          href="https://twitter.com/ScholarSift?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-size="large"
          data-show-count="false"
        >
          Follow @ScholarSift
        </a>
      </section>
    </main>
  );
  return (
    <ViewPort>
      <Slide style={{ height: h.current }} header={header} text={text} />
    </ViewPort>
  );
};

export default Contact;
