import { useEffect } from 'react';
import { useLocation } from 'react-router';
import useLocationState from '../../../hooks/useLocationState';
import OkCancel from '../../Layout/OkCancel';
import SelectionPanel from './SelectionPanel';

const WITHDRAW = 5;

const Withdraw = props => {
    const location = useLocation();
    const { state, dispatch } = useLocationState();
   

    useEffect(() => {
        if(state[location.pathname].items === undefined) {
            let ids = props.item.status.map(v => v.pub);
            let items = Object.keys(props.lawreviews).filter(v => ids.indexOf(Number(v)) > -1).map(v =>  ({name: props.lawreviews[v], id: v}));
            items.sort((a, b) => a.name.localeCompare(b.name) );
            dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: items});
        }
    })

    const onOK = () => {
        props.doUpdate(state[location.pathname].items.filter(v => v.checked).map(v => v.name), WITHDRAW);
        props.onOK(0);
    }

    return  <div>
                <div className="expedite-title-panel">
                    <div className="expedite-title">Withdraw</div>
                    <div className="expedite-sub-title">{props.item.title}</div>
                </div>
                <div className="expedite-panel">
                    <div className="expedite-to">
                        <div className="expedite-title-panel">
                            <div className="expedite-sub-title">From</div>
                        </div>
                        <SelectionPanel />
                    </div>
                    <OkCancel ok="Withdraw" onOK={onOK} onCancel={props.onCancel} />
                </div>
            </div>
}

export default Withdraw;