import { useLocation } from "react-router-dom";
import axios from "axios";

import creds from "../../creds.json";

const Orcid = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.hash.substring(1));

  axios
    .get("https://orcid.org/oauth/jwks")
    .then((result) => {
      let cert = result.data.keys[0];
      let issuer = "https://orcid.org";
      var pubKey = window.KEYUTIL.getKey(cert);
      let id_token = query.get("id_token");

      if (
        window.KJUR.jws.JWS.verifyJWT(id_token, pubKey, {
          alg: ["RS256"],
          iss: issuer,
          aud: creds.orcid.clientId,
          gracePeriod: 15 * 60,
        })
      )
        window.opener.setOrcidId(
          JSON.parse(window.KJUR.jws.JWS.parse(id_token).payloadPP).sub
        );
      window.close();
    })
    .catch((err) => {
      console.log(err);
    });

  return <div>Processing Token</div>;
};

export default Orcid;
