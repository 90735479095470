import { ChevronLeftIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2";

const SlideControls = ({ handleClickLeft, handleClickRight }) => {
  return (
    <div className="slide-controls-horizontal">
      <div
        className="next-icon"
        onClick={handleClickLeft}
        aria-label="Click to scroll previous"
      >
        <ChevronLeftIcon />
      </div>
      <div
        className="next-icon"
        onClick={handleClickRight}
        aria-label="Click to scroll next"
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
};

export default SlideControls;
