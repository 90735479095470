import React, { useEffect, useState } from 'react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Calendar } from '@fluentui/react';
import { getAuthNetProfile } from '../../../actions/AuthNet';
import creds from '../../../creds.json';

const AuthnetSubscriptionInfo = props => {
    const [profile, setProfile] = useState(props.profile)
    const [sub, setSub] = useState();

    useEffect(()=> {
        if(!profile || props.profile !== profile || !sub) {
            setProfile(props.profile);
            setSub(getAuthNetProfile(props.profile))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.profile])

    console.log("SUB:");
    console.log(sub)

    if(sub === -1 || (props.expiration && props.expiration.toString().indexOf("Z") === -1))
    {
        if(!props.renews || sub === -1)
            return <div className="subscription-panel">
                    <div className="subscription-title">No AuthNet Subscription Info</div>
                </div>
    }
    let date = props.expiration && props.expiration.toString().indexOf("Z") > -1 ? new Date(props.expiration) : undefined;
    let renews = props.renews && props.renews !== "0" ? new Date(props.renews) : undefined;
    let ppc = window.location.host.indexOf("puppy") > -1 ? "sandbox" : "production";
    return  props.paid && props.paid < creds.paypal[ppc].plans.length ?
                <div className="subscription-panel">
                    <div className="subscription-title">AuthNet Subscription Info</div>
                    {
                        (date || renews) && 
                        <div className="subscription-exp-wrapper">
                            <div className="subscription-exp">Subscription {date ? "Expiration: " + props.expiration : ("Renews: " + props.renews)}</div>
                            <Calendar value={date ? date : renews} showGoToToday={false}/>
                        </div>
                    }
                    { props.cancelled && <Checkbox checked={true} label="Cancelled"/>}
                </div> : 
                <div className="subscription-panel">
                    <div className="subscription-title">No AuthNet Subscription Info</div>
                </div>
}

export default React.memo(AuthnetSubscriptionInfo);