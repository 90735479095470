import axios from "axios";
import { APIErrorHandler } from "./APIErrorHandler";
//import { planObjects } from '../components/Payment/Plans';
//import creds from '../creds.json';

export const DeleteProfile = (user, saveUser, profile, callBack) => {
  if (!profile) return;
  let d = { profile: profile };
  axios
    .post("/api/delete", d, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((results) => {
      callBack();
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        DeleteProfile(user, saveUser, profile, callBack);
      });
    });
};

export const FetchProfile = (user, saveUser, profile, callBack) => {
  axios
    .post(
      "/api/profile",
      { profile: profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((results) => {
      console.log(results);
      callBack(results.data.pinfo);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        FetchProfile(user, saveUser, profile, callBack);
      });
    });
};

export const UpdateProfileField = (
  user,
  saveUser,
  profile,
  account,
  callBack
) => {
  let p = { ...profile };
  if (account) p.profile = account;
  axios
    .post("/api/update_profile", p, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((results) => {
      if (callBack) callBack(profile);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        FetchProfile(user, saveUser, profile, () => {});
      });
    });
};

export const UpdateProfile = (
  user,
  saveUser,
  profile,
  account,
  callBack,
  name,
  row
) => {
  if (window.location.host.indexOf("scholarsift") === -1) {
    console.log("Updating Profile:");
    console.log(profile);
  }
  let p = { ...profile };
  if (account) p.profile = account;
  axios
    .post("/api/uprofile", p, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((results) => {
      if (window.location.host.indexOf("scholarsift") === -1) {
        console.log(results);
        console.log(user.current.scholarsift.expiration === 0);
        name && console.log(name, row);
      }
      if (user.current.scholarsift.expiration === 0 && results.data.trial) {
        let u = { ...user.current };
        u.scholarsift.expiration = results.data.trial;
        u.scholarsift.paid = 10000;
        saveUser(u);
      }

      if (name) return callBack(name, row, results, "Edit Profile");
      if (callBack) callBack(profile);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        FetchProfile(user, saveUser, profile, () => {});
      });
    });
};

export const subscribePlan = async (user, plan, result) => {
  try {
    const profile = user.current.scholarsift.profile;

    const res = await axios.post(
      "/api/authnet",
      { profile, plan, result },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    );

    return console.log(res.data);
  } catch (err) {
    APIErrorHandler(err, user, () => {
      subscribePlan(user, plan);
    });
    console.error(err);
  }
};

export const CancelPlan = (user, saveUser) => {
  axios
    .post(
      "/api/cancel",
      { plan: user.current.scholarsift.plan },
      {
        headers: {
          Authorization: "Bearer " + user.current.scholarsift.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        CancelPlan(user, saveUser);
      });
    });
};

export const testDeleteProfile = (user, saveUser, profile, callBack) => {
  axios
    .post(
      "/api/cancel",
      { plan: user.current.scholarsift.plan },
      {
        headers: {
          Authorization: "Bearer " + user.current.scholarsift.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        CancelPlan(user, saveUser);
      });
    });
};

export const activateTrial = (user, saveUser, length, callBack) => {
  axios
    .post(
      "/api/activate",
      { profile: user.current.scholarsift.profile, length: length },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then(() => {
      user.current.scholarsift.expiration = 1;
      user.current.scholarsift.paid = 1;
      callBack();
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        activateTrial(user, saveUser, length, callBack);
      });
    });
};
