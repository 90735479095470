import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./components/App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { UserProvider } from "./context/UserContext";
import { LocationStateProvider } from "./context/LocationStateContext";
import { AlertProvider } from "./context/AlertContext";
import { ModalProvider } from "./context/ModalContext";
import { ProfileProvider } from 'ss-lib';
import { HelmetProvider } from "react-helmet-async";
const helmetContext = {}

initializeIcons();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider context={helmetContext}>
    <ProfileProvider>
      <UserProvider>
        <LocationStateProvider>
          <AlertProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </AlertProvider>
        </LocationStateProvider>
      </UserProvider>
    </ProfileProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
