import { Children } from "react";
import { a, useTrail } from "react-spring";

const CardTrail = ({ open, children }) => {
  const bios = Children.toArray(children);
  const trail = useTrail(bios.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? "100%" : 0,
    enter: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <div>
      {trail.map(({ opacity, ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div style={{ opacity }}>{bios[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

export default CardTrail;
