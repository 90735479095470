import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TableCell, TableRow, TableSelectionCell, Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components';
import useLocationState from '../../../hooks/useLocationState';
import ViewPort from "../../Layout/ViewPort";
import { Journals } from 'ss-lib';
import { ListJournals, JournalDelete, JournalUpdate } from './actions';
import useUser from '../../../hooks/useUser';
import { ssToast } from '../../../utils';

const itemRenderer = (props) => {
    const { data, style, index} = props;
    const { item, onClick, onKeyDown, selected, appearance } = data[index];
    return <TableRow aria-rowindex={index + 1} style={style} key={index} appearance={appearance} onClick={onClick} onKeyDown={onKeyDown}><TableSelectionCell checked={selected}/><TableCell style={{width:"100%"}}>{Object.keys(item)[0]}</TableCell></TableRow>
}

const JournalsPage = (props) => {
    const location = useLocation();
    const { dispatchToast } = useToastController(ssToast);
    const { user, saveUser, setTitle } = useUser();
    const [name, setName] = useState();
    const [journals, setJournals] = useState();
    const [fetching, setFetching] = useState(false);
    const { state } = useLocationState();
    const h = useRef();

    useEffect(() => {
        window.title = "ScholarSift - Journal Options";
        setTitle("Journal Options");
        if(!journals) {
            if(!fetching) {
                console.log("Fetching")
                setFetching(true);
                ListJournals(user, saveUser, setJournals, setFetching);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    const DeleteJournal = v => {
        var j = journals.filter(vv => v === vv.name);
        if(!j || j.length === 0)
            return ;
        JournalDelete(user, saveUser, j[0].id, () => { 
            setName(undefined);
            setJournals(journals.filter(vv => v !== vv.name))
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have successfully deleted {v}</ToastBody></Toast>, {intent: "success"})
        })
    }

    const SaveJournal = v => {
        var j = journals.filter(vv => v.k === vv.name);
        if(!j || j.length === 0)
            return ;
        var journal = {id: j[0].id, name: v.n, settings: v.j};

        //add name/setname
        JournalUpdate(user, saveUser, journal, () => { 
            setName(v.n); 
            setJournals([...journals.filter(vv => v.k !== vv.name), journal]); 
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have successfully updated {v.n}</ToastBody></Toast>, {intent: "success"})
        })
    }

    const UpdateJournal = v => {
        console.log("UJ")
        var j = journals.filter(vv => v.k === vv.name);
        if(!j || j.length === 0)
            return ;

        var journal = {id: j[0].id, name: v.n, settings: v.j};
        console.log(journals)
        console.log(journal)
        //add name/setname
        setJournals([...journals.filter(vv => v.k !== vv.name), journal]);    
    }
    console.log("rendering vp")
    console.log(journals)
    return  <ViewPort>
                <div style={{minHeight: h.current, width: '100%'}}>
                    <Journals itemRenderer={itemRenderer} journals={journals ? journals.map(v => ({[v.name] : v.settings})) : undefined} DeleteJournal={DeleteJournal} SaveJournal={SaveJournal} UpdateJournal={UpdateJournal} name={name} setName={setName}/>
                </div>
            </ViewPort>
}

export default JournalsPage;