import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsageBarChart from "./UsageBarChart";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import { GetUsage } from "../../../actions/Dashboard";
import { delay } from "../../../utils";
import ViewPort from "../../Layout/ViewPort";
import Loading from "../../Layout/Loading";
import {
  makeStyles,
  shorthands,
  useId,
  Card,
  CardHeader,
  Dropdown,
  Option,
  Text,
  Tooltip,
  Button,
} from "@fluentui/react-components";
import {
  ArrowSortDown24Regular,
  ArrowSortUp24Regular,
  DataBarVertical24Filled,
  DataBarHorizontal24Filled,
} from "@fluentui/react-icons";
import { Debug } from 'ss-lib';

export const UsageLabels = [
  "Logins",
  "Analyze",
  "Downloads",
  "Uploads",
  "Citation Edits",
  "Profile Edits",
  "Created Users",
  "Claimed Items",
  "Paper Search",
  "Author Search",
  "Reserved",
  "NeedsCitation",
  "Auto Complete",
  "Citation Formatter",
  "Analyze (Addin)",
  "Search",
  "Comment",
  "Like",
];

export const TickFormatter = (v) => {
  if (v.indexOf(" ") === -1) return <tspan>{v}</tspan>;
  let strs = v.split(" ");
  let ret = [];
  strs.forEach((vv, i) => {
    ret.push(
      <tspan x="0" dy="1em" key={i}>
        {vv}
      </tspan>
    );
  });
  return <tspan>{ret}</tspan>;
};

const flex = {
  ...shorthands.gap("30px"),
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const useStyles = makeStyles({
  main: {
    ...flex,
    flexDirection: "column",
    ...shorthands.margin("20px", "auto", "20px", "auto"),
    width: "fit-content",
  },
  row: {
    ...flex,
    flexDirection: "row",
  },
  zoomCard: {
    width: "100vw",
    height: "100vh",
    zIndex: 1000,
    ...shorthands.margin("20px", "auto", "20px", "auto"),
  },
  card: {
    width: "450px",
    maxWidth: "100%",
    height: "450px",
    ...shorthands.margin("10px", "auto", "10px", "auto"),
  },
  bigCard: {
    width: "600px",
    maxWidth: "100%",
    height: "330px",
  },
  header: {
    marginLeft: "10px",
    marginBottom: "20px",
  },
  actions: {
    display: "flex",
  },
});

const Usage = (props) => {
  const [zoom, setZoom] = useState(0);
  const [usage, setUsage] = useState();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [layout, setLayout] = useState(true);
  const { user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [isLoading, setIsLoading] = useState(false);
  const [displayClass, setDisplayClass] = useState("square");
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState(false);
  const [defaultValue, setDefaultValue] = useState("Last 7 Days");
  const styles = useStyles();
  const h = useRef();

  if(Debug())
    console.log(items);

  // useEffect(() => (document.title = "ScholarSift - Usage Dashboard")); // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    GetUsage(user, 0, 0, 7, saveUser, setUsage, setIsLoading, fatalError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, saveUser]);

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const options = [
    { key: "Today", text: "Today", timeframe: 0, offset: 0, length: 1 },
    {
      key: "last7Days",
      text: "Last 7 Days",
      timeframe: 0,
      offset: 0,
      length: 7,
    },
    {
      key: "last30Days",
      text: "Last 30 Days",
      timeframe: 0,
      offset: 0,
      length: 30,
    },
    {
      key: "last12Months",
      text: "Last 12 Months",
      timeframe: 1,
      offset: 0,
      length: 12,
    },
    {
      key: "last4Years",
      text: "Last 5 Years",
      timeframe: 2,
      offset: 0,
      length: 5,
    },
  ];

  const fatalError = (e, callBack) => {
    if (e === "login") {
      dispatch({
        type: "message-update",
        key: "info",
        payload: "Please login again.",
      });
      navigate("/signin", { goback: true, callBack: callBack });
    } else if (e === "server down") {
      let ct = 0;
      while (ct < 30) {
        // eslint-disable-next-line no-loop-func
        delay(1000).then(() => {
          ct++;
          dispatch({
            type: "message-update",
            key: "error",
            payload: "Server is down.  Retrying in..." + (30 - ct),
          });
        });
      }
      callBack();
    }
  };

  const toggleSize = (e, key) => {
    e.preventDefault();
    if (e.target.index === key) {
      setZoom(key);
    }
  };

  const toggleSwitch = (e) => {
    e.preventDefault();
    setChecked(!checked);
  };

  const toggleLayout = (e) => {
    e.preventDefault();
    setLayout(!layout);
  };

  const dropdownId = useId("dropdown");

  const handleChange = (e, data) => {
    setDefaultValue(data.optionText);
    const selectedItem = options.find((o) => o.text === data.optionText);
    GetUsage(
      user,
      selectedItem.timeframe,
      selectedItem.offset,
      selectedItem.length,
      saveUser,
      setUsage,
      setIsLoading,
      fatalError
    );
  };

  const GetItems = () => {
    if (!usage) return null;

    let ret = [];

    let keys = Object.keys(usage);
    keys.sort((a, b) => new Date(b) - new Date(a));

    let tot = {};
    keys.forEach((v, i) => {
      let ks = Object.keys(usage[v]);
      if (v.length === 4) {
        let year = {};
        const vs = Object.values(usage[v]);
        vs.map((y) =>
          Object.keys(y).forEach((k) => {
            if (year[k] === undefined) year[k] = [...y[k]];
            else {
              year[k].forEach((vvv, i) => {
                year[k][i] += vvv;
              });
            }
          })
        );

        ret.push(
          <Card
            /* className={zoom === i ? styles.zoomCard : styles.card} */
            className={styles.card}
            onClick={toggleSize}
          >
            <CardHeader
              header={
                <Text weight="semibold">
                  {new Date(v).toLocaleString("default", { year: "numeric" })}
                </Text>
              }
            />
            <UsageBarChart
              zoom={zoom}
              key={i}
              layout={layout ? "vertical" : "horizontal"}
              checked={checked}
              name={v}
              type="year"
              items={year}
              index={i}
              data-index={i}
              onClick={(e, i) => setZoom(i)}
              displayClass={displayClass}
            />
          </Card>
        );
      } else if (v.length === 10) {
        ret.push(
          <Card
            /* className={zoom === i ? styles.zoomCard : styles.card} */
            className={styles.card}
            onClick={toggleSize}
          >
            <CardHeader
              header={
                <Text weight="semibold" size={400}>
                  {defaultValue === "Today"
                    ? new Date().toLocaleDateString(undefined, {
                        weekday: "long",
                        day: "numeric",
                        month: "short",
                      })
                    : new Date(v).toLocaleString(undefined, {
                        weekday: "long",
                        day: "numeric",
                        month: "short",
                      })}
                </Text>
              }
            />
            <UsageBarChart
              layout={layout ? "vertical" : "horizontal"}
              zoom={zoom}
              key={i}
              type="day"
              checked={checked}
              name={v}
              items={usage[v]}
              index={i}
              data-index={i}
              onClick={(e, i) => setZoom(i)}
              displayClass={displayClass}
            />
          </Card>
        );
      } else if (v.length === 7) {
        let mo = {};
        const vs = Object.values(usage[v]);
        vs.map((m) =>
          Object.keys(m).forEach((k) => {
            if (mo[k] === undefined) mo[k] = [...m[k]];
            else {
              mo[k].forEach((vvv, index) => {
                mo[k][index] += vvv;
              });
            }
          })
        );

        ret.push(
          <Card
            /* className={zoom === i ? styles.zoomCard : styles.card} */
            className={styles.card}
            onClick={toggleSize}
          >
            <CardHeader
              header={
                <Text weight="semibold" size={400}>
                  {new Date(v).toLocaleString(undefined, {
                    month: "short",
                    year: "numeric",
                  })}
                </Text>
              }
            />
            <UsageBarChart
              layout={layout ? "vertical" : "horizontal"}
              zoom={zoom}
              key={i}
              checked={checked}
              name={v}
              type="month"
              items={mo}
              index={i}
              data-index={i}
              onClick={(e, i) => setZoom(i)}
              displayClass={displayClass}
            />
          </Card>
        );
      }

      if (v.length === 10) {
        ks.forEach((vv) => {
          if (tot[vv] === undefined) tot[vv] = [...usage[v][vv]];
          else {
            usage[v][vv].forEach((vvv, i) => {
              tot[vv][i] += vvv;
            });
          }
        });
      }
    });

    if (sort) ret.reverse();
    if (Object.keys(tot).length && defaultValue !== "Today") {
      ret.unshift(
        <Card
          /* className={zoom === i ? styles.zoomCard : styles.card} */
          className={styles.card}
          onClick={toggleSize}
        >
          <CardHeader
            header={
              <Text weight="semibold" size={400}>
                Total
              </Text>
            }
          />
          <UsageBarChart
            layout={layout ? "vertical" : "horizontal"}
            zoom={zoom}
            key={keys.length}
            checked={checked}
            items={tot}
            index={options.length}
            onClick={(e, i) => setZoom(i)}
            displayClass={displayClass}
          />
          {/*           <UsageLineChart
            zoom={zoom}
            key={keys.length}
            checked={checked}
            items={tot}
            index={options.length}
            onClick={(e, i) => setZoom(i)}
            displayClass={displayClass}
          /> */}
        </Card>
      );
    }
    return ret;
  };
  return isLoading ? (
    <Loading />
  ) : (
    <ViewPort>
      <div
        style={{
          minHeight: h.current,
          padding: 30,
          justifyContent: "center",
        }}
      >
        <div className={styles.main}>
          <div className={styles.row}>{items}</div>
        </div>
        <div className="display-header">
          <div className="display-name" aria-label="usage">
            Usage
          </div>
          <div className="usage-time-wrapper">
            <div key="buttons">
              <Tooltip content="Change Layout">
                <Button
                  aria-label="Change Layout"
                  appearance="subtle"
                  onClick={toggleLayout}
                  icon={
                    layout ? (
                      <DataBarVertical24Filled />
                    ) : (
                      <DataBarHorizontal24Filled />
                    )
                  }
                >
                  {layout ? "Vertical" : "Horizontal"}
                </Button>
              </Tooltip>
              <Tooltip content="Change scale">
                <Button
                  aria-label="Change scale"
                  appearance="subtle"
                  onClick={toggleSwitch}
                >
                  {checked ? "Symlog" : "Linear"}
                </Button>
              </Tooltip>
              <Tooltip content="Sort">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setSort(!sort);
                  }}
                  disabled={defaultValue === "Today"}
                  appearance="subtle"
                  aria-label="sort"
                  icon={
                    sort ? <ArrowSortUp24Regular /> : <ArrowSortDown24Regular />
                  }
                >
                  {sort ? "Ascending" : "Descending"}
                </Button>
              </Tooltip>
            </div>
            <Dropdown
              key="dropdown"
              aria-label="usage period selector"
              aria-labelledby={dropdownId}
              className="usage-time-selector"
              onOptionSelect={(e, data) => handleChange(e, data)}
              defaultValue={defaultValue}
              readOnly
              size="large"
            >
              {options.map((o, i) => (
                <Option key={o.key}>{o.text}</Option>
              ))}
            </Dropdown>
          </div>
        </div>

        <div className="display-body">{GetItems()}</div>
      </div>
    </ViewPort>
  );
};

export default Usage;
