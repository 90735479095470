import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
    Checkbox,
    ComboBox,
    Icon,
    Modal,
    PrimaryButton,
    Selection,
} from '@fluentui/react';
import SubmissionPortalList from "./SubmissionPortalList";
import { v4 as uuid } from "uuid";
import useUser from '../../../hooks/useUser';
import axios from 'axios';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import { assignmentTasks, editorStatusItems, statusItems } from '../../../utils';
import useLocationState from '../../../hooks/useLocationState';
import usePrevious from '../../../hooks/usePrevious';
import Loading from '../../Layout/Loading';
import BasicHeader from './BasicHeader';
import CloseableHeader from './ClosableHeader';
import FilterableHeader from './FilterableHeader';
import RangeableHeader from './RangeableHeader';
import { Debug } from 'ss-lib'

export const getItemRange = (items, column) => {
    if (items === undefined)
        return;
    let mn = Number.MAX_SAFE_INTEGER;
    let mx = Number.MIN_SAFE_INTEGER;
    let val = 0;
    items.forEach(v => {
        if (!(v.stats === null || v.stats === undefined || v.stats.length === 0)) {
            let stats = JSON.parse(v.stats);
            switch (column.key) {
                case 'above':
                case 'below':
                    val = stats[column.key];
                    if (val < mn)
                        mn = val;
                    if (val > mx)
                        mx = val;
                    break;
                case 'rage':
                case 'rtext':
                    let related = stats.related;
                    let key = column.key.substring(1);
                    if (related[key].length < mn)
                        mn = related[key].length;
                    if (related[key].length > mx)
                        mx = related[key].length;
                    break;
                case 'total':
                    val = stats['above'] + stats['below'];
                    if (val < mn)
                        mn = val;
                    if (val > mx)
                        mx = val;
                    break;
                default:
                    break;
            }
        }
    });
    if (mn !== Number.MAX_SAFE_INTEGER && mx !== Number.MIN_SAFE_INTEGER) {
        return [mn, mx];
    }
    return [0, 0];
}

const prepareItems = items => {
    if (items === undefined)
        return [];

    return items.map((v, i) => {
        console.log(v)
        let itm = { ...v };
        itm.propsIndex = i;

        //Expand Vars
        let s = new Set();
        if (itm.demographics) {
            itm.demographics.forEach(v => {
                if (v.black)
                    s.add("African American / Black");
                if (v.hispanic)
                    s.add("Hispanic / Latino / Latin");
                if (v.native)
                    s.add("American Indian / Alaska Native");
                if (v.asian)
                    s.add("Asian / Asian American");
                if (v.islander)
                    s.add("Pacific Islander");
                if (v.seana)
                    s.add("Southwest Asian / North African");
                if (v.white)
                    s.add("White");
            })
        }
        itm.ethnicity = "";
        new Array(...s).sort().forEach(vv => { itm.ethnicity += vv + "\n"; })

       /*
        itm.topics = "";
        try {
            var stats = itm.stats && itm.stats.length ? JSON.parse(itm.stats) : {};

            Object.keys(stats).filter(k => Array.isArray(stats[k])).sort((a, b) => stats[b].length - stats[a].length).forEach((k, i) => {
                //This can be updated to include subtypes and whatnot
                if (i < 5 && stats[k].length > 4)
                    itm.topics += k + " (" + stats[k].length + ")\n";
            })
        } catch (ex) { console.log(ex); console.log("Value: " + itm.stats) }
         */
        var stats = itm.stats && itm.stats.length ? JSON.parse(itm.stats) : {};
        if(stats.topics)
            itm.topics = {...stats.topics};
        itm.msg = "";
        switch (itm.status) {
            case -1:
                itm.msg = "Previously Published";
                break;
            default:
                if (itm.status < statusItems.length)
                    itm.msg = statusItems[itm.status];
                break;
        }
        itm.tasks = [];
        if (itm.editors)
            itm.tasks = itm.editors.map(vv => <div key={uuid()}><div>{vv.task + " - " + vv.status}</div><div>{vv.email}</div></div>);

        return itm;
    })
}

const SubmissionPortalPanel = props => {

    const { state } = useLocationState();
    const location = useLocation();
    const { user, saveUser } = useUser();
    const [panel, setPanel] = useState();
    const [isModal, setIsModal] = useState(false);
    const selectedRef = useRef([]);

    const statusIndex = useRef();
    const statusItem = useRef();
    const statusUpdate = useRef();

    const assignmentItem = useRef();
    const assignmentTaskIndex = useRef();
    const assignmentTaskValue = useRef();
    const assignmentUserIndex = useRef();
    const emailAssignment = useRef();

    const [columns, setColumns] = useState();
    const [items, setItems] = useState(prepareItems(props.items));

    const [fItems, setFiltered] = useState();
    const [ColumnList, setColumnList] = useState();

    const [headerText, setHeaderText] = useState("");

    const iRef = useRef(items);
    const pRef = useRef();
    const cRef = useRef(columns);
    const lRef = useRef(ColumnList);

    const dragStart = useRef();
    const dragStop = useRef();

    const filterKey = useRef();

    const [selectionCount, setSelectionCount] = useState(0)
    const [selection] = useState(new Selection({
        onSelectionChanged: () => { setSelectionCount(selection.count); }
    }));

    const h = useRef();

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + (state.viewport.o ? state.viewport.o : 0));
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    useEffect(() => {
        iRef.current = items;
    }, [items])

    useEffect(() => {
        cRef.current = columns;
    }, [columns]);

    useEffect(() => {
        lRef.current = ColumnList;
    }, [ColumnList])

    useEffect(() => { !user.current.scholarsift.profile && <Navigate to="/signin" /> }, [user])

    const setDragStart = v => {
        dragStart.current = v;
    }

    const setDragStop = v => {
        dragStop.current = v;
    }

    const handleDrop = after => {
        let p = cRef.current.filter(v => v.name === dragStart.current)[0];
        let cols = cRef.current.filter(v => v.name !== dragStart.current);
        let idx = 0;
        cols.forEach((v, i) => {
            if(v.name === dragStop.current)
                idx = i;
        });
        if(after)
            ++idx;
        cols.splice(idx, 0, p);
        let ords = cols.map(v => v.order);
        props.setOrder(ords);
        setColumns(cols);
    }

    useEffect(() => {

        if (!props.items || props.items.length === 0) {
            if (!props.items)
                setItems([]);
            return;
        }

        //Prep Items
        let itms = prepareItems(props.items);

        //Set Columns
        let o = 0;
        const columnList = location.pathname.indexOf("todo") === -1 ?
            [
                {
                    key: "mtype",
                    name: "Type",
                    onRenderHeader: p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    minWidth: 125,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "title",
                    name: "Title",
                    onRenderHeader: p => <BasicHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}/>,
                    minWidth: 250,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "status",
                    name: "Status",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 200,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "assignments",
                    name: "Assignments",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 200,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "topics",
                    name: "Topics",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 200,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "citations",
                    name: "Citations",
                    minWidth: 150,
                    onRenderHeader: p => <CloseableHeader column={p.column} {...props} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}/>,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "analyze",
                    name: "Analyze",
                    minWidth: 150,
                    onRenderHeader: p => <CloseableHeader column={p.column} {...props} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}/>,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "total",
                    name: "Total",
                    onRenderHeader: p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn}  setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} />,
                    type: "Rangeable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "above",
                    name: "Above",
                    onRenderHeader: p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}  />,
                    type: "Rangeable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "below",
                    name: "Below",
                    onRenderHeader: p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn}  setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} />,
                    type: "Rangeable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "tags",
                    name: "Tags",
                    onRenderHeader: p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 125,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "notes",
                    name: "Notes",
                    onRenderHeader: p => <CloseableHeader column={p.column} {...props}  setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}/>,
                    minWidth: 175,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "abstract",
                    name: "Abstract",
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "cover",
                    name: "Cover",
                    onRenderHeader: p => <CloseableHeader column={p.column}  setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    minWidth: 125,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "cv",
                    name: "CV",
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "authors",
                    name: "Author(s)",
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "atitle",
                    minWidth: 200,
                    name: "Author Title(s)",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "role",
                    name: "Role",
                    onRenderHeader: p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    isResizeable: true,
                    minWidth: 125,
                    order: o++
                },
                {
                    key: "gender",
                    name: "Gender",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "ethnicity",
                    name: "Ethnicity",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "orientation",
                    name: "Orientation",
                    minWidth: 175,
                    onRenderHeader: p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    isResizeable: true,
                    order: o++
                }
            ] :
            [
                {
                    key: "title",
                    name: "Title",
                    minWidth: 250,
                    onRenderHeader: p => <BasicHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} />,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "assignments",
                    name: "Assignment",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 200,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "status",
                    name: "Status",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    isResizeable: true,
                    minWidth: 150,
                    order: o++
                },
                {
                    key: "tags",
                    name: "Tags",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 125,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "notes",
                    name: "Notes",
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    minWidth: 175,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "analyze",
                    name: "Analyze",
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "topics",
                    name: "Topics",
                    onRenderHeader:  p => <FilterableHeader 
                                            column={p.column} 
                                            itemsRef={iRef} 
                                            setDragStart={setDragStart}
                                            setDragStop={setDragStop}
                                            handleDrop={handleDrop}
                                            pRef={pRef}
                                            filterKey={filterKey}
                                            processFilter={processFilter}
                                            setFiltered={setFiltered}
                                            removeColumn={props.removeColumn} 
                                        />,
                    type: "Filterable",
                    minWidth: 200,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "citations",
                    name: "Citations",
                    minWidth: 150,
                    onRenderHeader: p => <CloseableHeader column={p.column} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop} {...props} />,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "total",
                    name: "Total",
                    onRenderHeader:  p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}  />,
                    type: "Rangeable",
                    isResizeable: true,
                    minWidth: 150,
                    order: o++
                },
                {
                    key: "above",
                    name: "Above",
                    onRenderHeader:  p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}  />,
                    type: "Rangeable",
                    minWidth: 150,
                    isResizeable: true,
                    order: o++
                },
                {
                    key: "below",
                    name: "Below",
                    onRenderHeader: p => <RangeableHeader column={p.column} itemsRef={iRef} cRef={cRef} filterKey={filterKey} processRange={processRange} removeColumn={props.removeColumn} setDragStart={setDragStart} setDragStop={setDragStop} handleDrop={handleDrop}  />,
                    type: "Rangeable",
                    isResizeable: true,
                    minWidth: 150,
                    order: o++
                }
            ]

        setColumnList(columnList);

        //Columns
        let cols = [];

        //Order
        let order = props.order ? [...props.order] : [];
        if (order.length === 0)
            columnList.forEach((_, i) => { order.push(i) });

        //Add
        for (var i = 0; i < order.length; i++)
            if (props.omitted.indexOf(order[i]) === -1)
                cols.push(columnList[order[i]]);

        //Set
        setColumns(cols);
        setItems(itms);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items, props.omitted, props.order]);

    const finishTags = (d, retries) => {
        if (retries && retries < 5)
            return;

        axios.post("/api/tagsel", d, {
            headers: {
                "Authorization": "Bearer " + user.current.token.id,
                "Coda": user.current.scholarsift.coda
            }
        })
            .then(results => {
                props.processWS({ key: "portal/tagSelection", data: d })
            })
            .catch(error => {
                APIErrorHandler(error, user, saveUser, () => { finishTags(d, retries ? retries + 1 : 1) })
            })
    }

    const processTags = (selected, item, wsOnly) => {
        if (wsOnly)
            props.processWS({ key: "portal/tagSelection", data: { id: item.id, selected: selected } });
        else
            finishTags({ id: item.id, selected: selected })
    }

    const finishStatus = retries => {
        if (retries && retries > 5)
            return;

        let d = {
            id: statusItem.current ? statusItem.current.id : selection.getSelectedIndices(),
            status: window.location.pathname.indexOf("todo") > -1 ? undefined : statusIndex.current,
            update: statusUpdate.current,
            lawReview: statusItem.current ? statusItem.current["law review"] : undefined,
            task: window.location.pathname.indexOf("todo") > -1 ? editorStatusItems[statusIndex.current] : undefined,
            email: user.current.scholarsift.email
        };

        if (statusItem.current === undefined || statusItem.current.status !== statusIndex.current) {
            axios.post("/api/lrstatus", d, {
                headers: {
                    "Authorization": "Bearer " + user.current.token.id,
                    "Coda": user.current.scholarsift.coda
                }
            })
                .then(results => {
                    let itms = [...items];
                    itms.forEach(v => {
                        if (v.id === d.id) {
                            v.status = window.location.pathname.indexOf("todo") > -1 ? d.task : statusItems[d.status];
                            setItems(itms);
                            props.processWS({ key: window.location.pathname.indexOf("todo") === -1 ? "portal/status" : "portal/task", data: d })
                        }
                    })
                })
                .catch(error => {
                    APIErrorHandler(error, user, saveUser, () => { finishStatus(retries ? retries + 1 : 1) })
                })
        }
    }

    const handleNotes = (newValue, nItem) => {
        props.processWS({ key: "portal/notes", data: { notes: newValue, id: nItem.id } });
    }

    const finishNotes = (d, retries) => {
        if (retries && retries > 5)
            return;
        axios.post("/api/lrstatus", d, {
            headers: {
                "Authorization": "Bearer " + user.current.token.id,
                "Coda": user.current.scholarsift.coda
            }
        })
            .then(results => {
                let itms = [...items];
                itms.forEach(v => {
                    if (v.id === d.id) {
                        v.notes = d.notes;
                        setItems(itms)
                    }
                })
            })
            .catch(error => {
                APIErrorHandler(error, user, saveUser, () => { finishNotes(d, retries ? retries + 1 : 1) })
            })
    }

    const processNotes = (notes, nItem) => {
        if (notes !== nItem.notes)
            finishNotes({ id: nItem.id, lid: nItem["law review"], notes: notes });
    }

    const processStatus = item => {
        if (item) {
            statusItem.current = item;
            statusIndex.current = item.status;
        }
        setHeaderText("Update" + (item ? " " + (item.mytype ? item.mtype : item.title) : "") + " Status");
        setPanel(<div key={uuid()} className="modal-status">
            <ComboBox
                allowFreeform={false}
                options={location.pathname.indexOf("todo") === -1 ?
                    statusItems.filter((v, i) => i < 4).map((v, i) => ({ key: i, text: v })) :
                    editorStatusItems.map((v, i) => ({ key: i, text: v }))
                }
                defaultSelectedKey={statusIndex.current}
                onChange={(_, __, index) => { statusIndex.current = index }}
            />
            <div className="flex-row-between">
                <PrimaryButton className="modal-button" text="Update Status" onClick={() => { setIsModal(false); finishStatus() }} />
                {location.pathname.indexOf("todo") === -1 && <Checkbox label="Notify Authors" checked={statusUpdate.current} onChange={(ev, isChecked) => { statusUpdate.current = isChecked }} />}
            </div>
        </div>)
        setIsModal(true)
    }

    const finishAssignments = retries => {
        if (retries && retries > 5)
            return;

        if(Debug()) console.log(items)
        if(Debug()) console.log(selection.getSelectedIndices()[0])
        if(Debug()) console.log(items[selection.getSelectedIndices()[0]], true);

        let d = {
            id: assignmentItem.current ? assignmentItem.current.id : items[selection.getSelectedIndices()[0]].id,
            user: props.users[assignmentUserIndex.current],
            taskIndex: assignmentTaskIndex.current,
            taskValue: assignmentTaskValue.current,
            tasks: user.current.scholarsift.assignmentTasks ? [...assignmentTasks, ...user.current.scholarsift.assignmentTasks] : [...assignmentTasks],
            email: emailAssignment.current
        };

        if (assignmentItem.current === undefined || assignmentItem.current.task !== assignmentTaskIndex.current || assignmentItem.current.assigned !== assignmentUserIndex.current) {
            axios.post("/api/lrtask", d, {
                headers: {
                    "Authorization": "Bearer " + user.current.token.id,
                    "Coda": user.current.scholarsift.coda
                }
            })
                .then(results => {
                    if (results.data.assignmentTasks)
                        d.assignmentTasks = results.data.assignmentTasks;
                    props.processWS({ key: "portal/assignments", data: d })
                    props.FetchSubmissions();
                })
                .catch(error => {
                    APIErrorHandler(error, user, saveUser, () => { finishAssignments(retries ? retries + 1 : 1) })
                })
        }
    }

    const processAssignments = item => {
        let editor = item.editors && item.editors.length > 0 ? item.editors[0] : undefined;
        let defaultAssignment = "";
        assignmentItem.current = item;
        if (editor) {
            assignmentTaskIndex.current = editor.task && assignmentTasks.indexOf(editor.task) > -1 ? assignmentTasks.indexOf(editor.task) : 0;
            assignmentUserIndex.current = 0;
            props.users.forEach((v, i) => {
                if (v.email === editor.email) {
                    defaultAssignment = v.email;
                    assignmentUserIndex.current = i;
                }
            })
            assignmentTaskValue.current = editor.task ? editor.task : undefined;
        }

        if (window.location.pathname.indexOf("scholarsift") === -1) {
            console.log(assignmentItem.current)
            console.log(assignmentTaskIndex.current)
            console.log(assignmentUserIndex.current)
            console.log(assignmentTaskValue.current)
        }

        emailAssignment.current = true;
        setHeaderText(item ? item.title : "Update Assignment");
        let tasks = user.current.scholarsift.assignmentTasks ? [...assignmentTasks, ...user.current.scholarsift.assignmentTasks] : [...assignmentTasks];
        setPanel(<div key={uuid()} className="modal-status">
            <ComboBox
                label="Assign to"
                allowFreeform={false}
                options={props.users.map((v, i) => ({ key: v.email, text: v.first + " " + v.last }))}
                defaultSelectedKey={defaultAssignment}
                onChange={(_, __, index) => { assignmentUserIndex.current = index }}
            />
            <ComboBox
                label="Task"
                allowFreeForm={true}
                options={tasks.map(v => ({ key: v, text: v }))}
                defaultSelectedKey={assignmentTaskValue.current}
                onChange={(_, __, index, value) => { assignmentTaskIndex.current = index; assignmentTaskValue.current = value }}
            />
            <div className="flex-row-between">
                <PrimaryButton className="modal-button" text="Update Assignment" onClick={() => { setIsModal(false); finishAssignments() }} />
                <Checkbox label="Notify Editor" defaultChecked={true} onChange={(ev, isChecked) => { emailAssignment.current = isChecked; console.log(emailAssignment.current) }} />
            </div>
        </div>)
        setIsModal(true)
    }

    const processFilter = (filterSel, fm) => {
        if (filterSel === undefined) {
            if (filterKey.current) {
                let itms = prepareItems(props.items);
                let cols = [...cRef.current];
                let col;
                cols.forEach(v => { if (v.key === filterKey.current) col = v; })
                if (col)
                    col.filter = undefined;
                setItems(itms);
                setColumns(cols);
                filterKey.current = undefined;
            }
            return;
        }

        let itms = prepareItems(props.items);
        if(cRef.current === undefined)
            return ;
        let col = undefined;
        cRef.current.forEach(v => { if (v.key === filterKey.current) col = v; });
        let fv = col && col.filter && col.filter.items ?
            Object.keys(col.filter.items).sort() :
            fm ? Object.keys(fm).sort() : [];
        let sel = filterSel.getSelectedIndices();
        if (sel.length > 0) {
            let s = new Set();
            if (fItems)
                sel.forEach(v => fm[fItems[v][col.name]].forEach(vv => { s.add(itms[vv]) }))
            else
                sel.forEach(v => { fm[fv[v]].forEach(vv => { s.add(itms[vv]) }) })
            if (s.size !== items.length) {
                if (s.size) {
                    setItems([...s]);
                    if(col)
                        col.filter = { items: fm, selected: sel };
                    setColumns([...cRef.current])
                }
            }
        }
        else {
            setItems(pRef.current && pRef.current.length > items.length ? [...pRef.current] : itms);
            col.filter = undefined;
            setColumns([...cRef.current]);
        }
    }

    const processRange = (selectedRange, itemRange) => {
        let itms = prepareItems(props.items);
        let column;
        let cols = cRef.current ? [...cRef.current] : [];
        cols.forEach(v => { if (v.key === filterKey.current) column = v; })
        if (column)
            column.filter = undefined;
        else
        {
            setItems(itms);
            filterKey.current = undefined;
            return ;
        }    

        if (selectedRange === undefined) {
            if (filterKey.current) {
                setItems(itms);
                setColumns(cols);
                filterKey.current = undefined;
                column.filter = undefined;
                return;
            }
        }
        
        let smin = selectedRange.sMin;
        if(smin === undefined)
            smin = itemRange[0];

        let smax = selectedRange.sMax;
        if(smax === undefined)
            smax = itemRange[1];

        if(smin === itemRange[0] && smax === itemRange[1])
        {
            column.filter = undefined;
            setItems(itms);
            setColumns(cols);
            return ;
        }

        itms = itms.filter(v => {
            if (!v.stats || !v.stats.length)
                return false;
            
            let stats = JSON.parse(v.stats);
            switch (filterKey.current) {
                case 'above':
                case 'below':
                    let val = stats[filterKey.current];
                    return val >= smin && val <= smax;
                case 'total':
                    let v = (stats['above'] ? stats['above'] : 0) + (stats['below'] ? stats['below'] : 0);
                    return v >= smin && v <= smax;
                case 'rage':
                case 'rtext':
                    let related = { stats };
                    let k = filterKey.current.substring(1);
                    return related[k].length >= smin && related[k].length <= smax;
                default:
                    return false;
            }
        })

        itms.sort((a, b) => {
            var as = JSON.parse(a.stats);
            var bs = JSON.parse(b.stats);

            switch (filterKey.current) {
                case 'above':
                case 'below':
                    return bs[filterKey.current] - as[filterKey.current];
                case 'total':
                    let av = (as['above'] ? as['above'] : 0) + (as['below'] ? as['below'] : 0);
                    let bv = (bs['above'] ? bs['above'] : 0) + (bs['below'] ? bs['below'] : 0);
                    
                    return bv - av;
                case 'rage':
                case 'rtext':
                    let ar = as.related;
                    let br = bs.related;
                    let k = filterKey.current.substring(1);

                    return br[k].length - ar[k].length;
                default:
                    return 0;
            }
        })
        
        filterKey.current = undefined;
        column.filter = { rMin: itemRange[0], rMax: itemRange[1], sMin: smin, sMax: smax };
        setColumns(cols);
        setItems(itms);
    }

    const getEditors = editors => {
        let e = [];
        props.users.forEach(v => {
            editors.forEach(vv => {
                if (v.email === vv.email) {
                    e.push(v.profile)
                    if (v.others)
                        v.others.forEach(vvv => { e.push(vvv) })
                }
            })
        })
        return e;
    }

    if (!props.items)
        return <Loading msg="Loading" />
    
    if (props.items.length === 0)
        return <div className="none-found">No submissions were found for your account</div>

    return  <>
                <div className="submission-fill">
                    <SubmissionPortalList
                        items={items}
                        tags={props.tags}
                        lawReview={props.lawReview}
                        columns={columns}
                        checkWS={props.checkWS}
                        getEditors={getEditors}
                        setHeaderText={setHeaderText}
                        setTags={props.setTags}
                        selectedRef={selectedRef}
                        processTags={processTags}
                        processStatus={processStatus}
                        processAssignments={processAssignments}
                        processWS={props.processWS}
                        FetchSubmissions={props.FetchSubmissions}
                        handleNotes={handleNotes}
                        processNotes={processNotes}
                        omitted={props.omitted}
                        order={props.order}
                        selection={selection}
                        selectionCount={selectionCount}
                        setView={props.setView}
                        selectColumns={props.selectColumns}
                        resetView={props.resetView}
                    />
                </div>
                
                <Modal
                    isOpen={isModal}
                    onDismiss={() => setIsModal(false)}
                >
                    <>
                        <div className="section-title spaced">
                            <div className="island">{headerText}</div>
                            <Icon className="modal-close" iconName="Cancel" onClick={() => setIsModal(false)} />
                        </div>
                        {panel}
                    </>
                </Modal>
            </>
}

export default SubmissionPortalPanel;