import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
//import speakeasy from 'speakeasy';

const verifySecret = (secret, input) => {
    /*var t = speakeasy.totp.verify({
        secret: secret,
        encoding: 'ascii',
        token: input,
        window: 2,
        algorithm: "sha512"
    })
    console.log(t)
    return t;
    */
   return null;
}

const MAF = (props) => {
    const [url, setURL] = useState('');
    const rf = React.useRef();

    useEffect(() => {
        if(!url) {
     //       QRCode.toDataURL(speakeasy.otpauthURL({secret: props.secret, encoding:"ascii", issuer: "ScholarSift LLC", label: props.email, algorithm: "sha512"})).then(url => setURL(url)).catch(e => console.log(e));
        }
    }, [props, url]);

    if(props.secret === undefined)
        return <div>Your account is not authorized</div>;

    /*
    return  <div className="maf-panel" aria-label="multifactor authentication">
        {/*
                <img src={url} alt="qr code" aria-label="qr code"/>
                <div className="maf-input-panel">
                    <input 
                        type="text" 
                        className="mfa-input" 
                        placeholder="Verify Token" 
                        aria-label="verify token" 
                        onKeyPress={e => { 
                                        if(e.key === 'Enter' && verifySecret(props.secret, rf.current.value))
                                            props.onSuccess(1);
                                    } }
                        ref={rf}
                        autoFocus
                    />
                    <div 
                        className="maf-button" 
                        aria-label="submit" 
                        onClick={() => {
                            if(verifySecret(props.secret, rf.current.value))
                                props.onSuccess(1);
                        }}
                    >
                        Submit
                    </div>
                </div>
                }
            </div>
            */

            return <div>QRCODE</div>
}

export default MAF;