import axios from "axios";
import { APIErrorHandler } from "./APIErrorHandler";

export const GetCRMItems = (user, saveUser, setLoading, callback, onError) => {
  setTimeout(setLoading(true), 1000);
  axios
    .get("/api/crm", {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      console.log(response);
      setLoading(false);
    })
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) =>
        v
          ? onError(v, (p) => GetCRMItems(user, saveUser, callback, onError))
          : GetCRMItems(user, saveUser, callback, onError)
      )
    );
};

export const updateCRMJournal = (user, update, setInputOpen) => {
  axios
    .post(
      "/api/crm",
      update,
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      },
      console.log(update)
    )
    .then((res) => {
      console.log(res.data);
      return setInputOpen(false);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateCRMStatus = (
  user,
  j,
  formData,
  stage,
  finishSubmit,
  setFormData
) => {
  const { contact, owner, notes } = formData;
  setTimeout(setFormData(true), 1000);
  const req = {
    ...j,
    status: { [stage]: { contact: contact, owner: owner, notes: notes } },
  };

  console.log(j.status);
  console.log(stage);
  console.log(req);
  //console.log(JSON.parse(update));

  axios
    .post("/api/crm", req, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((res) => {
      console.log(res);

      finishSubmit("Status updated", "info");
      console.log(req.status[stage]);
      return setFormData({ ...formData, ...req.status[stage] });
    })
    .catch((err) => {
      console.log(err);
      finishSubmit("Status failed to update", "error");
    });
};

//Testing

export const CRMTestGET = (user, callback, p, row, name) => {
  axios
    .get("/api/crm", {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((response) => {
      console.log(response);
      if (callback) callback(p, row, response, name);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const CRMTestUPDATE = (user, callback, p, row, name) => {
  const update = {
    expedite: " Scholastica or lawreview@uakron.edu",
    journal: "TEST",
    submissions:
      " Submit via Scholastica or by e-mail to the Articles Editors at lawreview@uakron.edu Manuscripts sent by mail: Articles Editors Akron Law Review The University of Akron School of Law \nAkron, OH 44325-2901",
    format:
      " Bluebook citation format. Footnotes required . Please include a CV with name, address, telephone number, and e-mail address.",
    pid: "0",
    id: "1",
    url: "http://ideaexchange.uakron.edu/akronlawreview/policies.html",
    email:
      "saltmeyer@uakron.edu, lawreview@uakron.edu, jmoore5@uakron.edu (LRW),  cwo@uakron.edu (LRW), rrs11@uakron.edu (LRW), TEST",
    withdraw: " Scholastica or lawreview@uakron.edu",
    status: '{"email":{"owner":"","notes":"","contact":false}}',
    librarians:
      "Altmeyer, Susan; Oldfield, Charles (LRW); Strong, Richard (LRW); Moore, Jonathan (LRW)",
  };
  axios
    .post(
      "/api/crm",
      update,
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      },
      console.log(user)
    )
    .then((response) => {
      console.log(response);
      if (callback) callback(p, row, response, name);
    })
    .catch((error) => {
      console.log(error);
      if (callback) callback(p, row, error, name);
    });
};
