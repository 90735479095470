import React, { useState } from "react";
import {
  makeStyles,
  shorthands,
  Button,
  createTableColumn,
  Card,
  CardHeader,
  useTableFeatures,
  useTableSelection,
  CardFooter,
  Text,
} from "@fluentui/react-components";
import StatusTable from "./StatusTable";
import "../Dashboard.scss";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    display: "flex",
    width: "450px",
    maxWidth: "100%",
    height: "fit-content",
    fontFamily: "Outfit, sans-serif",
  },
  panels: {
    ...shorthands.borderRadius("7px", "7px", "0", "0"),
    ...shorthands.padding("20px"),
    width: "fit-content",
    maxHeight: "80%",
    color: "var(--violet)",
    fontFamily: "Outfit, sans-serif",
  },
  header: {
    width: "100%",
    textAlign: "center",
    float: "right",
    color: "#4061d6",
  },
  headerSmall: {
    ...shorthands.padding("15px", "auto", "0", "auto"),
    width: "100%",
    textAlign: "center",
    float: "right",
    color: "#4061d6",
  },
  inputWrapper: {
    ...shorthands.margin("10px", "auto", "10px", "auto"),
    width: "fit-content",
  },
  field: {
    ...shorthands.gap("5px, 16px"),
    ...shorthands.margin("20px", "auto", "0", "30px"),
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    color: "#21387a",
  },
  footer: {
    justifyContent: "center",
    ...shorthands.margin("0", "auto", "50px", "auto"),
  },
  footerSmall: {
    justifyContent: "center",
    ...shorthands.margin("0", "auto", "30px", "auto"),
  },
  button: {
    ...shorthands.margin("10px", "auto", "10px", "auto"),
    width: "fit-content",
    backgroundColor: "#7366f0",
    color: "white",
  },
});

const SystemCard = ({ items, viewAll, logView, title, loading }) => {
  const styles = useStyles();
  const [option1] = useState(false);
  const [option2] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [createTableColumn("Request"), createTableColumn("Status")];

  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
        defaultSelectedItems: selectedRows,
        onSelectionChange: (e, data) => setSelectedRows(data.selectedItems),
      }),
    ]
  );

  const rows = getRows((row) => {
    const selected = isRowSelected(row.rowId);

    return {
      ...row,
      onClick: (e) => {
        toggleRow(e, row.rowId);
      },
      selected,
    };
  });

  const handleSubmit = (e, item) => {
    e.preventDefault();
    console.log(selectedRows);
    title === "AuthNet"
      ? selectedRows.forEach((row) => items[row].req())
      : item.map((i) => i.req());
  };

  const handleReset = (e) => {
    e.preventDefault();
    handleSubmit(e);
    console.log("reset");
  };

  return (
    <Card
      size={viewAll || logView ? "small" : "large"}
      className={viewAll || logView ? styles.card : styles.panels}
      checked={
        option1 && option2 ? true : !(option1 || option2) ? false : "mixed"
      }
      style={{ boxShadow: "10px 10px 10px rgba(0,0,0,0.05)" }}
    >
      <>
        <CardHeader
          header={
            <Text
              weight="semibold"
              size={500}
              className={
                viewAll || logView ? styles.headerSmall : styles.header
              }
            >
              {title}
            </Text>
          }
        />
        <div className={styles.inputWrapper}>
          <StatusTable
            viewAll={viewAll}
            logView={logView}
            title={title}
            allRowsSelected={allRowsSelected}
            someRowsSelected={someRowsSelected}
            toggleAllRows={toggleAllRows}
            rows={title === "AuthNet" ? rows : items}
            items={items}
            loading={loading}
          />
        </div>
        <CardFooter
          className={viewAll || logView ? styles.footerSmall : styles.footer}
        >
          {title === "AuthNet" ? (
            selectedRows?.length < 1 || selectedRows?.size === 0 ? (
              <Button size="medium" className={styles.button} disabled>
                Test Selected
              </Button>
            ) : (
              <Button
                size="medium"
                onClick={handleSubmit}
                className={styles.button}
              >
                Test Selected
              </Button>
            )
          ) : (
            <Button
              size="medium"
              onClick={(e) => handleSubmit(e, items)}
              className={styles.button}
            >
              Test All
            </Button>
          )}
          <Button onClick={handleReset} size="medium" className={styles.button}>
            {`Reset ${title}`}
          </Button>
        </CardFooter>{" "}
      </>
    </Card>
  );
};

export default SystemCard;
