import useLocationState from "../../../../hooks/useLocationState";
import { animated } from "@react-spring/web";
import { useWindowDimensions } from "ss-lib";
const Slide = ({ header, text, slideControls, spring }) => {
  const { state } = useLocationState();
  const { width } = useWindowDimensions();

  const height = state.viewport.h - (state.viewport.m + state.viewport.f);
  return (
    <div
      style={{
        height: height,
      }}
      className="slide-container"
    >
      <section className="slide-contents">
        <div className="slide" id="slide-left" style={{ height: height }}>
          <animated.header
            className="slide-body"
            style={{ ...spring, lineHeight: 1.25 }}
          >
            {header}
          </animated.header>
        </div>
        <div className="slide" id="slide-right">
          <div className="slide-body">{text}</div>
        </div>
      </section>
      {slideControls}
    </div>
  );
};

export default Slide;
