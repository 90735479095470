import { memo } from "react";
import useLocationState from "../../../hooks/useLocationState";
import { AnalyzeResultsWithProvider } from "ss-lib";
import CombinedListItem from './CombinedListItem';

const Results = (props) => {
  const { state } = useLocationState();
  const itemRenderer = memo((props) => <div style={props.style}><CombinedListItem item={props.data.items[props.index]} index={props.index} /></div>);
  
  //Component
  return <AnalyzeResultsWithProvider {...props} clear={(e) => { props.Clear(e) }} itemRenderer={itemRenderer} itemSize={125} listHeight={state.viewport.h - (state.viewport.m + state.viewport.f)} />
};

export default Results;
