import { a } from "react-spring";
import useLocationState from "../../../hooks/useLocationState";
import { IconButton } from "@fluentui/react";
import Portal from "../Portal";

const Modal = ({ isOpen, style, closeModal, item }) => {
  const { state } = useLocationState();
  const { viewport } = state;

  if (!isOpen) return null;

  return (
    <Portal wrapperId="portal-container">
      <div className="alert-overlay">
        <a.div style={{ ...style, top: viewport.m + 5 }} id="modal">
          <IconButton
            className="modal-dismiss"
            ariaLabel="Close popup modal"
            onClick={closeModal}
            iconProps={{ iconName: "Cancel" }}
          />
          <h3 className="modal-header">{item.title}</h3>
          <div className="modal-body">{item.file}</div>
        </a.div>
      </div>
    </Portal>
  );
};

export default Modal;
