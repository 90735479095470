import { useState } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import licenses from "./licenses.json";
import { v4 as uuid } from "uuid";

const processText = (text) => {
  let ret = [];
  let p = text.split("\n");
  p.forEach((v) => {
    ret.push(<p key={uuid()}>{v}</p>);
  });
  return ret;
};

const Item = (k, v) => {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(!showResults);

  return (
    <div className="license-item" key={uuid()}>
      <div className="license-title" onClick={onClick}>
        {k} <Icon iconName={showResults ? "ChevronDown" : "ChevronRight"} />
      </div>
      {showResults && (
        <div className="license-text">
          {processText(Buffer.from(v, "base64").toString("ascii"))}
        </div>
      )}
    </div>
  );
};

const LinkItem = (k, v) => {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(!showResults);

  return (
    <div className="license-item" key={uuid()}>
      <div
        className="license-title"
        onClick={onClick}
        aria-label={"expand " + k}
      >
        {k} <Icon iconName={showResults ? "ChevronDown" : "ChevronRight"} />
      </div>
      {showResults && (
        <iframe
          src={v}
          title="License"
          className="license-text"
          aria-label={k + " license"}
        />
      )}
    </div>
  );
};

const Licenses = () => {
  let keys = Object.keys(licenses);
  let items = [];
  keys.forEach((k, i) => {
    if (typeof licenses[k] === "string")
      items.push(Item(k, licenses[k], "item_" + i));
    else {
      var ks = Object.keys(licenses[k]);
      if (ks.indexOf("text") > -1) {
        licenses[k].sw.forEach((v, j) => {
          items.push(Item(v, licenses[k].text));
        });
      } else if (ks.indexOf("src") > -1) {
        licenses[k].sw.forEach((v, j) => {
          items.push(LinkItem(v, licenses[k].src));
        });
      }
    }
  });

  let third = Math.floor(items.length / 3);
  console.log(third);
  let panels = [
    <div key={uuid()} className="selection-column">
      {items.slice(0, third).map((v) => v)}
    </div>,
    <div key={uuid()} className="selection-column">
      {items.slice(third, items.length - third).map((v) => v)}
    </div>,
    <div key={uuid()} className="selection-column">
      {items.slice(items.length - third).map((v) => v)}
    </div>,
  ];

  return (
    <div key={uuid()} className="selection-panel">
      {panels}
    </div>
  );
};

export default Licenses;
