import { useState } from 'react';
import { DetailsList, SelectionMode, TextField } from '@fluentui/react';
import useLocationState from "../../../hooks/useLocationState";

const calcHeight = vp => {
    let x = vp.h;
    if(x > 0 && vp.m)
        x -= vp.m;
    if(x > 0 && vp.f)
        x -= vp.f;
    if(x > 0)
        x -= 150;
    return x;
}

const TextFilterPanel = props => {
    const { state } = useLocationState();
    const [items, setItems] = useState(props.items);
    let x = calcHeight(state.viewport);

    const onFilterChanged = (ev, value) => {
    
        if(value.trim().length === 0)
        {
            setItems(props.items);
            props.setFiltered(undefined);
            return ;
        }

        let lc = value.toLowerCase();
        let itms = props.items.filter(v => v[props.searchKey].toLowerCase().indexOf(lc) > -1);
        setItems(itms);
        props.setFiltered(itms);
    }  
    
    return  <div>
                <TextField
                    styles={{ fieldGroup: [{ borderRadius: 20 }, {padding: 10}, {backgroundColor: "#f6f2f2"}, {margin: 30}]}}
                    placeholder={items.length === props.items.length ? 'Filter Selections' : null}
                    onChange={onFilterChanged}
                    iconProps={{iconName: "Filter", style: {paddingRight: 10, color: "gray"}}}
                    borderless
                />
                <div className="text-filter-panel-list" style={x ? {height: x} : undefined}>
                    <DetailsList
                            items={items}
                            selection={props.selection}
                            selectionMode={SelectionMode.multiple}
                            selectionPreservedOnEmptyClick={true}
                    />
                </div>
            </div>
}

export default TextFilterPanel;