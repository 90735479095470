import { createRef } from "react";
import { useNavigate } from "react-router";
import "./Nav.scss";
const Nav = ({ url, name, id, darkMode }) => {
  let anchor = createRef();
  const navigate = useNavigate();

  const handleNav = (e) => {
    e.preventDefault();
    navigate(url);
  };

  return (
    <div className="collapse-nav">
      <div
        innerref={anchor}
        className={darkMode ? "nav-dark nav" : "nav"}
        aria-label={id}
        onClick={handleNav}
      >
        {name.toUpperCase()}
      </div>
    </div>
  );
};

export default Nav;
