import { memo, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";
import SearchPanel from "./SearchPanel";
import { DoSearch, ExportResults, processDocument } from "../../actions/Analyze";
import { Button, Text, makeStyles } from "@fluentui/react-components";
import { defaultAnalyzeState } from "../../context/LocationStateContext";
import { processURL } from "../../actions/Analyze";
import { delay } from "../../utils";
import ViewPort from "../Layout/ViewPort";
import CombinedListItem from "./NewResults/CombinedListItem";
import "./Analyze.scss";
import { useStyles } from "./Styles";
import Results from "./NewResults/index";
import { Debug, LoadingAnimation, SearchResults} from 'ss-lib';

const ROUTE_TYPE = "free";

const useOverrides = makeStyles({
  tooltip: { backgroundColor: "blue", color: "white" },
});

const Analyze = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [focus, setFocus] = useState();
  const [open, setOpen] = useState(false);
  const [keyPath] = useState(
    location.pathname.indexOf("analyze/") === -1
      ? location.pathname
      : location.pathname.substring(0, location.pathname.lastIndexOf("/"))
  );
  const { user, saveUser, setTitle } = useUser();
  const { state, dispatch } = useLocationState();
  const [dismissButton, setDismissButton] = useState(false);
  const h = useRef();
  const [targetURL, setTargetURL] = useState("");
  const [loading, setLoading] = useState(state[keyPath].info === "Done");
  const [isProcessing, setIsProcessing] = useState(false);

  const classes = useStyles();
  const overrides = useOverrides();

  const toggleTutorial = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const dismiss = (e) => {
    e.preventDefault();
    setDismissButton(true);
  };

  const setState = (key, value, callBack) => {
    dispatch({
      type: "path-key-update",
      path: keyPath,
      key: key,
      payload: value,
    });
    if(callBack)
      callBack();
  };

  useEffect(() => {
    document.title = "ScholarSift - Analyze";
    setTitle(
      /*window.location.pathname.indexOf("scholarsift.com") === -1 ? "Test Version"  : */
      ("Analyze " + (props.results ? props.results.title : "")).trim()
    );

    if(!isProcessing && Object.keys(state[keyPath].results).length === 0 && state[keyPath].file === undefined) {
      if(id)
      {
        setIsProcessing(true);
        analyzeFile(+id, location && location.state ? location.state.title : undefined);
      }
      else if(location.state) {
        if(location.state.file) {
          setIsProcessing(true);
          analyzeFile(location.state.file);
        }
        else if(location.state.submission) {
          setIsProcessing(true);
          analyzeFile(location.state.submission.id, location.state.submission.title, true);
        }
      }
    }

    if(location.state && location.pathname.indexOf("welcome") === -1) {
      if(location.state.id) {
        if(location.state.id === "dnd-text")
          setFocus(location.state.id)
        else {
          let elmt = document.getElementById(location.state.id);
          if(elmt)
            elmt.focus();
        }
      }
    }

    user.current.page = location.pathname;
    window.history.replaceState({}, '')

    return () => {
      user.current.page = undefined;
      setTitle("");
    };
  });

  useEffect(() => {
    if(Object.keys(state[keyPath].results).length) {
      Clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChange = (e) => {
    e.preventDefault();
    setTargetURL(e.target.value);
  };

  const getScrapedText = (targetURL) => {
    setState("results", {});
    setState("text", "");
    setState("last", "");
    //     setState("file", `${res.data.title} - (${targetURL})`);
    processURL(targetURL, user, saveUser, setData, fatalError, (v) =>
      setState("info", v)
    );
  };

  const onClick = (e) => {
    getScrapedText(
      targetURL,
      user,
      saveUser,
      setData,
      setState,
      fatalError,
      getTextResults
    );
  };

  const fatalError = (e, callBack) => {
    if (e === "login") {
      dispatch({
        type: "message-update",
        key: "info",
        payload: "Please login again.",
      });
      navigate("/signin", { goback: true, callBack: callBack });
    } else if (e === "server down") {
      let ct = 0;
      while (ct < 30)
        // eslint-disable-next-line no-loop-func
        delay(1000).then(() => {
          ct++;
          dispatch({
            type: "message-update",
            key: "error",
            payload: "Server is down.  Retrying in..." + (30 - ct),
          });
        });
      callBack();
    }
  };

  const getTextResults = (url, res) => {
    setState("results", {});
    setState("text", "");
    setState("last", "");
    setState("file", `${res.data.title} - (${url})`);
  };

  const onDrop = (e) => {
    var f = e.dataTransfer.files[0];
    if (f === undefined) return;
    let name = f.name.toLowerCase();
    if (
      !name.endsWith(".pdf") &&
      !name.endsWith(".docx") &&
      !name.endsWith(".doc") &&
      !name.endsWith(".txt")
    )
      setState(
        "warn",
        "Invalid file type.  Only .doc, .docx, .pdf, and .txt are allowed"
      );
    else analyzeFile(f);
  };

  const analyzeFile = (f, name, submission) => {
    if (f && !props.preview) {
      setState("results", {});
      setState("text", "");
      setState("last", "");
      setState("file", typeof f === "number" ? name : f.name);
      processDocument(
        submission ? {f: f, submission: true} : f,
        user,
        saveUser,
        setData,
        fatalError,
        (v) => setState("info", v)
      );
    }
  };

  const setData = (v) => {
    if (v) {
      setState("warn", undefined);
      setState("info", undefined);
      setState("results", v);
    } else
      dispatch({
        type: "key-update",
        key: keyPath,
        payload: { ...defaultAnalyzeState },
      });
    setIsProcessing(false);
  };

  const Search = (v) => {
    if (
      Object.keys(state[keyPath].results).length === 0 ||
      v !== state[keyPath].text ||
      state[keyPath].text !== state[keyPath].last
    ) {
      let text = state[keyPath].text ? state[keyPath].text : v
      setState("results", {});
      setState("file", undefined);
      setState("text", text);
      setState("last", text);
      setState("info", "Searching");
      DoSearch(text, user, saveUser, setData, fatalError);
    }
  };

  const Clear = (e) => {
    console.log(e)
    let keys = Object.keys(state[keyPath].results);
    if(location.state && location.state.file && keys.length > 0)
      navigate("/welcome");

    if(e)
      setFocus(undefined)

    let resetPath = (keys.length === 0 || keys.indexOf("closest") === -1) && (!location.state || !location.state.analyze);
    setState("results", {});
    setState("file", undefined);
    setState("text", "");
    setState("last", "");
    setState("warn", undefined);
    setState("info", undefined);
    dispatch({
      type: "key-update",
      key: keyPath,
      payload: { ...defaultAnalyzeState },
    });
    
    if(location.pathname.indexOf("analyze/") > -1 && resetPath) {
      var x = location.pathname.substring(location.pathname.indexOf("analzye/") + 10);
      if(+x > 0)
        navigate("/analyze", {state:{}});
    }

    if(e && e.type === "click")
      navigate("/analyze", {state:{}});
  };

  if(Debug()) {
    console.log("ANALYZE INFO")
    console.log(props)
    console.log(location)
  }

  const itemRenderer = memo((props) => <div style={props.style}><CombinedListItem item={props.data[props.index].i} index={props.index} dateRange={props.data.dateRange} linkToPage={true} analyze={true} /></div>);


  return (
      state[keyPath].info ? (
          <div style={{marginTop:50}}><LoadingAnimation msg={state[keyPath].info} /></div>
      ) : Object.keys(state[keyPath].results).length === 0 &&
        state[keyPath].file === undefined ? (
          <div style={props.preview ? {marginTop: 0} : {width:"50%", marginLeft:"auto", marginRight:"auto"}}>
            <div className={props.preview ? classes.previewSearchPanel : classes.searchPanel}>
                <Text
                  className={classes.analyzeTitle}
                  size={500}
                  align="center"
                >
                  Search for a paper, drag and drop a file, or enter a URL to
                  analyze.
                </Text>
              <SearchPanel
                text={state[keyPath].text}
                SetText={(text, exec) => { if(exec) Search(text); else setState("text", text);}}
                file={state[keyPath].file}
                Search={Search}
                Clear={Clear}
                setFile={analyzeFile}
                onDrop={onDrop}
                targetURL={targetURL}
                handleChange={handleChange}
                hasResults={Object.keys(state[keyPath].results).length > 0}
                onClick={onClick}
                preview={props.preview}
                focus={focus}
              />
            </div>
          </div>
   
      ) : Object.keys(state[keyPath].results).length > 0 && !loading ? (
        state[keyPath].results.search ? 
        <>
          <Button appearance='primary' shape='rounded' onClick={Clear}>Clear</Button>
          <SearchResults data={state[keyPath].results.search} itemSize={125} itemRenderer={itemRenderer}  />
        </> :
        <>
          <Results
            results={{...state[keyPath].results}}
            authorList={state[keyPath].results["author list"]}
            available={state[keyPath].results.available}
            count={state[keyPath].results["item count"]}
            total={state[keyPath].results["total cites"]}
            max={state[keyPath].results["max cites"]}
            citation={state[keyPath].results.citation}
            url={state[keyPath].results.url}
            height={h.current}
            URL={state[keyPath].results.URL}
            file={
              state[keyPath].file ? state[keyPath].file : state[keyPath].text
            }
            wc={state[keyPath].wc}
            export={(v) => { console.log(v); ExportResults(user, saveUser, v, m => {console.log(m)})}}
            setWC={(v) => {
              setState("wc", v);
            }}
            Search={Search}
            Clear={Clear}
          />
        </>
      ) : null      
  );
};


export const ViewPortAnalyze = props => {
  const { state } = useLocationState();
  const h = useRef();
  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  return <ViewPort>
          <div style={{
                minHeight: h.current ? h.current - 50 : undefined,
              }}
          >
            <Analyze {...props} />
          </div>
      </ViewPort>
}

export default Analyze;
