import { useState } from "react";
import CheckoutForm from "./CheckoutForm";
import useUser from "../../hooks/useUser";
import { Toggle } from "@fluentui/react";
import { ONE_DAY_IN_MS } from "@azure/msal-common";
import { getPlanType } from "./Plans";

const ConfirmDetails = ({
  plan,
  showModal,
  showAlert,
  hideAlert,
  hideModal,
  handleNav,
}) => {
  const [renewPlan, setRenewPlan] = useState(plan.name.indexOf("Hour") === -1);
  const { user } = useUser();

  const handleCheckout = () => {
    return user && user.current && user.current.scholarsift.profile
      ? showModal(
          <CheckoutForm
            plan={plan}
            renew={renewPlan}
            price={plan.types[getPlanType(user)]}
          />,
          "modal-card-dark"
        )
      : showAlert(
          <>
            <div className="alert-body">
              Before subscribing, you must sign up for an account. Click 'Ok'
              and you will be redirected.
            </div>
            <div className="alert-footer">
              <div
                className="alert-button"
                id="dismiss-alert"
                onClick={() => hideAlert()}
              >
                Go back
              </div>
              <div className="alert-button" onClick={handleNav}>
                Ok
              </div>
            </div>
          </>
        );
  };

  return (
    <div className="confirm-detail-container">
      <h2 className="checkout-title">Confirm Details</h2>
      <div className="detail-body">
        {plan.name.indexOf("Hour") === -1 && (
          <Toggle
            defaultChecked
            inlineLabel
            className="centered centered-div"
            onText="Auto-Renew"
            offText="I don't want to auto-renew"
            onChange={(ev, checked) => {
              setRenewPlan(checked);
            }}
            styles={{
              text: {
                color: "#fff",
                fontSize: 18,
                fontFamily: "'Outfit', sans-serif",
              },
              thumb: { backgroundColor: renewPlan ? "#fff" : "#0078d4" },
            }}
          />
        )}
        <div className="detail-item">
          <div className="detail-item-title">Subscription type:</div>
          <div className="detail-item-content">{plan.name}</div>
        </div>
        <div className="detail-item">
          <div className="detail-item-title">Charged today:</div>
          <div className="detail-item-content">
            ${plan.types[getPlanType(user)]}
          </div>
        </div>

        <div className="detail-item">
          <div className="detail-item-title">
            {!renewPlan ? "Expires on:" : "Renews on:"}
          </div>
          <div className="detail-item-content">
            {new Date(new Date().getTime() + ONE_DAY_IN_MS * plan.length)
              .toISOString()
              .substring(0, 10)}
          </div>
        </div>
      </div>
      <div className="checkout-footer">
        <div className="form-btn" id="cancel" onClick={hideModal}>
          Cancel
        </div>
        <div className="form-btn" onClick={handleCheckout}>
          Checkout
        </div>
      </div>
    </div>
  );
};

export default ConfirmDetails;
