import { Navigate, useLocation } from "react-router-dom";
import useUser from '../../hooks/useUser';

const GroupAdminRoute = ({children}) => {
  const { allowRoute } = useUser();
  const location = useLocation();

  if(!allowRoute("gadmin"))
    return <Navigate to='/signin' state={{location: location}} replace/>;
  return children;
};

export default GroupAdminRoute;