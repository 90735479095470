import axios from "axios";
import { ProcessToken } from "./Signin";
import { delay } from "../utils";
import { Debug } from 'ss-lib';
import creds from "../creds.json";
import { pca }from '../context/UserContext';

export const LogError = (error) => {
  console.log(error);
  console.log(Object.keys(error));
  Object.keys(error).forEach((v) => console.log(error[v]));
};

export const APIErrorHandler = (
  error,
  user,
  saveUser,
  callBack,
  count,
  delayLength
) => {
  LogError(error);

  if (count === undefined) count = 1;
  if (delayLength === undefined) delayLength = 1000;

  const { response } = error;
  const status = response?.status;
  if (status === 401) {
    if (response.data.toLowerCase().indexOf("expired") > -1) {
      if (Debug())
        console.log("Refresh...");
      RefreshToken(user, saveUser, callBack);
    } else if (response.data === "Unable to parse token") callBack("login");
  } else if (status === 502) {
    if (response && response.data === "Bad Gateway")
      delay(count * delayLength).then(() => {
        callBack(
          "server down",
          count * delayLength < 60000 ? count + 1 : count,
          delayLength
        );
      });
  } else if (status === 503) {
    if (
      response &&
      (response.data === "Updating Data" || response.data === "{}")
    )
      delay(count * delayLength).then(() => {
        callBack(
          "data update",
          count * delayLength < 60000 ? count + 1 : count,
          delayLength
        );
      });
  }
};

export const refreshGoogle = (res, saveUser, callBack) => {
  ProcessToken(res.credential, undefined, "Google", saveUser, callBack);
};

export const RefreshToken = (user, saveUser, callBack, count, maxError) => {
  if (count === undefined) count = 0;

  if (window.location.host.indexOf("scholarsift") === -1)
    console.log("Testing Token");

  if (!user || !user.current || !user.current.token || !user.current.token.id) {
    console.log("Nothing to refresh");
    return;
  }

  if (window.location.host.indexOf("scholarsift") === -1) {
    console.log("GETTING TOKEN");
    console.log(user.current);
  }
  if (user.current.token.type === "Local") {
    if (window.location.host.indexOf("scholarsift") === -1)
      console.log("Refreshing Local Token");

    let data = {
      grant_type: "refresh_token",
      refresh_token: user.current.token.refresh,
      headers: {
        Authorization:
          "Basic " + btoa(creds.clientId + ":" + creds.clientSecret),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post("/api/token_local", data)
      .then((response) =>
        ProcessToken(
          response.data.access_token,
          response.data.refresh_token,
          "Local",
          saveUser,
          callBack
        )
      )
      .catch((err) => LogError(err));
  } else if (user.current.token.type === "Microsoft") {
    let accts = pca.getAllAccounts();
    if(accts.length === 0) {
      return ;
    }
    else {
    const request = {
      scopes: ['User.Read']
    }
    const accessTokenRequest = { ...request, account: accts[0]};
    pca
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        if (window.location.host.indexOf("scholarsift") === -1) {
          console.log("Processing MSFT TOken " + response.tokenType);
          console.log(response);
        }
        ProcessToken(
          response.idToken ? response.idToken : response.accessToken,
          user,
          "Microsoft",
          saveUser,
          callBack,
          /*setMessage*/ undefined,
          /*setError*/ undefined,
          /*count*/ count
        );
      })
      .catch((err) => {
        console.log("Error renewing msft:");
        console.log(err);
      });
    }
  } else if (user.current.token.type === "Google") {
    console.log(window.google !== undefined && window.google !== null);
    if (!window.google) {
      let scriptCheck = document.getElementById("gsi-script");
      if (scriptCheck === null) {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.onload = () => {
          window.google.accounts.id.initialize({
            client_id: creds.googleClientId[window.location.hostname],
            callback: (res) => refreshGoogle(res, saveUser, callBack),
            auto_select: true,
          });          
          window.google.accounts.id.prompt((notification) => {});
        };
        script.async = true;
        script.defer = true;
        script.id = "gsi-script";
        document.querySelector("body")?.appendChild(script);
      }
    }
    else {
    window.google.accounts.id.initialize({
      client_id: creds.googleClientId[window.location.hostname],
      callback: (res) => refreshGoogle(res, saveUser, callBack),
      auto_select: true,
    });
    window.google.accounts.id.prompt((notification) => {});
  }
  }
};
