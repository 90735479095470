import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "ss-lib";
import ViewPort from "./ViewPort";

const NotFound = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(3);
  const { height } = useWindowDimensions();

  useEffect(() => {
    setInterval(() => setTime(time - 1), 1000);
  }, [time]);

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, [navigate]);

  return (
    <ViewPort style={{ justifyContent: "center" }}>
      <div
        style={{
          textAlign: "center",
          height: height,
          marginTop: "15%",
          color: "var(--darkest-blue)",
        }}
      >
        <h1 style={{ fontWeight: 800 }}>404 Not Found</h1>
        <h2>Hm, seems like you're lost.</h2>
        <h3>
          Redirecting in{" "}
          <span style={{ color: "var(--blue)", fontWeight: 800 }}>{time}</span>{" "}
          seconds...
        </h3>
      </div>
    </ViewPort>
  );
};

export default NotFound;
