import { useState } from "react";
import {
  Calendar,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { APIErrorHandler } from "../../../../actions/APIErrorHandler";
import useUser from "../../../../hooks/useUser";
import axios from "axios";

const FromDomain = (props) => {
  const { user, saveUser } = useUser();
  const [groupName, setGroupName] = useState("");
  const [groupDomain, setGroupDomain] = useState("");
  const [exp, setExp] = useState();
  const [msg, setMsg] = useState("");

  const createGroup = (retries) => {
    if (retries && retries > 5) return;

    axios
      .post(
        "/api/create_group",
        { domain: groupDomain, expiration: exp, name: groupName },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((results) => {
        setMsg("Successfully added " + groupDomain);
      })
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, () => {
          createGroup(retries ? retries + 1 : 1);
        })
      );
  };

  return (
    <div className="add-groups">
      <div className="display-headers">
        <div className="display-name">Create Group from Domain</div>
      </div>
      {msg.length > 0 && (
        <MessageBar
          className="bigger"
          messageBarType={MessageBarType.info}
          isMultiline={false}
          onDismiss={() => {
            setMsg("");
          }}
          dismissButtonAriaLabel="Close"
        >
          <div className="bigger-text">{msg}</div>
        </MessageBar>
      )}
      <div className="group-items">
        <TextField
          className="group-item"
          value={groupDomain}
          onChange={(_, newValue) => {
            if (msg.length) setMsg("");
            setGroupDomain(newValue);
          }}
          label="Domain"
        />
        <TextField
          className="group-item"
          value={groupName}
          onChange={(_, newValue) => {
            if (msg.length) setMsg("");
            setGroupName(newValue);
          }}
          label="Name"
        />
        <div className="subscription-exp-wrapper">
          <div className="subscription-title">Group Subscription Info</div>
          <div className="subscription-exp">
            Subscription Expiration: {exp ? new Date(exp).toLocaleString() : ""}
          </div>
          <Calendar
            showGoToToday={true}
            value={exp ? new Date(exp) : undefined}
            onSelectDate={setExp}
          />
        </div>
      </div>
      {groupDomain.length > 0 && groupName.length > 0 && exp && (
        <PrimaryButton
          text="Create Group"
          onClick={() => {
            createGroup();
          }}
        />
      )}
    </div>
  );
};

export default FromDomain;
