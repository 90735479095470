import { useEffect } from 'react';
import { useLocation } from 'react-router';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import OkCancel from '../../Layout/OkCancel';
import SelectionPanel from './SelectionPanel';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import axios from 'axios';

const AddSubmissions = props => {
    const location = useLocation();
    const { user, saveUser } = useUser();
    const { state, dispatch } = useLocationState();
        useEffect(() => {
        if(state[location.pathname].items === undefined) {
            let ids = props.item.status.map(v => v.pub);
            let items = Object.keys(props.lawreviews).filter(v => ids.indexOf(Number(v)) === -1).map(v => ({name: props.lawreviews[v], id: v}));
            items.sort((a, b) => a.name.localeCompare(b.name) );
            dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: items});
        }
    })
    const DoSubmit = retries => {
        if(retries && retries > 5)
            return ;
        
        let d = {
            paper: props.item.id,
            authors: props.item.authors,
            journals: state[location.pathname].items.filter(v => v.checked).map(v => v.id)
        }

        //Submit
        axios
            .post('/api/more', d, { 
                    headers : {
                                "Authorization":"Bearer " + user.current.token.id,
                                "Coda" :  user.current.scholarsift.coda
                    }
            })
            .then(results => {
                console.log("Submitted");
                props.update();
            })
            .catch(error =>  { APIErrorHandler(error, user, saveUser, () => { DoSubmit(retries ? retries + 1 : 1) }) });
    }

    const onOK = () => {
        DoSubmit();
        props.onOK(0);
    }

    return  <div>
                <div className="expedite-title-panel">
                    <div className="expedite-title">Submit</div>
                    <div className="expedite-sub-title">{props.item.title}</div>
                </div>
                <div className="expedite-panel">
                    <div className="expedite-to">
                        <div className="expedite-title-panel">
                            <div className="expedite-sub-title">To</div>
                        </div>
                        <SelectionPanel />
                    </div>
                    <OkCancel ok="Submit" onOK={onOK} onCancel={props.onCancel} />
                </div>
            </div>
}

export default AddSubmissions;