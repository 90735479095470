import axios from "axios";
import { APIErrorHandler } from "./APIErrorHandler";

export const GetUsage = (
  user,
  timeframe,
  offset,
  length,
  saveUser,
  setUsage,
  setIsLoading,
  fatalError
) => {
  setIsLoading(true);

  axios
    .post(
      "/api/usage",
      { timeframe: timeframe, offset: offset, length: length },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      console.log(response.data);
      setUsage(response.data.usage);
    })
    .then(() => setIsLoading(false))
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) =>
        v
          ? fatalError(v, (p) =>
              GetUsage(
                user,
                timeframe,
                offset,
                length,
                saveUser,
                setUsage,
                setIsLoading,
                fatalError
              )
            )
          : GetUsage(
              user,
              timeframe,
              offset,
              length,
              saveUser,
              setUsage,
              setIsLoading,
              fatalError
            )
      )
    );
};

export const UpdateUserList = (user, saveUser, callBack, fatalError) => {
  axios
    .get("/api/profile_list", {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((response) => {
      callBack(response.data["users"]);
    })
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) =>
        v
          ? fatalError(v, (p) =>
              UpdateUserList(user, saveUser, callBack, fatalError)
            )
          : GetUsage(user, saveUser, callBack, fatalError)
      )
    );
};

export const GetUserAccount = (
  profile,
  user,
  saveUser,
  callBack,
  fatalError
) => {
  if (profile === undefined) return;
  axios
    .post(
      "/api/profile",
      { profile: profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      callBack(response.data.pinfo);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, (v) => {
        if (v) fatalError(v);
        else GetUserAccount(profile, user, saveUser, callBack, fatalError);
      });
    });
};
