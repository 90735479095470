import { useEffect, useRef, useState } from "react";
import UpdatePanel from "./UpdatePanel";
import MAF from "../../UserManagement/MAF";
import useUser from "../../../hooks/useUser";
import ViewPort from "../../Layout/ViewPort";
import creds from "../../../creds.json";
import useLocationState from "../../../hooks/useLocationState";

const Maintenance = (props) => {
  const { currentUser } = useUser();
  const [sauth, setAuth] = useState(0);
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    window.title = "ScholarSift - Maintenance Dashboard";
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  if (sauth === 0)
    return (
      <ViewPort>
        <div style={{ minHeight: h.current, width: "100%", padding: 20 }}>
          <MAF
            onSuccess={(v) => setAuth(v)}
            secret={creds.mfa[currentUser.scholarsift.profile]}
            email={currentUser.scholarsift.email}
          />
        </div>
      </ViewPort>
    );

  return (
    <ViewPort>
      <div style={{ minHeight: h.current, width: "100%" }}>
        <div className="display-header">
          <div className="display-name" aria-label="Maintenance">
            Maintenance
          </div>
          <div className="mlogout">
            <div className="command-item" onClick={() => setAuth(0)}>
              Log Out
            </div>
          </div>
        </div>
        <div className="display-body">
          <div className="dashboard-maintenance">
            <UpdatePanel dest="FrontEnd" />
            <UpdatePanel dest="Java" />
            <UpdatePanel dest="Cpp" />
            <UpdatePanel dest="Scripts" />
          </div>
        </div>
      </div>
    </ViewPort>
  );
};

export default Maintenance;
