import { useState } from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { APIErrorHandler } from "../../../../actions/APIErrorHandler";
import useUser from "../../../../hooks/useUser";
import GroupSelectorPanel from "../GroupSelectorPanel";
import axios from "axios";

const FromFile = (props) => {
  const { user, saveUser } = useUser();
  const [msg, setMsg] = useState();
  const [data, setData] = useState();
  const [progress, setProgress] = useState();

  const processFile = (file) => {
    let fd = new FormData();
    fd.append("file", file);

    axios
      .post("/api/extractgroup", fd, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
        onUploadProgress: (p) => {
          setProgress(Math.round((100 * p.loaded) / p.total));
        },
      })
      .then((results) => {
        setProgress(undefined);
        setMsg("");
        setData(results.data);
      })
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, () => {
          processFile(file);
        })
      );
  };

  const selectFile = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    input.onchange = (e) => {
      setMsg("Uploading " + e.target.files[0].name);
      processFile(e.target.files[0]);
    };
    input.click();
  };

  return (
    <div className="add-groups">
      <div className="display-header">
        <div className="display-name">Create Group From File</div>
      </div>
      <GroupSelectorPanel
        data={data}
        resetData={() => {
          setData(undefined);
        }}
      />
      <PrimaryButton text="Select File" onClick={selectFile} />
      {msg && <div>{msg}</div>}
      {progress && (
        <ProgressIndicator label="Upload Progress" percentComplete={progress} />
      )}
    </div>
  );
};

export default FromFile;
