import { useCallback, useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import axios from "axios";
import { Calendar, Checkbox } from "@fluentui/react";
import GroupUserList from "./GroupUserList";

const GroupDetails = (props) => {
  const { user, saveUser } = useUser();
  const [active, setActive] = useState(false);
  const [users, setUsers] = useState();
  const [exp, setExp] = useState();

  const fetchUsers = useCallback(
    (retries) => {
      if (retries && retries > 5) return;

      axios
        .post(
          "/api/list_group",
          { id: props.group },
          {
            headers: {
              Authorization: "Bearer " + user.current.token.id,
              Coda: user.current.scholarsift.coda,
            },
          }
        )
        .then((response) => {
          setActive(Number(response.data["Active"]) > 0);
          setUsers(response.data.users);
          if (response.data.exp) setExp(response.data.exp);
        })
        .catch((error) =>
          APIErrorHandler(error, user, saveUser, (v) => {
            fetchUsers(retries ? retries + 1 : 1);
          })
        );
    },
    [props.group, saveUser, user]
  );

  useEffect(() => {
    fetchUsers(0);
  }, [fetchUsers, props.group]);

  const toggleActivation = (checked, retries) => {
    if (retries === undefined) setActive(checked);

    axios
      .post(
        "/api/activate_group",
        { id: props.group, active: checked },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {})
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) => {
          toggleActivation(checked, retries ? retries + 1 : 1);
        })
      );
  };

  if (!props.group || !users) return null;

  return (
    <>
      <div className="display-header">
        <div className="display-name">Group Info</div>
      </div>
      {users && (
        <Checkbox
          className="active-group"
          checked={active}
          label="Active"
          onChange={(_, checked) => {
            toggleActivation(checked);
          }}
        />
      )}
      {exp && (
        <div className="subscription-exp-wrapper">
          <div className="subscription-title">Group Subscription Info</div>
          <div className="subscription-exp">
            Subscription Expiration: {exp ? new Date(exp).toLocaleString() : ""}
          </div>
          <Calendar
            showGoToToday={true}
            value={exp ? new Date(exp) : undefined}
            onSelectDate={setExp}
          />
        </div>
      )}
      <GroupUserList items={users ? users : []} />
    </>
  );
};

export default GroupDetails;
