import { useEffect, useRef } from "react";
import useMeasure from "react-use-measure";
import useLocationState from "../../../hooks/useLocationState";
import { calcH } from "../../../utils";
import ActiveUserSelector from "./ActiveUserSelector";
import ViewPort from "../../Layout/ViewPort";

const ActiveUserSelectorWrapper = (props) => {
  const [ref, { height }] = useMeasure();
  const { state, dispatch } = useLocationState();
  const h = useRef();

  useEffect(() => {
    console.log(state);

    if (height !== h.current) {
      h.current = height;
      if (props.watch)
        dispatch({
          type: "path-key-update",
          path: "viewport",
          key: "o",
          payload: h.current,
        });
    }

    return () => {
      if (props.watch)
        dispatch({
          type: "path-key-update",
          path: "viewport",
          key: "o",
          payload: 0,
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, props.watch]);

  return (
    <ViewPort>
      <div ref={ref} style={{ minHeight: calcH(state) }}>
        <ActiveUserSelector {...props} />
      </div>
    </ViewPort>
  );
};

export default ActiveUserSelectorWrapper;
