import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ViewPort from "../Layout/ViewPort";
import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";

const HowTo = () => {
  const { setTitle } = useUser();
  const { state } = useLocationState();
  const [index, setIndex] = useState(0);
  const h = useRef(null);

  useEffect(() => {
    document.title = "ScholarSift - Tutorials";
    setTitle("Tutorials")
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const videos = [
    {
      url: "https://ss-how-to.s3.us-east-2.amazonaws.com/Analyze_04-23-2024.mp4",
      title: "Analyze",
      description:
        "Watch this video to learn more about ScholarSift's Analyze tool.",
    },
    {
      url: "https://www.youtube.com/embed/oKd7c3irDQg",
      title: "Papers & Preprints",
      description: "How to use the Papers & Preprints feature.",
    },
   /* {
      url: "https://ss-how-to.s3.us-east-2.amazonaws.com/ScholarSift_Editor_Submissions.mp4",
      title: "Editor Submissions",
      description: "How to use the Editor Submissions feature",
    },
    {
      url: "https://ss-how-to.s3.us-east-2.amazonaws.com/ScholarSift_Author_Submissions.mp4",
      title: "Author Submissions",
      description: "How to use the Author Submissions feature",
    },
    {
      url: "https://ss-how-to.s3.us-east-2.amazonaws.com/ScholarSift_Jr_Editor.mp4",
      title: "Junior Editor",
      description: "How to use the Junior Editor feature",
    },
    */
  ];

  return (
    <ViewPort
      style={{
        height: h.current,
        overflow: "auto",
      }}
    >
      <div style={{ minHeight: h.current }}>
        <section>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {videos.map((v) => (
                  <div
                    className="rounded-btn"
                    style={{width:"fit-content"}}
                    onClick={() => setIndex(videos.indexOf(v))}
                  >
                    {v.title}
                  </div>
                ))}
            </div>
            <iframe
                className="iFrame"
                style={{ marginTop: 30 }}
                src={videos[index].url}
                title={videos[index].description}
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                autoplay="0"
              />
              <h4
                style={{
                  margin: "30px auto",
                  width: "80%",
                  textAlign: "center",
                }}
            >
              {videos[index].description}
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                textAlign: "center",
                justifyContent: "space-between",
                margin: "auto"
              }}
            >
              <Link style={{fontSize:"16pt", fontWeight:"600"}} to="/demo">Schedule a demo</Link>
              <Link style={{fontSize:"16pt", fontWeight:"600"}} to="/faqs">FAQs</Link>
            </div>
        </section>
      </div>
    </ViewPort>
  );
};

export default HowTo;
