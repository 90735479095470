import { useEffect, useState } from 'react';
import { Checkbox} from '@fluentui/react';
import { v4 as uuid} from "uuid";

const checks = {
    Assign: false,
    "Accept/Reject": false,
    Read: false,
    Tags: false,
    Notes: false
}

const getOps = keys => {
    let ops = {...checks};
    if(keys)
        keys.forEach(k => { ops[k] = true; });
    return ops;
}

const EditorChecker = props => {
    const [ops, setOps] = useState(getOps(props.limits));

    useEffect(() => {
        setOps(getOps(props.limits));
    }, [props])

    const doUpdate = (key, value) => {
        let o = {...ops, [key]: value};
        setOps(o);
        props.updateProfile({profile: props.profile, limits: Object.keys(o).filter(k => o[k]).map(k => k)});
    }


    return  <div className="editor-checker">
                <div className="editor-checker-title">Permissions</div>
                <div className="editor-checker-table">
                    <div className="editor-column">
                        { Object.keys(ops).filter(k => k.startsWith("A")).map(v => <Checkbox className="editor-checker-table-item" key={uuid()} label={v} checked={ops[v]} onChange={(_, chk) => { doUpdate(v, chk); }} />)}
                    </div>
                    <div className="editor-column">
                        { Object.keys(ops).filter(k => !k.startsWith("A")).map(v => <Checkbox className="editor-checker-table-item" key={uuid()} label={v} checked={ops[v]} onChange={(_, chk) => { doUpdate(v, chk); }} />)}
                    </div>
                </div>
           </div>
}

export default EditorChecker;