import { useEffect, useRef, useState } from "react";

import UserAccountSettings from "./UserAccountSettings";
import UserList from "../UserList";
import ViewPort from "../../Layout/ViewPort";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";

const Accounts = (props) => {
  const [profile, setProfile] = useState();
  const { setTitle } = useUser();
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    document.title = "ScholarSift - Accounts Dashboard";
    setTitle("Accounts Dashboard");
    return () => {
      setTitle("");
    };
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  return (
    <ViewPort>
      <div style={{ minHeight: h.current }}>
        <UserList setProfile={setProfile} profile={profile} />
        <UserAccountSettings profile={profile} />
      </div>
    </ViewPort>
  );
};

export default Accounts;
