import { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { useNavigate, useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import Loading from "../../Layout/Loading";
import {
  CheckboxVisibility,
  DetailsList,
  Selection,
  SelectionMode,
  TextField,
} from "@fluentui/react";
import { LocalCreateLogin } from "../../../actions/Signin";
import { GetUserList } from "../../../actions/Management";
import {
  CircleAdditionSolidIcon,
  StatusErrorFullIcon,
  EditContactIcon,
} from "@fluentui/react-icons-mdl2";
import { Icon } from "@fluentui/react";
//import TestIcons from "../../TestIcons";

const userColumns = [
  { key: "first", width: 200, name: "Name" },
  { key: "email", name: "Email", width: 600 },
  {
    key: "remove",
    name: "Remove User",
    minWidth: 100,
    textAlign: "center",
  },
];

const ActiveUserSelector = (props) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [items, setItems] = useState();
  const [filter, setFilter] = useState();
  const [openTextFields, setOpenTextFields] = useState(false);
  const [formData, setFormData] = useState({
    first: "",
    last: "",
    email: "",
  });

  const { first, last, email } = formData;

  const onChange = (e) => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resultCountText =
    !items ||
    !state[location.pathname].list ||
    items.length === state[location.pathname].list.length
      ? ""
      : ` (${items.length} of ${state[location.pathname].list.length} shown)`;

  const handleRemoveUser = (e, index) => {
    e.preventDefault();
    items.splice(index, 1);

    setState("list", items);
    return;
  };

  const createUser = async () => {
    let pwd = "";
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i <= 14; i++) {
      const num = Math.floor(Math.random() * chars.length);
      pwd += chars.substring(num, num + 1);
    }

    LocalCreateLogin(email, pwd, first, last, setMessage, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /*
  const clearFields = () => {
    setFormData({ first: "", last: "", email: "" });
    setOpenTextFields(false);
  };
  */

  const handleAddUser = (e) => {
    e.preventDefault();
    createUser();
    setItems(items.push(formData));
    return setState("list", items);
    //return clearFields();
  };

  const onRenderItemColumn = (item, index, column) => {
    switch (column.key) {
      case "first":
        return (
          <span style={{ fontSize: `14pt` }}>
            {item.first + " " + item.last}
          </span>
        );
      case "email":
        return <span style={{ fontSize: `14pt` }}>{item.email}</span>;
      case "remove":
        return (
          <StatusErrorFullIcon
            className="list-item-icon"
            handleClick={(e) => handleRemoveUser(e, index)}
            id="remove-item-icon"
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      (!state[location.pathname].list || state[location.pathname].refresh)
    ) {
      console.log("GETTING USER LIST");
      GetUserList(
        {...user, account: accounts[0] || {}, instance:instance},
        saveUser,
        (v) => {
          let dom = user.current.scholarsift.email;
          dom = dom.substring(dom.indexOf("@"));
          setState(
            "list",
            v.filter((vv) => vv.email.indexOf(dom) > -1)
          );
        },
        setError
      );
      let s = new Selection();
      setState("selection", s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, state[location.pathname].refresh, currentUser]);

  useEffect(() => {
    console.log(items);
    applyFilter();
    if (state[location.pathname].current && state[location.pathname].selection)
      state[location.pathname].selection.setKeySelected(
        state[location.pathname].current.ukey
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, state[location.pathname].list, state[location.pathname].current]);

  const setState = (key, value) => {
    dispatch({
      type: "path-key-update",
      path: location.pathname,
      key: key,
      payload: value,
    });
  };

  const setError = (v) => {
    if (v === "login") {
      dispatch({
        type: "message-update",
        key: "error",
        payload: "Please login again",
      });
      navigate("/signin", state[location.pathname]);
      return;
    }

    dispatch({ type: "message-update", key: "error", payload: v });
  };

  const setMessage = (v) => {
    return dispatch({ type: "message-update", key: "info", payload: v });
  };

  const onFilterChanged = (_, text) => {
    setFilter(text.toLowerCase());
    applyFilter();
  };

  const applyFilter = () => {
    if (!state[location.pathname].list) return;

    if (!filter || !filter.length) {
      console.log(state[location.pathname]);
      console.log(state[location.pathname].list);
      setItems([...state[location.pathname].list]);
      return;
    }

    let itm = [];
    state[location.pathname].list.forEach((v) => {
      if (v.text.indexOf(filter) > -1) itm.push({ ...v });
    });
    setItems(itm);
  };

  if (items === undefined || items.length === 0)
    return !state[location.pathname].list && !items ? (
      <Loading msg="Loading Users" />
    ) : null;

  const onSelect = (e) => {
    setState("current", e);
  };

  /*
  const breakpointsObj = {
    default: 2,
    700: 1,
  };
  */

  console.log("Hi");
  return (
    <>
      <div
        className="crm-grid"
        style={{
          justifyContent: "space-around",
          overflow: "auto",
        }}
      >
        {/*       <Masonry
        className=""
        breakpointCols={
          state[location.pathname] && state[location.pathname].current
            ? breakpointsObj
            : 1
        }
      > */}
        <div style={{ width: "90%", margin: "auto" }}>
          <div className="active-user-selector-table">
            <TextField
              label={resultCountText ? "Filtered" + resultCountText : null}
              onChange={onFilterChanged}
              placeholder="Filter Users"
              styles={{
                fieldGroup: [
                  { borderRadius: 20 },
                  { backgroundColor: "#f6f2f2" },
                  { margin: "auto" },
                  { minWidth: 150 },
                ],
              }}
              iconProps={{
                iconName: "Filter",
                style: { paddingRight: 10, color: "gray" },
              }}
            />
            <DetailsList
              selection={state[location.pathname].selection}
              selectionPreservedOnEmptyClick
              selectionMode={SelectionMode.single}
              setKey={
                state[location.pathname] && state[location.pathname].current
                  ? state[location.pathname].current.key
                  : undefined
              }
              getKey={(item) => item.ukey}
              columns={userColumns}
              items={items}
              checkboxVisibility={CheckboxVisibility.always}
              onRenderItemColumn={onRenderItemColumn}
              onActiveItemChanged={onSelect}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px auto 0 auto",
                minWidth: "fit-content",
                width: "100%",
                padding: "0 50px 0 10px",
                justifyContent: openTextFields ? "space-between" : "center",
              }}
            >
              {!openTextFields ? (
                <>
                  {/*                   <TestIcons
                    icon2={
                      <CircleAdditionIcon
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTextFields(!openTextFields);
                        }}
                        className="list-item-icon"
                        style={{ fontSize: 22 }}
                      />
                    }
                    icon1={
                      <CircleAdditionSolidIcon
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTextFields(!openTextFields);
                        }}
                        className="list-item-icon"
                        style={{ fontSize: 22 }}
                      />
                    }
                  /> */}
                  <CircleAdditionSolidIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTextFields(!openTextFields);
                    }}
                    className="list-item-icon"
                    style={{ fontSize: 22 }}
                  />
                  <span style={{ fontSize: 18, color: "slate" }}>Add User</span>
                </>
              ) : (
                <>
                  <Icon
                    onClick={handleAddUser}
                    iconName="SkypeCircleCheck"
                    className="list-item-icon"
                    style={{ fontSize: 22 }}
                  />
                  <TextField
                    onChange={onChange}
                    placeholder="First name"
                    name="first"
                    value={first}
                    styles={{
                      fieldGroup: [
                        { borderRadius: 20 },
                        { backgroundColor: "#f6f2f2" },
                        { margin: "auto" },
                        { width: 150 },
                      ],
                    }}
                  />
                  <TextField
                    onChange={onChange}
                    placeholder="Last name"
                    name="last"
                    value={last}
                    styles={{
                      fieldGroup: [
                        { borderRadius: 20 },
                        { backgroundColor: "#f6f2f2" },
                        { margin: "auto" },
                        { width: 150 },
                      ],
                    }}
                  />
                  <TextField
                    onChange={onChange}
                    placeholder="Email"
                    name="email"
                    value={email}
                    styles={{
                      fieldGroup: [
                        { borderRadius: 20 },
                        { backgroundColor: "#f6f2f2" },
                        { margin: "auto" },
                        { width: 200 },
                      ],
                    }}
                    iconProps={{
                      iconName: "Mail",
                      style: { paddingRight: 10, color: "gray" },
                    }}
                  />
                </>
              )}
            </div>
          </div>

          {state[location.pathname] && state[location.pathname].current ? (
            <div
              style={{
                padding: 20,
                width: 500,
                justifyContent: "center",
                margin: "0 10% 0 10%",
              }}
            >
              <div>
                <div className="active-user-profile-card" id="top-card">
                  <div className="active-user-title">
                    <EditContactIcon className="active-user-icon" />
                    <>
                      {state[location.pathname].current.first}{" "}
                      {state[location.pathname].current.last}
                    </>

                    <div className="active-user-email">
                      {state[location.pathname].current.email}
                    </div>
                  </div>
                </div>

                {props.options && (
                  <div className="active-user-profile-card">
                    {props.options}
                  </div>
                )}

                <div className="active-user-profile-column">
                  {props.form && (
                    <div className="active-user-profile-card">{props.form}</div>
                  )}
                </div>
              </div>
            </div>
          ) : undefined}
          {/*  </Masonry> */}
        </div>
      </div>
    </>
  );
};

export default ActiveUserSelector;
