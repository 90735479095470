import { useState } from "react";
import { TooltipHost, FontIcon } from "@fluentui/react";
import { useTracking } from "react-tracking";

const FacultyCard = ({ f, index }) => {
  const [collapse, setCollapse] = useState(true);
  const { Track, trackEvent } = useTracking({
    module: "FacultyCard",
    title: f["title"],
  });

  const open = (e) => {
    e.preventDefault();
    trackEvent({ action: "select" });
    setCollapse(!collapse);
  };

  const nav = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Track>
      <div className="crm-card faculty-card" key={index}>
        <div className="crm-journal">
          <div className="crm-journal-row faculty-row">
            <div
              onClick={open}
              className="row"
              style={{ justifyContent: "space-between", flexWrap: "nowrap" }}
            >
              {f["og:image"] !== "" || f["og:image"] !== undefined ? (
                <div
                  id="clickable"
                  /*                onClick={open} */
                  className="faculty-img"
                  alt={`Thumbnail of ${f["title"]}`}
                  style={{
                    backgroundImage: `url(${f["og:image"]})`,
                    backgroundSize: "cover",
                    backgroundPosition:
                      f["image:height"] !== f["image:width"] ? "center" : "top",
                  }}
                  type="image"
                />
              ) : (
                <div className="faculty-img">
                  <FontIcon iconName="Profile" />
                </div>
              )}
              <div className="faculty-header">
                <div
                  className="faculty-title"
                  style={{ width: "100%", fontWeight: 400, fontSize: 20 }}
                >
                  {f["title"] ? f["title"] : f["url"]}
                  {f["distance"] && (
                    <div>{Math.round(f["distance"])} (Miles)</div>
                  )}
                </div>
              </div>{" "}
            </div>
            {/* show coverage up front - how many law reviews listed - faq stuff - */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                className="row"
                style={{
                  textAlign: "right",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                  marginLeft: 20,
                }}
              >
                {f["cv url"] && (
                  <TooltipHost content="Open CV in new tab">
                    <div
                      name="link"
                      className="faculty-link"
                      onClick={(e) => {
                        e.preventDefault();
                        nav(f["cv url"]);
                      }}
                    >
                      CV
                      <FontIcon
                        id="faculty-link-icon"
                        iconName="D365TalentHRCore"
                      />
                    </div>
                  </TooltipHost>
                )}
              </div>
            </div>
          </div>
        </div>
        {!collapse &&
          (f["og:description"] ? (
            <div className="crm-card-content">
              <p>{f["og:description"]}</p>
              <p>
                Link:{" "}
                <a
                  href={f["url"]}
                  type="link"
                  className="details-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {f["title"] ? f["title"] : f["url"]}
                </a>
              </p>
            </div>
          ) : (
            <div className="crm-card-content">
              Link:{" "}
              <a
                href={f["url"]}
                className="details-link"
                target="_blank"
                rel="noreferrer"
                onClick={() => setCollapse(false)}
              >
                {f["title"] ? f["title"] : f["url"]}
              </a>
            </div>
          ))}
      </div>
    </Track>
  );
};

export default FacultyCard;
