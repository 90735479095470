export const LaunchOneDrive = (clientId:string, accessToken: string) => {
    return new Promise<OneDriveResult | null>((resolve, reject) => {
        var odOptions: OneDriveOpenOptions = {
            clientId: clientId,
            action: "download",
            multiSelect: true,
            viewType: "files",
            accountSwitchEnabled: true,
            advanced: {
                filter: ".doc,.docx,.pdf",
                accessToken: accessToken,
                redirectUri: "https://" + window.location.hostname + "/picker.html",
                queryParameters: "select=id,name,size,file,@microsoft.graph.downloadUrl",
            },
            success: function (files) { resolve(files); },
            cancel: function () { resolve(null); },
            error: function (e) { reject(e); }
        };
        OneDrive.open(odOptions);
    });
}

interface Thumbnails {
    id: string;
    large: Thumbnail;
    medium: Thumbnail;
    small: Thumbnail;
}

interface Thumbnail {
    height: number;
    width: number;
    url: string;
}

interface OneDriveResult {
    value: OneDriveFile[];
    webUrl: string | null;
}

interface OneDriveFile {
    "@microsoft.graph.downloadUrl": string;
    "thumbnails@odata.context": string;
    id: string;
    name: string;
    size: number;
    thumbnails: Thumbnails[];
    webUrl: string;
}

interface OneDriveOpenOptions {
    clientId: string;
    action: "download" | "share" | "query";
    multiSelect: boolean;
    viewType: "files" | "folders" | "all";
    accountSwitchEnabled: boolean;
    advanced: {
        filter?: string;
        queryParameters?: string;
        createLinkParameters?: string;
        redirectUri?: string;
        endpointHint?: string;
        accessToken?: string;
        loginHint?: string;
        isConsumerAccount?: boolean;
        scopes?: string | [];
        navigation?: object;
    }
    success(result: OneDriveResult): void;
    cancel(): void;
    error(e: any): void;
}


interface OneDriveI {
    open(options: OneDriveOpenOptions): any;
}

declare var OneDrive: OneDriveI;