import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import { Label, TextField, Toggle } from "@fluentui/react";
import { UpdateUserList } from "../../../actions/Dashboard";
import { delay } from "../../../utils";
import Loading from "../../Layout/Loading";
import UserList from "./UserList.tsx";

const UserListWrapper = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const t = useRef();
  const tRef = useRef();
  const { currentUser, user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [showUserList, setShowUserList] = useState(true);

  const setState = (key, value) => {
    dispatch({
      type: "path-key-update",
      path: location.pathname,
      key: key,
      payload: value,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const UpdateList = useCallback(() => {
    if (
      state[location.pathname].list === undefined ||
      state[location.pathname].refresh
    )
      UpdateUserList(user, saveUser, (v) => {setState("list", v); setState("refresh", false)}, fatalError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user.current, currentUser, state]);

  useEffect(() => {
    if (
      state[location.pathname].list === undefined ||
      state[location.pathname].refresh
    )
      UpdateList();
  }, []);

  const fatalError = (e, callBack) => {
    if (e === "login") {
      dispatch({
        type: "message-update",
        key: "info",
        payload: "Please login again.",
      });
      navigate("/signin", { goback: true, callBack: callBack });
    } else if (e === "server down") {
      let ct = 0;
      while (ct < 30)
        // eslint-disable-next-line no-loop-func
        delay(1000).then(() => {
          ct++;
          dispatch({
            type: "message-update",
            key: "error",
            payload: "Server is down.  Retrying in..." + (30 - ct),
          });
        });
      callBack();
    }
  };

  const onChange = (text) => {
    if (t.current) clearTimeout(t.current);
    t.current = setTimeout(() => {
      setState("filter", text);
      t.current = undefined;
    }, 500);
  };

  if (!state[location.pathname].list)
    return <Loading msg="Loading User List" />;

  return (
    <div className="flex-column" style={{ height: "fit-content" }}>
      <div className="flex-column">
        <div className="active-user-selector-filter-w-button">
          <TextField
            ref={tRef}
            className="active-user-selector-filter-input"
            onChange={(_, text) => {
              onChange(text);
            }}
          />
          <div className="active-user-selector-refresh" onClick={UpdateList}>
            Refresh
          </div>
        </div>
        {state[location.pathname].list && (
          <div className="right-toggle">
            <Label className="spaced-label">Show User List</Label>
            <Toggle
              inlineLabel
              onText="On"
              offText="Off"
              checked={showUserList}
              onChange={(ev, checked) => {
                setShowUserList(checked);
              }}
            />
          </div>
        )}
      </div>
      {
        showUserList &&
        <UserList
          items={state[location.pathname].list}
          UpdateList={UpdateList}
          {...props}
        />
      }
    </div>
  );
};

export default UserListWrapper;
