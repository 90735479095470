import { useEffect, useState } from 'react';
import { CheckboxVisibility, Icon, DetailsList, PrimaryButton, TextField, TooltipHost } from '@fluentui/react';
import useUser from '../../../hooks/useUser';
import { useWindowDimensions } from 'ss-lib';
import axios from 'axios';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import { AuthorCard } from 'ss-lib';

const onRenderColumn = (item, column, index, updateItem, removeItem, setID) => {
    switch(column.key)
    {
        case 'name':
            return <div className="portal-list-item"><div className="portal-list-item-element">{item[column.key]}</div><TooltipHost content={"Remove " + item[column.key]}><Icon className="file-list-close-icon" iconName="Clear" onClick={() => removeItem(item)}/></TooltipHost></div>;
        case 'title':
            return <TextField value={item.title} onChange={(_, newValue) => { updateItem({...item, title: newValue}, item.index) }} />
        case 'authors':
            return item.authors.map((_v, i) => <AuthorCard item={item} index={i} setID={setID} setItem={(v) => {updateItem(v, item.index)}} />)
        default:
            return null;
    }
}

const getItems = files => {
    if(files === undefined || files.length === 0)
        return undefined;
    if(typeof files[0].file === 'function') {
        let items = [...files].map(async (v, i) => new Promise((resolve, reject) => v.file(resolve, reject)));
        let ret = [];
        items.forEach((v, i) => { 
            v
            .then(res => ret.push({name: res.name, key: res.name, file: res, index: i}))
            .catch(err => {console.log(err)})
        })
        return ret;
    }
    return [...files].map((v, i) => { return { name: v.name, title: v.name.substring(0, v.name.lastIndexOf(".")), key: v.name, file: v, index: i}})
}

const FileList = props => {
    const { user, saveUser } = useUser();
    const dim = useWindowDimensions();
    const [id, setID] = useState();
    const [items, setItems] = useState(getItems(props.files));
    
    useEffect(() => {
        setItems(getItems(props.files))
    }, [props.files])

    
    useEffect(() => {
        if(id) {
            document.getElementById(id)?.focus();
        }
    }, [id])


    const updateItem = (item, index) => {
        let itms = [...items];
        itms[index] = item;
        setItems(itms);
    }

    const submitItem = (item, retries) => {
        if(retries && retries > 5)
            return ;
        
        //Meta Data
        let md = {
            title: item.title ? item.title : item.file.name,
            targets: [user.current.scholarsift.profile],
            authors: item.authors
        }

        //Files
        let fd = new FormData();
        fd.append("file", item.file);
        fd.append("metadata", JSON.stringify(md));

        //Submit
        axios
            .post('/api/submit', fd, { 
                    headers : {
                                "Authorization":"Bearer " + user.current.token.id,
                                "Coda" :  user.current.scholarsift.coda
                    },
                    onUploadProgress: p => { props.setProgress(Math.round(100 * p.loaded/p.total)) }
            })
            .then(results => {
                console.log("Submitted " + item.file.name)
            })
            .catch(error =>  { APIErrorHandler(error, user, saveUser, () => { submitItem(item, retries ? retries + 1 : 1) }) });
    }

    const Submit = () => {
        items.forEach(v => { submitItem(v) })
        props.setSubmitted(true);
    }
    
    if(props.files === undefined || props.files.length === 0 || items === undefined)
        return null;
    
    return  <div className="file-list">
                <div className="file-list-wrapper">
                    <div>All Fields Are Optional</div>
                    <div className="file-list-close"><Icon className="file-list-close-icon" iconName="Clear" onClick={props.Clear}/></div>
                </div>
                <DetailsList
                    checkboxVisibility={CheckboxVisibility.hidden} 
                    items={items}
                    columns={[{key: "name", name: "File Name", minWidth: dim.width/10, maxWidth: dim.width/10}, {key: "title", name: "Title", minWidth: dim.width/4, maxWidth: dim.width/4}, {key: "authors", name:"Author(s)"}]}
                    onRenderItemColumn={(item, index, column) => onRenderColumn(item, column, index, updateItem, item => { setItems(items.filter(v => v.index !== item.index)) }, setID) } 
                />
                <div className="island"><PrimaryButton text="Submit" className="submit-button" onClick={Submit}/></div>
            </div>
}

export default FileList;