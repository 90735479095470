import { useState } from "react";
import { updateNode } from "../../actions/Nodes";
import {
  Button,
  Field,
  Input,
  Subtitle1,
  Subtitle2,
} from "@fluentui/react-components";

const Form = ({ styles, node, user, saveUser, callBack, fatalError }) => {
  const [formData, setFormData] = useState({
    code: node.code,
    title: node.title,
    section: node.section,
    jurisdiction: node.jurisdiction,
    type: node.type,
  });

  const { code, title, section, jurisdiction, type } = formData;

  const onChange = (e) => {
    e.preventDefault();

    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateNode(user, saveUser, formData, callBack, fatalError);
  };

  return (
    <div
      style={{
        maxWidth: "100%",
        width: 600,
        height: "fit-content",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Subtitle1 className={styles.modalHeader}>
        Short Cite: {node["short cites"][0].replace("?", "§")}
        {/*         {` ${title === undefined ? "" : title} ${
          code === undefined ? "" : code
        }${title !== undefined && code !== undefined ? ` § ` : " "}${
          section === undefined ? "" : section
        }`} */}
      </Subtitle1>
      <form>
        <div className={styles.row}>
          <Field label="Title" style={{ margin: "0 20px 10px 10px" }}>
            <Input
              placeholder={node.title}
              name="title"
              value={title}
              onChange={onChange}
            />
          </Field>
          <Field label="Code" style={{ margin: "0 20px 10px 10px" }}>
            <Input
              placeholder={node.code}
              name="code"
              value={code}
              onChange={onChange}
            />
          </Field>
          <Field label="Section" style={{ margin: "0 20px 10px 10px" }}>
            <Input
              placeholder={node.section}
              name="section"
              value={section}
              onChange={onChange}
            />
          </Field>
          <Field label="Jurisdiction" style={{ margin: "0 20px 10px 10px" }}>
            <Input
              placeholder={node.jurisdiction}
              name="jurisdiction"
              value={jurisdiction}
              onChange={onChange}
            />
          </Field>
          <Field label="Type" style={{ margin: "0 20px 10px 10px" }}>
            <Input
              placeholder={node.type}
              name="type"
              value={type}
              onChange={onChange}
            />
          </Field>
        </div>
        {/* 
        {columns.map(
          (item, index) =>
            index > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Field
                  label={
                    columns[index].columnId[0].toUpperCase() +
                    columns[index].columnId.slice(1)
                  }
                  orientation="horizontal"
                  style={{ margin: "20px" }}
                >
                  <Input
                    style={{ width: "80%" }}
                    placeholder={
                      typeof node[columns[index].columnId] === "object"
                        ? ""
                        : node[columns[index].columnId]
                    }
                    name={columns[index].columnId}
                    value={
                      typeof node[columns[index].columnId] === "object"
                        ? ""
                        : node[columns[index].columnId]
                    }
                    onChange={onChange}
                  />
                </Field>
              </div>
            )
        )} */}
      </form>
      <div className={styles.modalFooter}>
        <Button className={styles.button}>Cancel</Button>
        <Button className={styles.button} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Form;
