import { useEffect, useState } from 'react';
import { sentenceCase } from "ss-lib";
import { CheckboxVisibility, DetailsList, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { statusItems as classes} from '../../utils';

const getItemList = values => {
    let ret = [];
    values.sort().forEach(v => {
        ret.push({key: v, name:v})
    })
    return ret;
}

const StatusItem = props => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(getItemList(props.details));
    const [msg, setMsg] = useState();
    
    useEffect(() => {
        setItems(getItemList(props.details));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.details])

    if(props.type === undefined || !classes[props.type])
        return null;

    return  <div className={"submitted-item-" + classes[props.type].toLowerCase()} onClick={() => { setOpen(true); setMsg(undefined); }}>
                <div>{sentenceCase(classes[props.type]) + " " + props.details.length}</div>
                <Panel
                    type={PanelType.medium}
                    isOpen={open}
                    onDismiss={() => setOpen(false)}
                    headerText={classes[props.type] + " List"}
                    closeButtonAriaLabel="Close"
                >
                    <DetailsList
                        checkboxVisibility={CheckboxVisibility.always}
                        columns={[{key: "name", name: "Law Review"}]}
                        items={items}
                        isHeaderVisible={false}
                        onRenderItemColumn={(item, _, column) => <div>{item[column.key]}</div> }
                    />
                    <div className="right-row">
                        <PrimaryButton text="Cancel" onClick={() => { setOpen(false)}} />
                    </div>
                    <div>{msg}</div>
                </Panel>
            </div>
}

export default StatusItem;