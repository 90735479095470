import { useState, useEffect } from "react";
import SystemCard from "./SystemCard";
import "./Status.scss";

const Tabs = ({ pages, items, doTest, doReset }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [newPageList, setNewPageList] = useState(pages);

  const selectTab = (e, index) => {
    e.preventDefault();

    setActiveTab(index);

    /*     setNewPageList(() => {
      let newArr = [...pages];
      newArr.unshift(newArr[index]);
      newArr.splice(index + 1, 1);
      console.log(newArr);
      return newArr;
    }); */
  };

  return (
    <div className="wrapper">
      <div className="tabs">
        {pages.map((p, index) => (
          <label
            className="tab"
            id={`${activeTab === index && "active-tab"}`}
            htmlFor={index}
            key={index}
            onClick={(e) => selectTab(e, index)}
          >
            {p}
          </label>
        ))}
      </div>
      <SystemCard
        id={`${activeTab !== null && "active-panel"}`}
        items={items[activeTab]}
        title={pages[activeTab]}
        doReset={(e) => doReset(e, activeTab)}
        doTest={doTest}
      />
    </div>
  );
};

export default Tabs;

//where we're at: want to change the order of the tabs depending on the active tab, but will have to change the page and row numbers in the index component
