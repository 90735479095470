import { useEffect, useRef, useState } from 'react';
import AddGroup from './AddGroup';
import GroupDetails from './GroupDetails';
import GroupList from './GroupList';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import useUser from '../../../hooks/useUser';
import axios from 'axios';
import useLocationState from '../../../hooks/useLocationState';
import ViewPort from '../../Layout/ViewPort';

const Groups = props => {
    const { user, saveUser } = useUser();
    const [group, setGroup] = useState();
    const [groups, setGroups] = useState([]);
    const { state } = useLocationState();
    const h = useRef();

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    const UpdateList = retries => {
        if(retries && retries > 5)
            return ;

        axios
            .get("/api/groups", {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" : user.current.scholarsift.coda
                }
            })
            .then(response => { 
                setGroups(response.data.groups);
            })
            .catch(error => APIErrorHandler(error, user, saveUser, v => { UpdateList(retries ? retries + 1 : 1) }));
    }

    useEffect(() => {
        document.title = "ScholarSift - Dashboard Group Administrator"
        if(groups.length === 0)
            UpdateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return  <ViewPort>
                <div style={{minHeight: h.current, width: '100%'}}>
                    <div className="group-wrapper">
                        <AddGroup />
                        {groups.length > 0 && <GroupList groups={groups} setGroup={setGroup}/>}
                        {group && <GroupDetails group={group} />}
                    </div>
                </div>
            </ViewPort>

}

export default Groups;