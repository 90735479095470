import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { PrimaryButton, ProgressIndicator } from "@fluentui/react";
import FileList from "./FileList";
import SharedList from "./SharedList";
import { v4 as uuid } from "uuid";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import {
  OneDriveClick,
  GoogleDriveClick,
  DropBoxClick,
} from "../../../actions/SharedDrives";
import ViewPort from "../../Layout/ViewPort";
import { DNDSelector } from "ss-lib";

// Drop handler function to get all files
async function getAllFileEntries(dataTransferItemList) {
  let fileEntries = [];
  // Use BFS to traverse entire directory/file structure
  let queue = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      fileEntries.push(entry);
    } else if (entry.isDirectory) {
      queue.push(...(await readAllDirectoryEntries(entry.createReader())));
    }
  }
  return fileEntries;
}

// Get all the entries (files or sub-directories) in a directory
// by calling readEntries until it returns empty array
async function readAllDirectoryEntries(directoryReader) {
  let entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
}

// Wrap readEntries in a promise to make working with readEntries easier
// readEntries will return only some of the entries in a directory
// e.g. Chrome returns at most 100 entries at a time
async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

const Upload = (props) => {
  const { instance, accounts } = useMsal();
  const { state } = useLocationState();
  const { user, setTitle } = useUser();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [files, setFiles] = useState();
  const [progress, setProgress] = useState();
  const [submitted, setSubmitted] = useState();
  const [remoteSubmitted, setRemoteSubmitted] = useState();
  const [googleLoading, setGoogleLoading] = useState();
  const [googleAuth, setGoogleAuth] = useState();
  const [googlePick, setGooglePick] = useState();
  const [msftToken, setMsftToken] = useState();
  const [remoteFiles, setRemoteFiles] = useState();
  const h = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.title = "ScholarSift - Upload Submissions";
    setTitle("Upload Submissions");
    if (
      user.current &&
      user.current.token &&
      user.current.token.type === "Microsoft"
    ) {
      if (!msftToken) {
        const tokenRequest = {
          scopes: ["files.read", "files.read.all"],
          account: accounts[0] || {},
        };
        instance
          .acquireTokenSilent(tokenRequest)
          .then((res) => {
            setMsftToken(res.accessToken);
          })
          .catch((err) => {
            console.log(err);
            setMsftToken(null);
          });
      }
    } else {
      setMsftToken(null);
    }

    if (!googleAuth) {
      if (!googleLoading) {
        if (window.gapi) {
          setGoogleLoading(true);
          window.gapi.load("auth", {
            callback: () => {
              setGoogleAuth(true);
            },
          });
          window.gapi.load("picker", {
            callback: () => {
              setGooglePick(true);
            },
          });
        }
      }
    }

    return () => {
      setTitle("");
    };
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const SelectFile = (e) => {
    if (e === null || e === undefined) return;
    setFiles(e.target.files);
  };

  const Clear = () => {
    setFile(undefined);
    setProgress(undefined);
    setFiles(undefined);
    setSubmitted(undefined);
  };

  const ClearRemote = () => {
    setRemoteSubmitted(undefined);
    setRemoteFiles(undefined);
  };

  const onDrop = (e) => {
    getAllFileEntries(e.dataTransfer.items)
      .then((results) => {
        setFiles(results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (progress) {
    if (progress === 100) {
      setProgress(undefined);
      return <div className="island">Finished Uploading {file}</div>;
    }
    return (
      <div className="island">
        <ProgressIndicator
          label={"Uploading " + file}
          percentComplete={progress}
        />
      </div>
    );
  }

  return (
    <ViewPort>
      <div style={{ flexDirection: "column", height: h.current, width: "90%", margin:"auto"}}>
        {msftToken !== undefined && googleAuth && googlePick && (
          <div className="shared-drive-options">
                      <div className="dropbox-button" onClick={() => { DropBoxClick(v => { setRemoteFiles(remoteFiles ? [...remoteFiles, v] : [v]) }) }}>
                        <img src="/dropbox.svg" alt="Dropbox Icon" />
                      </div>
                      <div className="gdrive-button" onClick={() => { GoogleDriveClick(v => { if(v.results.action !== "cancel") setRemoteFiles(remoteFiles ? [...remoteFiles, v] : [v]) }); } }>
                        <img className="onedrive-icon" src="/drive.png" alt="Google Drive Icon" />
                        <div className="gdrive-text">Google Drive™</div>
                      </div>
                      <div className="onedrive-button" onClick={() => { OneDriveClick(msftToken, v => { setRemoteFiles(remoteFiles ? [...remoteFiles, v] : [v]) }) }}>
                        <img className="onedrive-icon" src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/onedrive_48x1.svg" alt="OneDrive product icon"/>
                        <div className="onedrive-text">OneDrive</div>
                      </div>    
                    </div>
        )}
        <DNDSelector
          onDrop={onDrop}
          Clear={Clear}
          SelectFile={SelectFile}
          multiple={true}
          text="Drop files or folders to add, or click to browse"
        />
        {submitted ? (
          <>
            <div className="display-header">
              <div className="display-name">Submitted</div>
            </div>
            {[...files].map((v) => (
              <div key={uuid()}>{v.name}</div>
            ))}
            <div className="portal-upload-buttons">
              <PrimaryButton
                className="spaced-right"
                text="Add More Files"
                onClick={Clear}
              />
              <PrimaryButton
                text="Go To Submissions"
                onClick={() => {
                  navigate("/portal/list");
                }}
              />
            </div>
          </>
        ) : (
          <FileList
            files={files}
            Clear={Clear}
            setFile={setFile}
            setProgress={setProgress}
            setSubmitted={setSubmitted}
          />
        )}
        {remoteSubmitted ? (
          <>
            <div className="display-header">
              <div className="display-name">Submitted Remote Files</div>
            </div>
            {[...remoteSubmitted].map((v) => (
              <div key={uuid()}>{v.name}</div>
            ))}
            <div className="portal-upload-buttons">
              <PrimaryButton
                className="spaced-right"
                text="Add More Files"
                onClick={ClearRemote}
              />
              <PrimaryButton
                text="Go To Submissions"
                onClick={() => {
                  navigate("/portal/list");
                }}
              />
            </div>
          </>
        ) : (
          <SharedList
            items={remoteFiles}
            Clear={ClearRemote}
            setSubmitted={setRemoteSubmitted}
          />
        )}
      </div>
    </ViewPort>
  );
};

export default Upload;
