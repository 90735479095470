import { sentenceCase } from "ss-lib";

const romans = ["i", "v", "x", "l", "c", "d", "m"];

export const isRomanNumeral = (s) => {
  if (!s || s.length === 0) return -1;

  s = s.trim();
  for (var i = 0; i < s.length; i++)
    if (romans.indexOf(s[i].toLowerCase()) === -1) return false;
  return true;
};

export const ssToast = "ss-toast-id";

export const calcH = (state) => {
  const { viewport } = state;

  const { h, v, m, f, o } = viewport;
  return h && v && m && f && o
    ? v > h - (m + f)
      ? v
      : h - (m + f + o)
    : undefined;
};

export const calcMaxH = (state) => {
  const { viewport } = state;
  const { h, v, m, f, o } = viewport;
  return h && v && m && f && o ? h - (m + f + o) : undefined;
};

export const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

export const deepEquals = (o, t) => {
  if (o === undefined) return t === undefined;
  if (t === undefined) return false;

  if (typeof t === "string" || Array.isArray(t)) return false;

  let ok = Object.keys(o);
  let tk = Object.keys(t);
  if (ok.length !== tk.length) return false;

  let ret = true;
  ok.forEach((k) => {
    if (ret && k !== "categoryBlock") {
      if (Array.isArray(o[k])) {
        if (!Array.isArray(t[k])) {
          ret = false;
          return;
        }
        o[k].forEach((v, i) => {
          if (t[k][i] !== v) {
            ret = false;
            return;
          }
        });
      } else if (
        typeof o[k] === "object" &&
        o[k] !== null &&
        typeof t[k] === "object" &&
        t[k] !== null
      ) {
        if (!deepEquals(o[k], t[k])) {
          ret = false;
          return;
        }
      } else {
        if (o[k] !== t[k]) {
          ret = false;
          return;
        }
      }
    }
  });

  return ret;
};

export const checkProfile = (pro, dispatch) => {
  console.log(pro)
  if (!pro) return false;
  if (!pro.name || pro.name.length === 0) return false;
  if (!pro.title || pro.title.length === 0) return false;
  if (!pro.roles || pro.roles.length === 0) return false;
  if (!pro.institutions || pro.institutions.length === 0) return false;
  if (pro.categories) {
    let p = [];
    pro.categories.forEach((v) => {
      p.push(sentenceCase(v));
    });
    pro.categories = p;
  }
  let ret = pro.categories && pro.categories.length > 0;
  if (ret && dispatch)
    dispatch({ type: "message-update", key: "mandatory", payload: ret });
  return ret;
};

export const emptyProfile = (pro) => {
  if (!pro) return true;
  if (pro.name && pro.name.length > 0) return false;
  if (pro.title && pro.title.length > 0) return false;
  if (pro.roles && pro.roles.length > 0) return false;
  if (pro.institutions && pro.institutions.length > 0) return false;
  if (pro.categories) {
    let p = [];
    pro.categories.forEach((v) => {
      p.push(sentenceCase(v));
    });
    if (pro.categories && pro.categories.length > 0) return false;
  }
  return true;
};

export const GetLink = (item) => {
  let link = {
    url: "",
    type: "",
  };

  if (item["hidden"]) return link;

  if (item["custom download url"]) {
    link.url = item["custom download url"];
    link.type = "custom";
  }

  if (item["download url"]) {
    link.type = "download";
    link.url = "/api/download/" + btoa(item["n"]);
  }

  if (item["pdf url"]) {
    link.url = item["pdf url"];
    link.type = "open";
  }

  if (item["url"]) {
    link.url = item["url"];
    link.type = "open";
  }

  return link;
};

export const assignmentTasks = ["Read"];

export const assignmentStatus = ["Assigned", "In Progress", "Complete"];

export const statusItems = [
  "New Submission",
  "In Progress",
  "Offer",
  "Declined",
  "Expedite",
  "Withdrawn",
  "Accepted",
  "Resubmission",
];

export const editorStatusItems = [
  "Assigned",
  "In Progress",
  "Completed",
  "Recommend",
  "Do not recommend",
];

export const displayPath = {
  "/": "Home",
  "/bugs": "Bugs",
  "/analyze": "Analyze",
  "/dashboard": "Dashboard",
  "/dashboard/accounts": "Dashboard - Accounts",
  "/dashboard/groups": "Dashboard - Groups",
  "/dashboard/maintenance": "Dashboard - Maintenance",
  "/dashboard/usage": "Dashboard - Usage",
  "/manage/papers": "Manage - Papers",
  "/manage/users": "Manage - Users",
  "/manage/submit": "Manage - Submit a Paper",
  "/manage/submissions": "Manage - Submissions",
  "/nodes": "Nodes",
  "/papers": "My Papers",
  "/payment": "Subscribe",
  "/portal": "Submissions Portal",
  "/portal/list": "Submissions Portal - List",
  "/portal/upload": "Submissions Portal - Upload",
  "/profile": "Profile",
  "/submit": "Submit",
  "/submissions": "Submissions",
};

export const mTypes = [
  {
    key: "Article",
    text: "Article",
  },
  {
    key: "Book Review",
    text: "Book Review",
  },
  {
    key: "Essay",
    text: "Essay",
  },
];

export const getListStyle = (h) => {
  return {
    root: {
      overflowX: "auto",
      selectors: {
        "& [role=grid]": {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          height: h,
        },
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
    },
    contentWrapper: {
      flex: "0 1 auto",
      overflowY: "auto",
      overflowX: "auto",
    },
  };
};
