import {
  makeStyles,
  shorthands,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  table: {
    ...shorthands.margin("auto"),
    width: "90%",
  },
  footer: {
    justifyContent: "center",
    ...shorthands.margin("0", "auto", "50px", "auto"),
  },
  footerSmall: {
    justifyContent: "center",
    ...shorthands.margin("0", "auto", "30px", "auto"),
  },
  button: {
    ...shorthands.margin("20px", "auto", "auto", "auto"),
    width: "fit-content",
    backgroundColor: "#7366f0",
    color: "white",
  },
});

const StatusTable = ({
  viewAll,
  logView,
  title,
  allRowsSelected,
  someRowsSelected,
  toggleAllRows,
  rows,
  items,
  loading,
}) => {
  const styles = useStyles();

  const errors = [
    "Error",
    "error",
    "E00003",
    "E_WC_01",
    "E_WC_03",
    "E_WC_08",
    "E_WC_10",
    "E_WC_13",
    "E_WC_14",
    "E_WC_18",
    "E_WC_19",
    "E_WC_21",
    500,
    502,
    503,
    504,
    501,
    401,
    403,
    404,
    400,
    429,
  ];

  const warnings = [
    "E_WC_02",
    "E_WC_04",
    "E_WC_05",
    "E_WC_06",
    "E_WC_07",
    "E_WC_15",
    "E_WC_16",
    "E_WC_17",
  ];

  return (
    <>
      <Table
        aria-label="Status Table"
        size={viewAll || logView ? "extra-small" : "medium"}
        className={styles.table}
        style={{
          marginTop: title === "AuthNet" ? -15 : viewAll || logView ? 10 : -10,
        }}
      >
        <TableHeader>
          <TableRow>
            {title === "AuthNet" && (
              <TableSelectionCell
                checked={
                  allRowsSelected ? true : someRowsSelected ? "mixed" : false
                }
                onClick={toggleAllRows}
                checkboxIndicator={{ "aria-label": "Select all rows " }}
              />
            )}
            <TableHeaderCell>
              <TableCellLayout style={{ fontWeight: 600, fontSize: 16 }}>
                Request
              </TableCellLayout>
            </TableHeaderCell>
            <TableHeaderCell>
              <TableCellLayout style={{ fontWeight: 600, fontSize: 16 }}>
                Endpoint
              </TableCellLayout>
            </TableHeaderCell>
            <TableHeaderCell>
              <TableCellLayout style={{ fontWeight: 600, fontSize: 16 }}>
                Status
              </TableCellLayout>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map(({ item, selected, onClick }, index) => (
            <TableRow
              key={index}
              aria-selected={selected}
              onClick={onClick}
              style={{ backgroundColor: !(index % 2) && "#f8f8fb" }}
            >
              {title === "AuthNet" && (
                <TableSelectionCell
                  checked={selected}
                  checkboxIndicator={{ "aria-label": "Select row" }}
                />
              )}
              <TableCell>{items[index].name}</TableCell>
              <TableCell>{items[index].endpoint}</TableCell>
              <TableCell
                id={
                  items[index].name === "Process Document" &&
                  items[index].status === 500
                    ? "text-warning"
                    : items[index].name === "Create Login"
                    ? "text-warning"
                    : errors.includes(items[index].status)
                    ? "text-error"
                    : warnings.includes(items[index].status)
                    ? "text-warning"
                    : items[index].status === 200 ||
                      items[index].status === "Ok"
                    ? "text-success"
                    : "text-default"
                }
              >
                {items[index].status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default StatusTable;
