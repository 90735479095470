import { Navigate, useLocation } from "react-router-dom";
import useUser from '../../hooks/useUser';

const AdminRoute = ({children}) => {
  const { allowRoute } = useUser();
  const location = useLocation();
  
  if(!allowRoute("admin"))
    return <Navigate to="/signin" state={{location: location}} replace />;
  return children;
}

export default AdminRoute;