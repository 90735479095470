import { memo, useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import CombinedListItem from '../Analyze/NewResults/CombinedListItem';
import { Debug, SearchResults } from 'ss-lib';
import ViewPort from '../Layout/ViewPort';
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';

export const ExecSearch = (text, user, saveUser, callBack, fatalError) => {
    axios
      .post(
        "/api/search",
        { text: text, profile: user.current.scholarsift.profile },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
        if(Debug())
          console.log(response.data)
        callBack(response.data.ids);
      })
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) =>
          v ? fatalError(v) : ExecSearch(text, user, saveUser, callBack)
        )
      );
};

const Search = props => {
    const { user, saveUser, setTitle } = useUser();
    const [filter, setFilter] = useState("");
    const [items, setItems] = useState();
    const { state } = useLocationState();
    
    useEffect(() => {
        setTitle("Search");
    })

    const DoSearch = (e) => {
        if(((e.key && e.key === "Enter") || e.type === "click") && filter.length)
            ExecSearch(filter, user, saveUser, setItems, (e) => { console.log(e) });
    }

    const itemRenderer = memo((props) => <div style={props.style}><CombinedListItem item={props.data[props.index].i} index={props.index} dateRange={props.data.dateRange} linkToPage={true}/></div>);

    return  <ViewPort>
                <div style={{height: state.viewport.h}}>
                    <SearchResults data={items} itemSize={125} itemRenderer={itemRenderer} filter={filter} search={setFilter} searchMode={DoSearch} />
                </div>
            </ViewPort>
}

export default Search;