import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import { EditorProfile } from "../../../actions/Portal";
import { doCreateUser } from "../../../actions/Management";
import { Checkbox, PrimaryButton } from "@fluentui/react";
import EditorChecker from "./EditorChecker";

const EditorOps = (props) => {
  const location = useLocation();
  const { user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (
      state[location.pathname].current &&
      state[location.pathname].current.profile
    )
      EditorProfile(
        { profile: state[location.pathname].current.profile },
        user,
        saveUser,
        (v) => setState("ep", v)
      );
    // eslint-disable-next-line
  }, [state[location.pathname].current]);

  const setState = (key, value) => {
    dispatch({
      type: "path-key-update",
      path: location.pathname,
      key: key,
      payload: value,
    });
  };

  if (state[location.pathname].current === undefined) return null;

  if (
    state[location.pathname].current &&
    state[location.pathname].current.profile === undefined
  )
    return (
      <div className="create-panel">
        <Checkbox
          styles={{ text: { fontWeight: 600 } }}
          label="Notify user that an account was created"
          checked={checked}
          onChange={(_, chk) => setChecked(chk)}
        />
        <PrimaryButton
          className="form-btn active-user-form-btn"
          text="Create Account"
          onClick={() => {
            doCreateUser(user, saveUser, location, state, dispatch, checked, 0);
          }}
        />
      </div>
    );

  return (
    <EditorChecker
      profile={state[location.pathname].current.profile}
      updateProfile={(v) => {
        EditorProfile(
          { ...v, others: state[location.pathname].current.others },
          user,
          saveUser,
          (vv) => setState("ep", vv)
        );
      }}
      limits={
        state[location.pathname].ep ? state[location.pathname].ep.limits : []
      }
    />
  );
};

export default EditorOps;
