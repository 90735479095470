import { APIErrorHandler } from './APIErrorHandler';
import axios from 'axios';

export const EditorProfile = (data, user, saveUser, callback) => {
    axios.post("/api/ep", data, {
        headers : {  
                "Authorization":"Bearer " + user.current.token.id,
                "Coda" : user.current.scholarsift.coda
        }
    })
        .then(res => {
            if(callback)
                callback(res.data);
        })
        .catch(error => APIErrorHandler(error, user, saveUser, v => {
            console.log(v);
            if(!v)
                EditorProfile(data, user, saveUser, callback)
        }));
}

export const DeleteTask = (data, user, saveUser, callback) => {
    axios.post("/api/deltask", data, {
        headers : {  
                "Authorization":"Bearer " + user.current.token.id,
                "Coda" : user.current.scholarsift.coda
        }
    })
        .then(res => {
            if(callback)
                callback(res.data);
        })
        .catch(error => APIErrorHandler(error, user, saveUser, v => {
            console.log(v);
            if(!v)
                DeleteTask(data, user, saveUser, callback)
        }));
}