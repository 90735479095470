import "./Buttons.scss";

const RoundedButton = ({ title, id, onClick, icon }) => {
  return (
    <button className="btn rounded-btn" id={id} onClick={onClick}>
      {title} {icon}
    </button>
  );
};

export default RoundedButton;
