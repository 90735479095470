import { Button, Input, Tooltip } from "@fluentui/react-components";
import { Search24Filled, GlobeSearch24Filled } from "@fluentui/react-icons";
import { useStyles } from "./Styles";
//import { SearchBox, TooltipHost } from "@fluentui/react";

import {DNDSelector} from "ss-lib";

import {
  bundleIcon,
  DismissCircle16Filled,
  DismissCircle16Regular,
} from "@fluentui/react-icons";

const SearchPanel = (props) => {
  const classes = useStyles();

  const {
    onClick,
    text,
    Clear,
    onDrop,
    ph,
    file,
    Search,
    SetText,
    setFile,
    targetURL,
    handleChange,
  } = props;

  const SelectFile = (e) => {
    if (e === null || e === undefined) return;
    setFile(e.target.files[0]);
  };

  const DoChange = (e) => {
    if (e) {
      if (e.nativeEvent) {
        if (
          e.nativeEvent.inputType === "insertFromPaste" ||
          e.nativeEvent.inputType === undefined
        ) {
          SetText(e.target.value, true);
        } else if (e.target.value === "") Clear();
        else SetText(e ? e.target.value : "");
      } else SetText(e ? e.target.value : "");
    }
  };

  const keyUp = e => {
    if(e.code === "Enter")
      props.Search();
  }

  const DismissIcon = bundleIcon(DismissCircle16Filled, DismissCircle16Regular);

  return (
    <div className={classes.searchPanelWrapper}>
      <div className={classes.searchContainer}>
        <div className={classes.inputContainer}>
          <Input
            className={classes.searchInput}
            placeholder={ph ? ph : "Citation, Title, Author (Full Text Search)"}
            value={text}
            onKeyUp={keyUp}
            onChange={DoChange}
            onClear={Clear}
            contentBefore={<Search24Filled className={classes.searchIcon} />}
            id="analyze-search-bar"
          />
          <Button
            shape="circular"
            className={classes.searchButton}
            onClick={Search}
          >
            Search
          </Button>
        </div>
        <DNDSelector focus={props.focus} preview={props.preview} onDrop={onDrop} Clear={Clear} SelectFile={SelectFile}/>
        <div className={classes.inputContainer}>
          <Input
            id="analyze-url-search-bar"
            className={classes.searchInput}
            placeholder="Enter a URL to Analyze"
            value={targetURL}
            onChange={handleChange}
            onDragEnter={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              onDrop(e);
            }}
            onClear={Clear}
            contentBefore={
              <GlobeSearch24Filled className={classes.searchIcon} />
            }
          />
          <Button
            shape="circular"
            className={classes.searchButton}
            onClick={onClick}
          >
            Analyze
          </Button>
        </div>
      </div>

      {file && (
        <div className="search-file-box">
          <div style={{ padding: "5px 0 5px 10px" }}>{file}</div>
          <Tooltip content="Clear data, cancel upload">
            <div className="search-file-cancel">
              <DismissIcon onClick={Clear} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SearchPanel;
