const UpdatePanel = props => {
    return <div>Hi</div>
}

export default UpdatePanel;

/*
import { useState } from 'react';
import axios from 'axios';

const updateDest = (dest, user) => {
    if(user === undefined || user.scholarsift === undefined)
        return undefined;

    axios
        .post("/api/update/" + dest, {}, {
                headers : {  
                    "Authorization":"Bearer " + user.user["id token"],
                    "Coda" : user.scholarsift.coda
                }
        })
        .then(response => {
            if(response.data.error)
                this.setState({run: "Error: " + response.data.error});
            return response.data.output;
        })
        .catch(error => console.log(error))

    return undefined;
}

const C = (props) => {
    const [runResults, setRunResults] = useState("");
    const user = useSelector(state => state.user);

    return  <div className="maintenance-update-panel">
                <div 
                    className="mup-start" 
                    aria-label={"update " + props.dest}
                    onClick={ () => { 
                        let r = updateDest(props.dest, user);
                        if(r)
                            setRunResults(r);
                    } }>
                        Update {props.dest}
                </div>
                <div className="mup-results">{runResults}</div>
            </div>
}

export default C;
*/