import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import ViewPort from '../Layout/ViewPort';
import { PreprintSplash } from 'ss-lib';
import axios from 'axios';

const Preprints = props => {
    const navigate = useNavigate();
    const [pp, setPP] = useState();
    const [fetching, setFetching] = useState(false);
    const { state } = useLocationState();
    const { setTitle } = useUser();

    useEffect(() => {
        document.title = "ScholarSift - Preprints";
        setTitle("Preprints");

        if(!fetching && pp === undefined) {
            setFetching(true);
            axios.get("/api/lspp").then((res) => { setPP(res.data) }).catch(err => {console.log(err)})
        }

        return () => {
        setTitle("");
        };
    })

    return  <ViewPort>
                <div style={{minHeight: state.viewport.h - (state.viewport.m + state.viewport.f)}}>
                    <PreprintSplash navigate={v => navigate(v)} pp={pp} height={state.viewport.h - (state.viewport.m + state.viewport.f)} width="95vw"/>
                </div>
            </ViewPort>
}

export default Preprints;