import FromDomain from './FromDomain';
import FromFile from './FromFile';

const AddGroup = props => {
    
    return  <>
                <FromDomain />
                <FromFile />
            </>
}

export default AddGroup;