import { Toast } from '@fluentui/react-components'
import axios from "axios";


export const searchFacultyBio = (
  data,
  setBios,
  setIsFetching,
  callBack,
  setLocationSearch
) => {
  setIsFetching(true);
  axios
    .post("/api/fs", data)
    .then((res) => {
      console.log(res);
      console.log(res.data);
      setBios(res.data.matches);
      setLocationSearch(false);
      return setIsFetching(false);
    })
    .catch((err) => {
      console.log(err);
      setIsFetching(false);
      let intent = "error"
      return callBack(<Toast>err.message</Toast>, {intent});
    });
};

export const searchFacultyTest = (p, row, callBack) => {
  console.log(p, row, "test");
  axios
    .post("/api/fs", { text: "BSU", postal: "83605", searchRadius: 100 })
    .then((res) => {
      return callBack(p, row, res, "Faculty Search");
    })
    .catch((err) => {
      console.log(err);
      //setIsFetching(false);
      return console.log(err.message);
    });
};
