import {
  makeStyles,
  typographyStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

export const useStyles = makeStyles({
  h2: {
    ...typographyStyles.subtitle2Stronger,
    ...shorthands.margin("20px", "auto", "auto", "auto"),
    textAlign: "center",
  },
  h1: {
    ...typographyStyles.subtitle2Stronger,
    ...shorthands.margin("auto"),
    textAlign: "center",
    color: "var(--dark-blue)",
  },

  analyzeButton: {
    backgroundColor: "var(--dark-blue)",
    color: "#fff",
    fontFamily: "var(--sans-serif)",
    fontSize: "16px",
    fontWeight: "400",
    width: "250px",
    ...shorthands.margin("20px", "auto"),
    "&:hover": { backgroundColor: "var(--violet)" },
  },
  searchPanel: {
    ...shorthands.margin("50px", "auto", "auto", "auto"),
    textAlign: "center",
    color: "var(--dark-blue)",
  },
  previewSearchPanel: {
    width: "100%",
    ...shorthands.margin("auto"),
    textAlign: "center",
    color: "var(--dark-blue)",
  },
  analyzeTitle: {
    ...shorthands.margin("20px", "auto", "20px", "auto"),
  },
  analyzeUrl: {
    ...shorthands.margin("auto", "auto", "20px", "auto"),
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: "800px",
  },
  card: {
    ...shorthands.padding("10px"),
    ...shorthands.margin("10px", "auto", "10px", "auto"),
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "auto",
    scrollbarWidth: "none",
  },
  clear: {
    ...shorthands.margin("auto", "10px", "auto", "10px"),
    backgroundColor: "var(--berry)",
    color: "#fff",
  },
  column: {
    ...shorthands.gap("5px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  dndIcon: {
    ...shorthands.margin("auto", "10px", "7px", "auto"),
  },
  dndInnerText: {
    ...shorthands.flexCenter,
    ...shorthands.flexColumn,
    ...shorthands.gap("5px"),
    cursor: "pointer",
  },
  divider: {
    marginTop: "10px",
  },
  dndSelector: {
    ...shorthands.border("1px", "dashed", "#000"),
    ...shorthands.margin("20px", "auto", "20px", "auto"),
    backgroundColor: "#fff",
    color: "var(--dark-blue)",
    cursor: "pointer",
    fontFamily: "var(--sans-serif)",
    fontSize: "16px",
    height: "300px",
    justifyContent: "center",
    minWidth: "360px",
    width: "50%",
  },
  excel: {
    backgroundColor: tokens.colorPaletteLightGreenForeground1,
    width: "250px",
  },
  filterSearch: {
    width: "250px",
  },
  header: {
    ...shorthands.margin("10px", "10px", "auto", "10px"),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    width: "fit-content",
    ...shorthands.gap("5px"),
    height: "fit-content",
    ...shorthands.margin("auto", "auto", "auto", "auto"),
  },
  mediaIcon: {
    color: "var(--darkest-blue)",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  main: {
    ...shorthands.flexCenter,
    ...shorthands.flexColumn,
    ...shorthands.gap("5px"),
    ...shorthands.margin("10px", "auto", "10px", "auto"),
    backgroundColor: "var(--background-color)",
    scrollbarWidth: "none",
  },
  progressBar: {
    ...shorthands.margin("10px", "auto", "10px", "auto"),
    minWidth: "360px",
    width: "50vw",
  },
  resultsHeader: {
    width: "96vw",
    ...shorthands.margin("80px", "auto", "10px", "auto"),
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontSize: "1.25em",
  },
  /*   resultsContainer: {
    height: "fit-content",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    ...shorthands.gap("5px"),
  }, */
  sourceHeader: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    position: "relative",
    justifyContent: "space-between",
    ...shorthands.margin("10px", "auto", "auto", "auto"),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.overflow("hidden"),
    width: "96vw",
    ...shorthands.gap("5px"),
    ...shorthands.margin("auto", "auto", "10px", "auto"),
    justifyContent: "center",
  },
  searchButton: {
    width: "150px",
    backgroundColor: "var(--dark-blue)",
    ...shorthands.margin("auto", "auto", "auto", "20px"),
    color: "#fff",
    fontFamily: "var(--sans-serif)",
    fontSize: "16px",
    fontWeight: "400",
    "&:hover": { backgroundColor: "var(--violet)", color: "#fff" },
  },
  searchContainer: {
    width: "100%",
    minWidth: "240px",
    ...shorthands.margin("auto", "auto", "20px", "auto"),
    justifyContent: "center",
  },
  searchIcon: {
    color: "var(--dark-blue)",
  },
  searchInput: {
    width: "100%",
  },
  searchPanelWrapper: {
    ...shorthands.margin("30px", "auto", "30px", "auto"),
  },
  tooltip: {
    backgroundColor: '#0F6CBD',
    color: '#fff',
    ...shorthands.padding('5px'),
    ...shorthands.borderRadius('4px'),
    fontFamily:
      "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif",
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  urlCard: {
    display: "flex",
    flexDirection: "column",
    width: "50vw",
    minWidth: "360px",
    color: "white",
    ...shorthands.overflow("auto"),
    ...shorthands.margin("20px", "auto", "40px", "auto"),
    ...shorthands.padding("0", "25px", "25px", "25px"),
    justifyContent: "center",
    alignItems: "center",
  },
  wordCount: {
    height: "fit-content",
    ...shorthands.padding("10px"),
    ...shorthands.overflow("hidden"),
  },
  tooltip: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundInverted,
  },
  topics: {
    height: "400px",
    ...shorthands.padding("10px"),
  },
  authors: {
    ...shorthands.padding("10px"),
    height: "fit-content",
    ...shorthands.overflow("hidden"),
  },
  genderBalance: {
    height: "325px",
  },
  jComps: {
    ...shorthands.padding("20px", "0", "0", "0"),
    height: "500px",
    ...shorthands.overflow("hidden"),
  },
  sourceTypes: {
    height: "350px",
    ...shorthands.padding("10px", "0", "0", "0"),
  },
  small: {
    minWidth: "300px",
    width: "20vw",
    justifyContent: "center",
  },
  large: { width: "52vw", height: "100vh", ...shorthands.overflow("auto") },
  medium: { width: "50vw" },
  extraLarge: {
    width: "65vw",
    height: "100vh",
    ...shorthands.overflow("auto"),
  },
});
