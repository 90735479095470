import { createContext, useState } from 'react';
import Alert from '../components/Layout/Alert';
import { useSpring, a, config } from "react-spring";
   
const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("")

  const spring = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0)` : `translateY(-10%)`,
    config: config.gentle,
  });

  const showAlert = (content) => {
    setContent(content);
    setIsOpen(true);
  };

  const hideAlert = () => {
    setContent("");
    setIsOpen(false);
  }

    return (
      <AlertContext.Provider value={{ showAlert, hideAlert }}>
        {children}
          <a.div>
          <Alert style={spring} Alert={Alert} isOpen={isOpen} handleClose={hideAlert}>
            { content }
          </Alert>
        </a.div>
      </AlertContext.Provider>
    )
};

export const AlertConsumer = AlertContext.Consumer;

export default AlertContext;