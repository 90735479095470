import { ArrowNext24Regular } from "@fluentui/react-icons";

const Log = ({ style, a, setLogView, messages }) => {
  return (
    <a.div className="log" style={style}>
      <div className="close" onClick={() => setLogView(false)}>
        <ArrowNext24Regular />
      </div>
      <div className="field-wrapper" style={{ width: "100%" }}>
        {messages.map((m, index) => (
          <p key={index}>{m}</p>
        ))}
      </div>
    </a.div>
  );
};

export default Log;
