import { useEffect, useRef } from "react";
import useLocationState from "../../hooks/useLocationState";
import Slide from "../Layout/Splash/Slide";
import ViewPort from "../Layout/ViewPort";
import { NavLink } from "react-router-dom";

const About = () => {
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  useEffect(() => {
    document.title = "ScholarSift - About us";
  });

  const header = (
    <h1 style={{ fontWeight: 600 }}>
      About
      <span> us</span>
    </h1>
  );
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">
        About
        <span> us</span>
      </h1>
      <p>ScholarSift is the first analytical platform to deploy machine learning to legal scholarship.</p>
      <p>Co-founders Rob Anderson and Trent Wenzel previously developed an analytics technology for transactional drafting, which is now a flagship feature for one of the largest legal research platforms. Together, they now present a technological breakthrough to the law review writing, editing, and article selection process. With deep domain expertise in law and technology, Rob and Trent envision a law review system that works better for authors, editors, and readers of legal scholarship.
      </p>
      <p>
        Kat Yeary-Rantala joined the team as Lead Frontend Developer in 2021.
        With combined experience in building user-centric applications and
        library research assistant, Kat is excited to bring her skills to the
        ScholarSift platform.{" "}
      </p>
      <NavLink to="/faqs">FAQs</NavLink>
    </main>
  );

  return (
    <ViewPort>
      <Slide header={header} text={text} />
    </ViewPort>
  );
};

export default About;
