import { useEffect, useRef } from "react";
import { TextField } from "@fluentui/react";
import useMeasure from "react-use-measure";
import useLocationState from "../../hooks/useLocationState";

const searchMargin = 20;

const ListFitler = (props) => {
  const [ref, { height }] = useMeasure();
  const { dispatch } = useLocationState();
  const h = useRef();

  useEffect(() => {
    if (height !== h.current) {
      h.current = height + searchMargin * 2;
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "o",
        payload: Math.round(h.current),
      });
    }

    return () => {
      if (props.watch)
        dispatch({
          type: "path-key-update",
          path: "viewport",
          key: "o",
          payload: 0,
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, props.watch]);

  return (
    <div ref={ref}>
      <div className="floating-filter">
        <TextField
          className="list-filter"
          styles={{
            fieldGroup: [{ borderRadius: 20 }, { backgroundColor: "#f6f2f2" }],
          }}
          label={
            props.resultCountText ? "Filtered " + props.resultCountText : null
          }
          onChange={props.onFilterChanged}
          iconProps={{
            iconName: "Filter",
            style: { paddingRight: 10, color: "gray" },
          }}
          placeholder={"Filter Results"}
          borderless
        />
      </div>
    </div>
  );
};

export default ListFitler;
