export const planObjects = [
  {
    name: "Basic",
    upload: true,
    submissions: true,
    analysis: false,
    length: 999,
    renews: false,
    paid: 0,
  },
  {
    name: "48 Hour Plan",
    types: {
      "Student/VAP": "9.95",
      Faculty: "9.95",
      Public: "9.95",
    },
    upload: true,
    submissions: true,
    analysis: true,
    length: 2,
    paid: 1,
  },
  {
    name: "Monthly Plan",
    types: {
      "Student/VAP": "19.95",
      Faculty: "24.95",
      Public: "24.95",
    },
    upload: true,
    submissions: true,
    analysis: true,
    length: 30,
    paid: 2,
  },
  {
    name: "Yearly",
    types: {
      "Student/VAP": "189.50",
      Faculty: "239.50",
      Public: "239.50",
    },
    upload: true,
    submissions: true,
    analysis: true,
    length: 365,
    paid: 3,
  },
];

export const getPlanType = (user) => {
  const { role } = user.current.scholarsift;
  if (role === undefined) return "Public";
  if (
    role.indexOf("Student") > -1 ||
    role.indexOf("Visiting") > -1 ||
    role.indexOf("FAR") > -1
  )
    return "Student/VAP";
  if (
    role.indexOf("Doctrinal") > -1 ||
    role.indexOf("Clinical") > -1 ||
    role.indexOf("Librar") > -1
  )
    return "Faculty";
  return "Public";
};
