import { LaunchOneDrive } from './LaunchOneDrive.ts';
import { LaunchDropBox } from './LaunchDropBox.ts';
import creds from '../../creds.json';
import { pca } from '../../context/UserContext.js';

export const OneDriveClick = (token, callBack) => {
    console.log(token)
    LaunchOneDrive(creds.msftClientId, token)
        .then(res => {                        
            callBack({type: "microsoft", results: res, token: token})
        })
        .catch(err => { 
            if(err.toString().indexOf("can not find personal tenant url")) {
                const tokenRequest = {
                    scopes: ["files.read", "files.read.all"],
                }
                pca.acquireTokenPopup(tokenRequest).then((res) => { console.log(res); OneDriveClick(res.accessToken, callBack) }).catch(e => console.log(e));
            }
            console.log(err)
        })
}

export const GoogleDriveClick = callBack => {
    //Authorize
    window.gapi.auth.authorize({
        'client_id' : creds.googleClientId[window.location.hostname],
        'scope': ['https://www.googleapis.com/auth/drive.readonly'],
        'immediate': false
    }, res => { handleGoogleAuth(res, callBack) });
}

const handleGoogleAuth = (res, callBack) => {
    if(res && !res.error) {
        var picker = window.gapi.picker.api;
        var view = new picker.View(picker.ViewId.DOCS);
        view.setMimeTypes("application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document");
        var pickr = new picker.PickerBuilder()
            .enableFeature(picker.Feature.NAV_HIDDEN)
            .enableFeature(picker.Feature.MULTISELECT_ENABLED)
            .setAppId(creds.googleProjectId)
            .setOAuthToken(res.access_token)
            .addView(view)
            .addView(new picker.DocsUploadView())
            .setDeveloperKey(creds.googlePickerAPIKey)
            .setCallback(results => {
                callBack({type:"google", results: results, token: res.access_token});
            })
            .build();
         pickr.setVisible(true);
    }
    else {
        console.log("Error: "); 
        console.log(res);
    }
}

export const DropBoxClick = (callBack) => {
    LaunchDropBox()
        .then(res => {
            callBack({type: "dropbox", results: res});
        })
        .catch(err => { console.log(err) });
}