import { useEffect, useRef } from "react";
import useLocationState from "../../hooks/useLocationState";
import Slide from "../Layout/Splash/Slide";
import ViewPort from "../Layout/ViewPort";

const Demo = () => {
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  useEffect(() => {
    document.title = "ScholarSift - Schedule a Demo";
  });

  const header = <h1 style={{ fontWeight: 600 }}>Demo</h1>;
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">Demo</h1>
      <iframe
        title="demo"
        src="https://outlook.office365.com/owa/calendar/ScholarSiftDemoCalendar@draftools.com/bookings/"
      ></iframe>
    </main>
  );

  return (
    <ViewPort>
      <main id="slide-right-nav">
        <h1 id="slide-header-sm">Demo</h1>
        <iframe
          style={{ width: "100%", height: h.current }}
          title="demo"
          src="https://outlook.office365.com/owa/calendar/ScholarSiftDemoCalendar@draftools.com/bookings/"
        ></iframe>
      </main>
    </ViewPort>
  );
};

export default Demo;
