import { useEffect } from "react";
import { IconButton } from "@fluentui/react";
import Portal from "./Portal";
import { a, config, useSpring } from "react-spring";

const Alert = ({ isOpen, children, handleClose, alertType }) => {
    
    const spring = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0)` : `translateY(-10%)`,
    config: config.gentle,
      });

    useEffect(() => {
        const closeOnKeystroke = e => e.key === "Escape" || e.key === "Backspace" || e.key === " " ? handleClose() : null; 
        document.body.addEventListener("keydown", closeOnKeystroke);
        return () => {
        document.body.removeEventListener("keydown", closeOnKeystroke);
        };
    }, [handleClose]);
    
    if (!isOpen) return null;

    return (
        <Portal wrapperId="portal-container">
            <a.div className={`alert-overlay ${alertType}`} onClick={() => isOpen ? handleClose() : null} >
                <a.div className="alert-card" style={spring}>
                    <a.div onBlur={handleClose}>
                        <IconButton
                            id="modal-dismiss"
                            ariaLabel="Close popup modal"
                            onClick={handleClose}
                            iconProps={{ iconName: "Cancel", fontSize: 16 }}
                            title="Close"
                            />
                    </a.div>
                        <a.div id="modal-body">
                            {children}
                        </a.div>
                </a.div>
            </a.div>
        </Portal>
    )
}

export default Alert;