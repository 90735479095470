import { useEffect, useRef } from 'react';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import ViewPort from '../../Layout/ViewPort';
import ActiveUserSelector from '../../Management/ActiveUserSelector';
import EditorOps from './EditorOps';

const Editors = props => {
    const { state } = useLocationState();
    const { setTitle } = useUser();
    const h = useRef();

    useEffect(() => { 
        document.title = "ScholarSift - Manage Editors";
        setTitle("Manage Editors");
        return () => { setTitle("") }
    })

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    return (
        <ViewPort>
            <div className="portal-editors" style={{ height: h.current, minHeight: "100%" }}>
                 <ActiveUserSelector options={<EditorOps />} /> 
            </div>
         </ViewPort>)
}

export default Editors;