/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ComboBox } from '@fluentui/react';
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import stringSimilarity from 'string-similarity';
import { v4 as uuid} from "uuid";
import axios from "axios";
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import useUser from '../../../hooks/useUser';

const findClosest = (needle, haystack) => {
    let r = stringSimilarity.findBestMatch(needle.toLowerCase(), haystack);
    return r.bestMatch.rating > 0.5 || r.bestMatch.target.toLowerCase().indexOf(needle.toLowerCase()) === 0 ? r.bestMatchIndex : -1;
}

const keys = ["First Name", "Last Name", "Name", "Email", "Roles", "Categories", "Institutions", "CV", "Faculty"];
const lc = [];
const keyOps = [];
keys.forEach(v => {
    lc.push(v.toLowerCase());
    keyOps.push({key: v, text: v});
})

const getEmailList = (masterKey, master) => {
    let ret = [];
    if(!masterKey || !master)
        return ret;
    
    let data = master[masterKey];
    Object.keys(data).forEach(key => {
        let lc = key.toLowerCase();
        if(lc.indexOf("email") > -1 || lc.indexOf("e-mail") > -1) {
            data[key].forEach(v => { ret.push({key: v, text:v})})
        }
    })
    ret.sort((a, b) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0)
    return ret;
}

const GroupSelectorPanel = props => {
    const { user, saveUser } = useUser();
    const [group, setGroup] = useState("");
    const [adminKeys, setAdminKeys] = useState([]);
    const [cols, setCols] = useState([]);
    const [wbKeys, setWBKeys] = useState([]);

    const [selection, setSelection] = useState();
    const [selectionPanel, setSelectionPanel] = useState([]);
    const [emailList, setEmailList] = useState([]);

    useEffect(() => {
        if(props.data)
            setWBKeys(Object.keys(props.data))
    }, [props.data])

    useEffect(()=> {
        setSelection(0);
    }, [wbKeys])

    useEffect(() => {
        if(!props.data || !wbKeys.length || selection === null || selection === undefined)
            return ;
        
        let columns = {};
        Object.keys(props.data[wbKeys[selection]]).forEach(v => { 
            let c = findClosest(v, lc);
            columns[v] = c > -1 ? keys[c] : "";
        });
        setEmailList(getEmailList(wbKeys[selection], props.data));
        setCols(columns);
    }, [selection, wbKeys])

    useEffect(() => {
        let r = [];
        Object.keys(cols).forEach(v => {
            r.push(
                    <Dropdown
                        key={uuid()}
                        className="group-field"
                        options={keyOps}
                        selectedKey={cols[v]}
                        label={v}
                        onChange={(ev, option, index) => { setCols(p => { return {...p, [v]: option.text} }) } }
                    />
                );
        });
        setSelectionPanel(r);
    }, [cols])

    const processData = data => {
        axios
            .post('/api/creategroup', data, { 
                headers : {
    	                "Authorization":"Bearer " + user.current.token.id,
						"Coda" :  user.current.scholarsift.coda
                }
            })
            .then(results => {
                console.log(results.data);
                props.resetData();
            })
            .catch(error => APIErrorHandler(error, user, saveUser, () => { processData(data) }));
    }

    const submit = () => {
        let d = {
            group: group,
            admin: adminKeys,
            data: props.data[wbKeys[selection]],
            map: cols
        }
        processData(d);
    }

    const aSelect = (ev, option, number, value) => {
        if(option)
            setAdminKeys(prevAdminKeys => option.selected && option.key !== null? [...prevAdminKeys, option.key ] : prevAdminKeys.filter(k => option.key !== null && k !== option.key), );
    }

    if(!props.data)
        return null;
    
    return  <div className="group-selector-panel">
                {
                    wbKeys.length > 1 &&
                    <Dropdown
                        className="group-field"
                        options={wbKeys}
                        defaultSelectedKey={0}
                        label="Worksheet"
                        onChange={(ev, option, index) => { setSelection(index) }}
                    />
                }
                <div className="group-selection">
                    <div className="group-items">
                        <div className="group-options">
                            <TextField label="Group Name" value={group} onChange={(ev, nv) => {setGroup(nv)}} />
                            <ComboBox label="Group Admin(s)" multiSelect selectedKeys={adminKeys} onChange={aSelect} options={emailList} />
                        </div>
                        <div className="group-columns">
                            <div className="group-header">Columns</div>
                            {selectionPanel}
                        </div>
                    </div>
                    <PrimaryButton className="group-selector-button" text="Submit" onClick={submit}/>
                </div>
            </div>
}

export default GroupSelectorPanel;