import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import ActiveUserSelector from '../ActiveUserSelector';
import { Debug, Papers } from 'ss-lib';
import { doCreateUser } from '../../../actions/Management';
import ViewPort from '../../Layout/ViewPort';

const ManagePapers = props => {
    const location = useLocation();
    const { user, saveUser, setTitle } = useUser();
    const { state, dispatch } = useLocationState();
    const h = useRef();
    
    useEffect(() => {
        setTitle( "ScholarSift - Manage Papers")
        return () => { setTitle("") }
    })

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    if(Debug())
        console.log(h.current)
    return  <ViewPort>
                <div style={{minHeight: h.current}}>
                    <ActiveUserSelector/>
                    <div style={{height: h.current}}>
                        <Papers 
                            name={state[location.pathname].current ? state[location.pathname].current.first + " " + state[location.pathname].current.last : undefined} 
                            account={state[location.pathname].current ? state[location.pathname].current.profile : undefined}
                            createUser={notify => { doCreateUser(user, saveUser, location, state, dispatch, notify, 0); }}
                            management={true}
                        />
                    </div>
                </div>
            </ViewPort>
}

export default ManagePapers;