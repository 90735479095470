const TextInputModal = ({
    id,
    type,
    label,
    name,
    placeholder,
    style,
    error,
    value,
    onChange }) => {

    
    return (
        <div className="input-column">
            <input
                id={id}
                className="modal-input"
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required
            />
            {error ?
                <label className="modal-input-label"
                    htmlFor={id}>
                    <span id="input-required">{error}</span>
                </label>
                :
                <label
                    className="modal-input-label"
                    htmlFor={id}
                    id="modal-input-label-dark"
                >
                    {label}{" "}
                    <span id="input-required">*</span>
                </label>
            }
        </div>
    )
}

export default TextInputModal;