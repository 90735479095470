import { Helmet } from "react-helmet-async";
import { checkRN } from 'ss-lib';

export const ItemSEO = (props) => {
    return  <Helmet>
                {
                    props.item ?
                    <>
                        <title>{props.item?.title}</title>
                        <meta name="description" content={props.text?.split("\n")[0]} />
                        <meta property="og:type" content={props.item?.type} />
                        <meta property="og:title" content={props.item?.title} />
                        <meta property="og:description" content={props.text?.split("\n")[0]} />
                        <meta name="twitter:creator" content={checkRN(props.item?.authors)} />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content={props.item?.title} />
                        <meta name="twitter:description" content={props.text?.split("\n")[0]} />
                    </> :
                    <>
                        <meta name="description" content="ScholarSift makes legal research more accessible, more equitable, and more complete. ScholarSift makes legal research better."/>
                        <meta property="og:title" content="ScholarSift" />
                        <meta property="og:site_name" content="ScholarSift" />
                        <meta property="og:url" content="https://scholarsift.com" />
                        <meta property="og:type" content="website" />
                        <meta property="og:description" content="ScholarSift makes legal research more accessible, more equitable, and more complete. ScholarSift makes legal research better."/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta property="twitter:domain" content="scholarsift.com"/>
                        <meta property="twitter:url" content="https://scholarsift.com"/>
                        <meta name="twitter:title" content="ScholarSift"/>
                        <meta name="twitter:description" content="ScholarSift makes legal research more accessible, more equitable, and more complete. ScholarSift makes legal research better."/>            
                    </>
                }
            </Helmet>
}