import useLocationState from "../../hooks/useLocationState";
import { Toast, useToastController } from "@fluentui/react-components";
import { ssToast } from "../../utils";

const MessageBarPanel = () => {
  const { state, dispatch } = useLocationState();
  const { dispatchToast } = useToastController(ssToast);

  if (!(state.error || state.warn || state.info)) return null;

  const sendMsg = (content, msgType) => {
    dispatchToast(<Toast appearance='inverted'>{content}</Toast>, { msgType });
    return dispatch({
      type: "message-update",
      key: msgType,
      payload: undefined,
    });
  };
  return (
    <div>
      {state.error && sendMsg(state.error, "error")}
      {state.warn && sendMsg(state.warn, "warn")}
      {state.info && sendMsg(state.info, "info")}
    </div>
  );
};

export default MessageBarPanel;
