import React from "react";
import { makeStyles, tokens, Tooltip } from "@fluentui/react-components";
import { DocumentText20Regular } from "@fluentui/react-icons";
import { v4 as uuid } from "uuid";

const nPages = 5;

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundInverted,
  },
});

const ResultPages = (props) => {
  const styles = useStyles();
  if (props.pages === undefined) return null;

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: 5,
        maxWidth: 360,
      }}
    >
      {props.pages.map((v, i) =>
        i < nPages ? (
          <Tooltip
            key={uuid()}
            content={{ children: `Pg. ${v.p + 1}`, className: styles.tooltip }}
          >
            <a
              href={props.url + "#page=" + (v.p + 1)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DocumentText20Regular />
            </a>
          </Tooltip>
        ) : null
      )}
    </div>
  );
};

export default ResultPages;
