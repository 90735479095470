import { useState } from 'react';
import { Icon, Panel, PanelType, PrimaryButton, Selection } from '@fluentui/react';
import TextFilterPanel from './TextFilterPanel';
import { v4 as uuid } from "uuid";
import { sentenceCase } from 'ss-lib';
import DraggableHeader from './DraggableHeader';

const FilterableHeader = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [filterSelection, setFilterSelection] = useState();
    const [filterMap, setFilterMap] = useState();
    const [pItems, setPItems] = useState();

    const { column, itemsRef } = props;

    const onFilter = e => {
            e.stopPropagation();

            let d = {};
            itemsRef.current.forEach(v => {
                //Fill Filter Set
                switch (column.key) {
                    case 'authors':
                        JSON.parse(v[column.key]).forEach(vv => {
                            if (d[vv.name] === undefined)
                                d[vv.name] = [];
                            d[vv.name].push(v.propsIndex)
                        });
                        break;
                    case 'citations':
                        let j = JSON.parse(v.stats).types;
                        if (j) {
                            Object.keys(j).sort().forEach(vv => {
                                if (d[vv] === undefined)
                                    d[vv] = [];
                                d[vv].push(v.propsIndex)
                            })
                        }
                        break;
                    case 'gender':
                        if (v.demographics && v.demographics)
                            v.demographics.forEach(vv => {
                                if (vv.genders) {
                                    if (d[vv.genders] === undefined)
                                        d[vv.genders] = [];
                                    d[vv.genders].push(v.propsIndex)
                                }
                            })
                        break;
                    case 'atitle':
                        if (v.demographics)
                            v.demographics.forEach(vv => {
                                if (d[vv.title] === undefined)
                                    d[vv.title] = [];
                                d[vv.title].push(v.propsIndex);
                            })
                        break;
                    case 'role':
                        if (v.demographics)
                            v.demographics.forEach(vv => {
                                if (d[vv.roles] === undefined)
                                    d[vv.roles] = [];
                                d[vv.roles].push(v.propsIndex);
                            })
                        break;
                    case 'ethnicity':
                        if (v.demographics && v.ethnicity)
                            v.ethnicity.split('\n').forEach(vv => {
                                if (vv.length) {
                                    if (d[vv] === undefined)
                                        d[vv] = [];
                                    d[vv].push(v.propsIndex)
                                }
                            })
                        break;
                    case 'status':
                        if (d[v.msg] === undefined)
                            d[v.msg] = [];
                        d[v.msg].push(v.propsIndex);
                        break;
                    case 'assignments':
                        v.tasks.forEach(vv => {
                            vv.props.children.forEach(x => {
                                let xx = x.props.children;
                                if (d[xx] === undefined)
                                    d[xx] = [];
                                d[xx].push(v.propsIndex);
                            })
                        })
                        break;
                    case 'orientation':
                        if (v.demographics) {
                            v.demographics.forEach(vv => {
                                if (vv.orientation) {
                                    if (d[vv.orientation] === undefined)
                                        d[vv.orientation] = [];
                                    d[vv.orientation].push(v.propsIndex)
                                }
                            });
                        }
                        break;
                    case 'mtype':
                        if (d[v.mtype] === undefined)
                            d[v.mtype] = [];
                        d[v.mtype].push(v.propsIndex);
                        break;
                    case 'tags':
                        if (v[column.key]) {
                            v[column.key].forEach(vv => {
                                if (d[vv] === undefined)
                                    d[vv] = [];
                                d[vv].push(v.propsIndex)
                            });
                        }
                        break;
                    case 'topics':
                        if (v.topics)
                            v.topics.split('\n').forEach(vv => {
                                if (vv.indexOf("("))
                                    vv = sentenceCase(vv.substring(0, vv.indexOf("(")).trim());
                                if (vv.length) {
                                    if (d[vv] === undefined)
                                        d[vv] = [];
                                    d[vv].push(v.propsIndex)
                                }
                            })
                        break;
                    default:
                        break;
                }
            });

            if (Object.keys(d).length === 0)
                return;

            props.filterKey.current = column.key;
            props.pRef.current = [...itemsRef.current];
            const fm = column.filter ? { ...column.filter.items } : d;
            const fs = new Selection();
            
            // add or remove refs
            let fv = column.filter ? Object.keys(column.filter.items).sort() : Object.keys(fm).sort();
            if (column && column.filter) {
                fs.setItems(fv);
                column.filter.selected.forEach(v => { fs.setIndexSelected(v, true) })
            }

            setPItems(fv.map(v => { return { [column.name]: v } }));
            setFilterSelection(fs);
            setFilterMap(fm);
            props.setFiltered(undefined);
            setIsOpen(true);
    }

    return  <>
                <DraggableHeader column={props.column} handleDrop={props.handleDrop} setDragStart={props.setDragStart} setDragStop={props.setDragStop}>
                    <div className={column.filter ? "filtered-column" : "filterable-column"} >
                        <Icon
                            iconName="Filter"
                            onClick={onFilter}
                        />
                        <div className="column-header-title">{column.name}</div>
                        <Icon
                            className="column-close"
                            iconName="Cancel"
                            onClick={e => { e.stopPropagation(); props.removeColumn(column.order); } }
                        />
                    </div>
                </DraggableHeader>
                <Panel
                    isLightDismiss
                    isOpen={isOpen}
                    onDismiss={() => { setIsOpen(false) }}
                    onDismissed={() => { props.processFilter(filterSelection, filterMap)}}
                    headerText={column.name + " Filter"}
                    type={PanelType.medium}
                    closeButtonAriaLabel="Close"
                >
                    <TextFilterPanel key={uuid()} items={pItems} selection={filterSelection} searchKey={column.name} setFiltered={props.setFiltered} />
                    <div className="filter-button-panel">
                        <PrimaryButton
                            className="filter-item"
                            text="Clear Filter"
                            onClick={() => {
                                setFilterMap(undefined);
                                if (filterSelection)
                                    props.processFilter(undefined, filterMap);
                            }}
                        />
                        <PrimaryButton
                            className="filter-item"
                            text="Apply"
                            onClick={() => {
                                setIsOpen(false);
                                if(filterMap)
                                    props.processFilter(filterSelection, filterMap);
                            }}
                        />
                    </div>
                </Panel>
            </>
}

export default FilterableHeader;