export const LaunchDropBox = () => {
    return new Promise<DropBoxResult | null>((resolve, reject) => {
        let ops:DropBoxOptions = {
            success: files => { resolve(files); },
            cancel: () => { resolve(null); },
            error: (e) => { reject(e); },
            linkType: "direct",
            multiselect: true,
            extensions: [".pdf", ".doc", ".docx"]
        };
        
        Dropbox.choose(ops);
    });
}

interface DropBoxResult {
    id?: string;
    name?: string;
    bytes?: number;
    icon?: string;
    thumbnailLink?: string;
    isDir?: boolean;
}

interface DropBoxOptions {
    success(res: any): void;
    cancel?(): void;
    error?(e: any): void;
    linkType?: string;
    multiselect?: boolean;
    extensions?: string[];
    folderselect?: boolean;
    sizeLimit?: number;
}

interface DropBoxI {
    choose(options: DropBoxOptions): any;
}

declare var Dropbox: DropBoxI;