import { useEffect } from 'react';
import Licenses from './Licenses';

const ThirdParty = () => {
    
    useEffect(() => {
        document.title = "ScholarSift - Third Party Software";
    });

    return  <div>
                <div>Google Drive is a trademark of Google Inc. Use of this trademark is subject to Google Permissions.</div>
                <Licenses/>
            </div>
}

export default ThirdParty;