const DraggableHeader = props => {
    return  <div 
                draggable={true}
                onDragStart={() => { props.setDragStart(props.column.name); }}
                onDragEnter={e => { 
                    e.preventDefault();
                    props.setDragStop(props.column.name); 
                }}
                onDragEnd={e => {
                    e.preventDefault();
                    let r = e.target.getBoundingClientRect();
                    props.handleDrop((r.left + r.right)/2 < e.clientX); }}
            >
                {props.children}
            </div>
}

export default DraggableHeader;