import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellActions,
  TableCellLayout,
} from "@fluentui/react-components";
import { bundleIcon, Edit24Filled, Edit24Regular } from "@fluentui/react-icons";
const EditIcon = bundleIcon(Edit24Filled, Edit24Regular);

const NodeList = (props) => {
  const { rows, columns, styles, openModal, offset } = props;

  const dataOverflow = (cellDataStr) => {
    if (cellDataStr.length > 22) {
      return cellDataStr.substring(0, 22) + "...";
    }
    return cellDataStr;
  };

  const getCellData = (cellData, column) => {
    if (cellData === undefined) return "";

    let cellDataStr = cellData[0];

    if (column.columnId === "short cites") {
      cellDataStr = cellDataStr.replace("?", "§");
      cellDataStr = cellDataStr.replace("https://", "");

      return dataOverflow(cellDataStr);
    }

    if (typeof cellData === "object") {
      return "";
    }

    console.log(cellData);
    return dataOverflow(cellData);
  };

  return (
    <Table sortable aria-label="Node List" size="small">
      <TableHeader>
        <TableRow>
          {columns.map((c) => (
            <TableHeaderCell id={c.columnId} key={c.columnId}>
              <TableCellLayout className={styles.tableHeader}>
                {c.name.toUpperCase()}
              </TableCellLayout>
            </TableHeaderCell>
          ))}
        </TableRow>{" "}
      </TableHeader>
      {rows.length > 0 && (
        <TableBody>
          {rows.map((n, index) => (
            <TableRow key={index}>
              {columns.map((c, index) => (
                <TableCell key={c.columnId}>
                  {index % 5 === 0 && (
                    <TableCellActions>
                      <Button
                        size="small"
                        appearance="subtle"
                        icon={<EditIcon />}
                        onClick={(e) => openModal(e, n)}
                      ></Button>
                    </TableCellActions>
                  )}
                  <TableCellLayout>
                    {getCellData(n[c.columnId], c)}
                  </TableCellLayout>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};

export default NodeList;

/*



class Nodes extends Component {
    componentDidMount() {
        const { nodes, fillNodes } = this.props;
        if(nodes.requested === undefined)
            fillNodes();
    }
    
    render() {
        const { nodes, dismissStatus } = this.props;
        let page = nodes !== undefined && nodes.page !== undefined ? parseInt(nodes.page) - 1 : 0;
        let offset = nodes !== undefined && 
                     nodes !== null && 
                     nodes.pageSize !== undefined ? 
                     page * nodes.pageSize :
                     0;

        var statusAlert = null;
        if(nodes !== null && nodes.error !== undefined && nodes.error !== null && nodes.error.length > 0) {
            statusAlert = (
                            <div className="alert alert-primary fade show" role="alert">
                                {nodes.error}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={dismissStatus}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        );
        }

        return  <DocumentTitle title="ScholarSift - Nodes">
                    <div className="display-panel">
                        {statusAlert}
                        <NodeSearchPanel/>
                        <EditableTable offset={offset}/>
                    </div>
                </DocumentTitle>
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    fillNodes: () => { 
        dispatch(FillNodes()); 
    },
    dismissStatus: () => {
        dispatch(updateNodeViaKey("error", ""));
    }
});

export default (connect)(mapStateToProps, mapDispatchToProps)(Nodes);

*/
