import { Button, Input } from "@fluentui/react-components";

const NodeSearchPanel = (props) => {
  return (
    <div className="node-search-panel">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Input
          style={{ width: "80%" }}
          placeholder="Node list"
          onChange={props.setNodeList}
        />
        <Button style={{ width: "10%" }} onClick={props.onSearch}>
          Search
        </Button>
      </div>
      <div style={{ height: 10 }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Input
          style={{ width: "80%" }}
          placeholder="Node Search - Citation, Title, Author"
          onChange={props.setSearchText}
        />
        <Button style={{ width: "10%" }} onClick={props.onSearch}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default NodeSearchPanel;
