import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import useLocationState from '../../../hooks/useLocationState';
import ActiveUserSelector from '../ActiveUserSelector';
import Submit from '../../Submit';
import ViewPort from '../../Layout/ViewPort';

const ManageSubmit = props => {
    const location = useLocation();
    const { state } = useLocationState();
    const { setTitle } = useUser();
    const h = useRef();

    useEffect(() => {
        document.title = "ScholarSift - Manage Submit"
        setTitle("Manage Submit")
        return () => { setTitle("") }
    });
    
    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    return  <ViewPort>
                <div style={{minHeight: h.current}}>
                    <ActiveUserSelector/>
                    {
                        state[location.pathname].current && 
                        <Submit 
                            name={state[location.pathname].current ? state[location.pathname].current.first + " " + state[location.pathname].current.last : undefined} 
                            account={state[location.pathname].current ? state[location.pathname].current.profile : undefined} 
                            email={state[location.pathname].current ? state[location.pathname].current.email : undefined} 
                            unwatched={true}
                        />
                    }
                </div>
            </ViewPort>
}

export default ManageSubmit;