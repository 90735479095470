import { APIErrorHandler } from '../../../../actions/APIErrorHandler';
import axios from 'axios';

export const ListJournals = (user, saveUser, setJournals, setFetching) => {
    axios.get("/api/lslr", {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
    })
    .then(res => {
        setJournals(res.data);
        setFetching(false);
    })
    .catch(err => {
        APIErrorHandler(err, user, saveUser, (v) => v ? setFetching(false) : ListJournals(user, saveUser, setJournals, setFetching));
    })
}

export const JournalUpdate = (user, saveUser, journal, callBack) => {
    axios.post("/api/uj", journal, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
    })
    .then(() => {
        if(callBack)
            callBack();
    })
    .catch(err => {
        APIErrorHandler(err, user, saveUser, (v) => v ? undefined : ListJournals(user, saveUser, journal, callBack));
    })
}

export const JournalDelete = (user, saveUser, journal, callBack) => {
    axios.post("/api/rmj", {id:journal}, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
    })
    .then(() => {
        if(callBack)
            callBack();
    })
    .catch(err => {
        APIErrorHandler(err, user, saveUser, (v) => v ? undefined : ListJournals(user, saveUser, journal, callBack));
    })
}