import { useState } from 'react';
import { Icon, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import DraggableHeader from './DraggableHeader';
import RangeableColumn from './RangeableColumn';
import { getItemRange } from './SubmissionPortalPanel';

const RangeableHeader = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState();
    const [selectedRange,  setSelectedRange] = useState();
    const { column, cRef, itemsRef, filterKey } = props;

    return  <>
                <DraggableHeader column={props.column} handleDrop={props.handleDrop} setDragStart={props.setDragStart} setDragStop={props.setDragStop}>
                    <div className={column.filter ? "filtered-column" : "filterable-column"}>
                        <Icon
                            iconName="Filter"
                            onClick={e => {
                                e.stopPropagation();

                                if (itemsRef.current === undefined || itemsRef.current.length === 0)
                                    return;

                                //Set Range                        
                                filterKey.current = column.key;
                                
                                //Check Ref
                                if (!cRef.current)
                                    return;

                                let col = undefined;
                                cRef.current.forEach(v => {
                                    if (v.key === filterKey.current)
                                        col = v;
                                })

                                setRange(getItemRange(props.itemsRef.current, col));
                                setIsOpen(true);
                            }}
                        />
                        <div className="column-header-title">{column.name}</div>
                        <Icon
                            className="column-close"
                            iconName="Cancel"
                            onClick={e => {
                                e.stopPropagation();
                                props.removeColumn(column.order)
                            }}
                        />
                    </div>
                </DraggableHeader>
                <Panel
                    isLightDismiss
                    isOpen={isOpen}
                    onDismiss={() => { setIsOpen(false) }}
                    onDismissed={() => {props.processRange(selectedRange, range)}}
                    headerText={column.name + " Filter"}
                    type={PanelType.medium}
                    closeButtonAriaLabel="Close"
                >
                    <RangeableColumn {...props} column={column} itemRange={range} selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
                    <div className="filter-button-panel">
                        <PrimaryButton
                            className="filter-item"
                            text="Clear Filter"
                            onClick={() => {setSelectedRange(undefined); props.processRange();}}
                        />
                        <PrimaryButton
                            className="filter-item"
                            text="Apply"
                            onClick={() => {
                                props.processRange(selectedRange, range);
                                setIsOpen(false);
                            }}
                        />
                    </div>
                </Panel>
            </>
}

export default RangeableHeader;