/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";

import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";

import { planObjects } from "./Plans";
import PlanDetails from "./PlanDetails";
import ViewPort from "../Layout/ViewPort";
import useAlert from "../../hooks/useAlert";
import useModal from "../../hooks/useModal";
import ConfirmDetails from "./ConfirmDetails";
//import Options from "./Options";
import { Icon } from "@fluentui/react";
import { Link } from "react-router-dom";

const Payment = (props) => {
  const { user, setTitle } = useUser();
  const { state } = useLocationState();
  const { showAlert, hideAlert } = useAlert();
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  useEffect(() => {
    document.title = "ScholarSift - Subscribe";
    setTitle("Subscription Options");
    return () => {
      setTitle("");
    };
  });

  const handleNav = () => {
    hideAlert();
    navigate("/signin/create");
  };

  const handleClick = (plan) => {
    user.current.scholarsift.profile
      ? showModal(
          <ConfirmDetails
            plan={plan}
            showModal={showModal}
            showAlert={showAlert}
            hideAlert={hideAlert}
            hideModal={hideModal}
            handleNav={handleNav}
          />,
          "modal-card-dark"
        )
      : showAlert(
          <>
            <div className="alert-body">
              Before subscribing, you must sign up for an account. Click 'Ok'
              and you will be redirected.
            </div>
            <div className="alert-footer">
              <div
                className="alert-button"
                id="dismiss-alert"
                onClick={() => hideAlert()}
              >
                Go back
              </div>
              <div className="alert-button" onClick={handleNav}>
                Ok
              </div>
            </div>
          </>
        );
  };

  return (
    <ViewPort>
      <div className="subscription-container" style={{ minHeight: h.current }}>
        <div className="subscription-content">
          {user.current &&
          user.current.scholarsift &&
          user.current.scholarsift.expiration > new Date().getTime() &&
          user.current.scholarsift.authnetProfile &&
          user.current.scholarsift.authnetProfile.description &&
          user.current.scholarsift.authnetProfile.description !== "empty" ? (
            <>
              <div className="display-name">Active Plan</div>

              <PlanDetails
                planId={user.current.scholarsift.authnetProfile.description}
              />
            </>
          ) : (
            <>
              <div className="display-name">Individual Plans</div>
              {/* <Options handleClick={handleClick} handleNav={handleNav} /> */}
              <div
                className="payment-card-row"
                style={{
                  height: "fit-content",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="basic-card subscription-card"
                  style={{
                    width: 400,
                    maxWidth: "100%",
                  }}
                >
                  <div
                    className="payment-detail-title"
                    style={{ color: "var(--berry)" }}
                  >
                    FREE in Browser!
                  </div>
                  <div
                    className="subscription-text"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                      margin: "auto",
                      width: "fit-content",
                    }}
                  >
                    <div
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <Icon iconName="SkypeCheck" className="list-item-icon" />{" "}
                      Browse Faculty Bios
                    </div>
                    <div
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <Icon iconName="SkypeCheck" className="list-item-icon" />{" "}
                      Upload Papers
                    </div>
                    <div>
                      <Icon iconName="SkypeCheck" className="list-item-icon" />{" "}
                      Free Submissions
                    </div>
                    <div>
                      <Icon iconName="SkypeCheck" className="list-item-icon" />{" "}
                      HTML-to-Text Analysis
                    </div>
                    <div>
                      <Icon iconName="SkypeCheck" className="list-item-icon" />{" "}
                      Document Analysis
                    </div>
                  </div>
                  <div
                    className="payment-btn"
                    onClick={handleNav}
                    style={{
                      width: 200,
                      maxWidth: "100%",
                      marginTop: 60,
                    }}
                  >
                    <div>Sign Up</div>{" "}
                    <Icon iconName="AddFriend" id="btn-icon" />
                  </div>
                </div>
                <div
                  className="basic-card subscription-card payment-copy"
                  style={{
                    width: "700px",
                    maxWidth: "100vw",
                    textAlign: "left",
                    fontSize: 22,
                    lineHeight: 1.2,
                    margin: "auto",
                    minHeight: "fit-content",
                  }}
                >
                  <p>
                    Thank you for trying ScholarSift! As of Feb. 2024, we have
                    decided to make our website Analysis feature free to all
                    users, so that everyone can experience the value that
                    ScholarSift provides.
                  </p>
                  <p>
                    <span>
                      We are launching exciting new products in the near future
                      which we believe will radically improve the way you
                      conduct your research.
                    </span>
                  </p>
                  <p>
                    We will be offering these products at an affordable price,
                    and we will provide updated pricing information for those
                    products as soon as they are available. Keep an eye out for
                    our release emails and keep checking the website for more
                    details!
                  </p>
                </div>
              </div>
            </>
          )}
          <>
            <div className="display-name">Institutional Plans</div>

            <div
              className="basic-card subscription-card"
              style={{
                height: "fit-content",
                lineHeight: 1.2,
                fontWeight: 300,
              }}
            >
              <div className="subscription-text">
                Please contact{" "}
                <a href="mailto:inquiries@scholarsift.com">
                  inquiries@scholarsift.com
                </a>{" "}
                to learn more about institutinal plans or to arrange a payment.
                You can also <Link to="/demo">schedule a demo</Link>.
              </div>
            </div>

            <div className="display-name">Free Law Review Editor Accounts</div>

            <div
              className="basic-card subscription-card"
              style={{
                height: "fit-content",
                lineHeight: 1.2,
                fontWeight: 300,
              }}
            >
              <div className="subscription-text">
                Please contact{" "}
                <a href="mailto:inquiries@scholarsift.com">
                  inquiries@scholarsift.com
                </a>{" "}
                to set up a free Law Review Editor account.
              </div>
            </div>
          </>
        </div>
      </div>
    </ViewPort>
  );
};

export default Payment;
