import { createContext, useState } from "react";
import Modal from "../components/Layout/Modal";
import { useSpring, a, config } from "react-spring";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const [type, setType] = useState("");
  const spring = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? `translateY(0)` : `translateY(-10%)`,
    config: config.gentle,
  });

  const showModal = (content, type) => {
    setContent(content);
    setType(type);
    setShow(true);

    console.log(type);
  };

  const hideModal = () => {
    setContent("");
    setShow(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <a.div>
        <Modal
          style={spring}
          Modal={Modal}
          type={type}
          show={show}
          handleClose={hideModal}
        >
          {content}
        </Modal>
      </a.div>
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export default ModalContext;
