/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";
import { useWindowDimensions } from "ss-lib";
import { FillNodes, nodeQuery } from "../../actions/Nodes";
import NodeList from "./NodeList";
import NodeSearchPanel from "./NodeSearchPanel";
import { delay } from "../../utils";
import axios from "axios";
import ViewPort from "../Layout/ViewPort";
import useModal from "../../hooks/useModal";
import {
  Button,
  Card,
  Field,
  Label,
  Input,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
  TableRow,
  Text,
  makeStyles,
  shorthands,
  useTableFeatures,
  useTableSelection,
} from "@fluentui/react-components";
import {
  bundleIcon,
  DatabaseSearch24Regular,
  Search24Regular,
  Edit24Regular,
  Edit24Filled,
} from "@fluentui/react-icons";
import Form from "./Form";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-start",
  },
  button: {
    display: "flex",
    alignSelf: "center",
    backgroundColor: "var(--violet)",
    color: "white",
    width: "75px",
    ...shorthands.margin("20px", "auto", "0", "auto"),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    ...shorthands.padding("20px", "40px", "20px", "40px"),
    ...shorthands.margin("40px", "auto", "20px", "auto"),
  },
  icon: {
    color: "var(--dark-blue)",
  },
  edit: {
    color: "var(--dark-blue)",
    "&:hover": {
      color: "white",
      backgroundColor: "var(--violet)",
    },
  },
  input: {
    width: "75px",
    height: "30px",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    width: "fit-content",
    ...shorthands.margin("0", "auto", "20px", "auto"),
  },
  tableHeader: {
    fontWeight: 600,
    color: "var(--dark-blue)",
  },
  row: {
    height: 50,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "fit-content",
    ...shorthands.margin("0", "auto", "20px", "auto"),
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "95%",
    justifyContent: "space-between",
    ...shorthands.margin("0", "auto", "20px", "auto"),
  },
});

const Nodes = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [load, setLoad] = useState(0);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [offsetPages, setOffsetPages] = useState([0, 5]);

  const { showModal } = useModal();

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    document.title = "ScholarSift - Nodes";
    if (!state[location.pathname].nodes && load === 0) {
      Fill();
      setLoad(1);
    }
  }, [load, location]);

  const styles = useStyles();

  const Fill = () => {
    let cancel = handleCancel();
    FillNodes(cancel, user, saveUser, setNodes, fatalError);
  };

  const handleCancel = () => {
    if (state[location.pathname].cancel)
      state[location.pathname].cancel.cancel();

    const CancelToken = axios.CancelToken;
    let cancel = CancelToken.source();
    setState("cancel", cancel);
    return cancel;
  };

  const setState = (key, value) => {
    dispatch({
      type: "path-key-update",
      path: location.pathname,
      key: key,
      payload: value,
    });
  };

  const openModal = (e, v) => {
    e.preventDefault();

    showModal(
      <Form
        node={v}
        styles={styles}
        user={user}
        saveUser={saveUser}
        fatalError={fatalError}
        callBack={setNodes}
      />,
      "modal-card-light"
    );
  };

  const fatalError = (e, callBack) => {
    if (e === "login") {
      dispatch({
        type: "message-update",
        key: "info",
        payload: "Please login again.",
      });
      navigate("/signin", { goback: true, callBack: callBack });
    } else if (e === "server down") {
      let ct = 0;
      while (ct < 30)
        // eslint-disable-next-line no-loop-func
        delay(1000).then(() => {
          ct++;
          dispatch({
            type: "message-update",
            key: "error",
            payload: "Server is down.  Retrying in..." + (30 - ct),
          });
        });
      callBack();
    }
  };

  const getColumns = (rows) => {
    let k = [];

    for (let i = 0; i < rows.length; i++) {
      let keys = rows[i];
      keys = Object.keys(rows[i]).filter((item) => typeof item !== "object");

      for (let j = 0; j < keys.length; j++) {
        if (k.findIndex((item) => item.columnId === keys[j]) === -1) {
          k.push({ name: keys[j], columnId: keys[j] });
        }
      }

      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "jurisdiction"),
          1
        )[0]
      );
      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "type"),
          1
        )[0]
      );
      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "section"),
          1
        )[0]
      );
      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "code"),
          1
        )[0]
      );
      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "title"),
          1
        )[0]
      );
      k.unshift(
        k.splice(
          k.findIndex((item) => item.columnId === "short cites"),
          1
        )[0]
      );
      setColumns(k.slice(offsetPages[0], offsetPages[1]));
    }

    return columns;
  };

  const setNodes = (v) => {
    setState("nodes", v);
    const { nodes } = v;

    getColumns(nodes);
    setRows(nodes.filter((item) => item.type !== "Code"));

    if (state[location.pathname].nodes === undefined) return [];
  };

  const setNodeList = (e) => {
    let v = e.target.value;

    setState("nodeList", v);

    if (
      state[location.pathname]["searchText"] === undefined &&
      (v === undefined || v.length === 0)
    )
      return [];
    Fill();
  };

  const setSearchText = (e) => {
    let v = e.target.value;
    setState("searchText", v);

    if (
      state[location.pathname]["nodeList"] === undefined &&
      (v === undefined || v.length === 0)
    )
      return [];
    //Fill();
    console.log("search text");
  };

  const onSearch = (v) => {
    let cancel = handleCancel();
    nodeQuery(
      cancel,
      user,
      saveUser,
      state[location.pathname]["nodeList"],
      state[location.pathname]["searchText"],
      setNodes,
      fatalError
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("save");
  };

  return (
    <ViewPort>
      <div style={{ height: height }} className={styles.main}>
        <div style={{ height: "fit-content", margin: "auto" }}>
          <NodeSearchPanel
            searchText={state[location.pathname]["searchText"]}
            nodeList={state[location.pathname]["nodeList"]}
            onSearch={onSearch}
            setSearchText={setSearchText}
            setNodeList={setNodeList}
          />
          <Card className={styles.card}>
            {/* <Table sortable aria-label="User List" size="small">
              <TableHeader>
                <TableRow>
                  {columns.map((c) => (
                    <TableHeaderCell id={c.columnId} key={c.columnId}>
                      {c.name.toUpperCase()}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              {nods?.length > 0 && (
                <TableBody>
                  {nods.map((n, index) => (
                    <TableRow key={index}>
                      {columns.map((c) => (
                        <>
                          <TableCell key={c.columnId}>
                            {c.columnId === "short cites" && (
                              <TableCellActions>
                                <Button
                                  size="small"
                                  appearance="subtle"
                                  icon={<EditIcon />}
                                  onClick={(e) => openModal(e, n)}
                                ></Button>
                              </TableCellActions>
                            )}
                            <TableCellLayout>
                              <Text>
                                {n[c.columnId] === undefined
                                  ? ""
                                  : c.columnId === "short cites"
                                  ? n[c.columnId][0].replace("?", "§")
                                  : c.columnId === "general" ||
                                    c.columnId === "pages cited"
                                  ? n[c.columnId]["pages cited"]
                                  : n[c.columnId]}
                              </Text>
                            </TableCellLayout>
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table> */}
            <NodeList
              rows={rows}
              columns={columns}
              styles={styles}
              width={width}
              openModal={openModal}
              offset={offsetPages[0]}
            />
            {/*             <Button
              onClick={(e) => {
                e.preventDefault();
                offsetPages[0] < 1
                  ? setOffsetPages([0, 6])
                  : setOffsetPages(offsetPages.map((item) => 6 - item));
                getColumns(rows);
              }}
            >
              back
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                offsetPages[1] > 22
                  ? setOffsetPages([0, 5])
                  : setOffsetPages(offsetPages.map((item) => item + 5));
                getColumns(rows);
              }}
            >
              next
            </Button> */}
          </Card>
        </div>
      </div>
    </ViewPort>
  );
};

export default Nodes;
