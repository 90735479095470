import "./Buttons.scss";

const RectButton = ({ title, onClick, icon, id }) => {
  return (
    <button className="btn rect-btn" id={id} onClick={onClick}>
      {title} {icon}
    </button>
  );
};

export default RectButton;
