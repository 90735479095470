import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toggle } from "@fluentui/react";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import ActiveUserSelector from "../ActiveUserSelector";
import Profile from "../../Profile";
import { doCreateUser } from "../../../actions/Management";
import ViewPort from "../../Layout/ViewPort";

const ManageUsers = (props) => {
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const { user, saveUser, setTitle } = useUser();
  const { state, dispatch } = useLocationState();
  const h = useRef();

  useEffect(() => {
    document.title = "ScholarSift - Manage Users";
    setTitle("Manage Users");
    return () => {
      setTitle("");
    };
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const ProfileForm = () => {
    return (
      state[location.pathname].list && (
        <>
          <div className="profile-edit-toggle">
            <Toggle
              label="Profile Edit Mode"
              checked={editMode}
              onText="On"
              offText="Off"
              onChange={(ev, checked) => {
                setEditMode(checked);
              }}
              inlineLabel
            />
          </div>

          <div className={!editMode ? "read-only-panel" : "editable-panel"}>
            <Profile
              name={
                state[location.pathname].current
                  ? state[location.pathname].current.first +
                    " " +
                    state[location.pathname].current.last
                  : undefined
              }
              account={
                state[location.pathname].current
                  ? state[location.pathname].current.profile
                  : undefined
              }
              createUser={(notify) => {
                doCreateUser(
                  user,
                  saveUser,
                  location,
                  state,
                  dispatch,
                  notify,
                  0
                );
              }}
              readOnly={!editMode}
            />
          </div>
        </>
      )
    );
  };

  console.log("MU")
  return (
    <ViewPort>
      <div
        className="vertical-center"
        style={{ minHeight: h.current + state.viewport.o }}
      >
        <ActiveUserSelector options={<ProfileForm />} form={<ProfileForm />} />
      </div>
    </ViewPort>
  );
};

export default ManageUsers;
