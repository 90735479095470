import { Icon } from '@fluentui/react';
import DraggableHeader from './DraggableHeader';

const CloseableHeader = props => {
    return  <DraggableHeader column={props.column} handleDrop={props.handleDrop} setDragStart={props.setDragStart} setDragStop={props.setDragStop}>
                <div className="closeable-column">
                    <div className="column-header-title">{props.column.name}</div>
                    <Icon
                        iconName="Cancel"
                        onClick={e => {
                            e.stopPropagation();
                            props.removeColumn(props.column.order)
                        }
                        }
                    />
                </div>
            </DraggableHeader>
}

export default CloseableHeader;