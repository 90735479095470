import React, { useRef, useEffect, useState } from "react";
import ViewPort from "../../Layout/ViewPort";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { useAcceptJs } from "react-acceptjs";
import useLocationState from "../../../hooks/useLocationState";
import "../Dashboard.scss";
import SystemCard from "./SystemCard";
import {
  getMerchantDetailsRequest,
  validatePaymentProfile,
  validateSubscriptions,
} from "../../../actions/AuthNet";
import { CRMTestGET, CRMTestUPDATE } from "../../../actions/CRM";
import { useWindowDimensions } from "ss-lib";
//import useToaster from "../../../hooks/useToaster";
import useUser from "../../../hooks/useUser";
import Tabs from "./Tabs";
import Log from "./Log";
import creds from "../../../creds.json";
import { useSpring, a, easings } from "react-spring";
import { Switch, Text } from "@fluentui/react-components";
import { Debug } from "ss-lib";
import { searchFacultyTest } from "../../../actions/Faculty";
import { v4 as uuid } from "uuid";
import {
  ProcessCV,
  RemovePaper,
  testUploadPaper,
} from "../../../actions/Papers";
import {
  testCreateLogin,
  testProcessToken,
  testUpdateLogin,
  testVerifyEmail,
} from "../../../actions/Signin";
import { GetUserAccount } from "../../../actions/Dashboard";
import { aproxyraisin, testProcessDocument } from "../../../actions/Analyze";
import { UpdateProfile } from "../../../actions/Profiles";

const useStyles = makeStyles({
  main: {
    ...shorthands.gap("16px"),
    ...shorthands.margin("5vh", "auto"),
    ...shorthands.padding("25px"),
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "90vw",
    height: "100%",
    justifyContent: "center",
  },
  switch: {
    ...shorthands.margin("10vh", "0", "0", "11vw"),
  },
  logView: {
    ...shorthands.gap("16px"),
    ...shorthands.margin("auto", "auto", "auto", "0"),
    ...shorthands.padding("25px"),
    display: "flex",
    flexDirection: "column",
    width: "40vw",
    justifyContent: "center",
  },
});

const SiteManagement = () => {
  const [viewAll, setViewAll] = useState(false);
  const [logView, setLogView] = useState(false);
  const [messages, setMessages] = useState([]);
  const { state } = useLocationState();
  const h = useRef();
  const styles = useStyles();
  const { setTitle, user, saveUser } = useUser();
  const { width } = useWindowDimensions();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([
    //Analyze
    [
      {
        name: "Proxy Server",
        endpoint: "external",
        status: loading ? "loading" : "---",
        req: () => aproxyraisin(handleReq, user),
      },
      {
        name: "Process URL",
        endpoint: "/showcase",
        status: "---",
        req: () => console.log("Process url"),
      },
      {
        name: "Process Document",
        endpoint: "/file",
        status: "---",
        req: () => testProcessDocument(user, text, handleReq),
      },
      {
        name: "FTS",
        endpoint: "/fts",
        status: "---",
        req: () => console.log("FTS"),
      },
      {
        name: "Search",
        endpoint: "/cite",
        status: "---",
        req: () => console.log("Search"),
      },
    ],
    //AuthNet
    [
      {
        name: "API Keys",
        endpoint: "AuthNet",
        status: "---",
        req: () => getMerchantDetailsRequest(1, 0, updateMerchantResponse),
      },
      {
        name: "AcceptJS",
        endpoint: "AuthNet",
        status: "---",
        req: () => handleAcceptJs(1, 1, "AcceptJS Transactions"),
      },
      {
        name: "Payment Profiles",
        endpoint: "AuthNet",
        status: "---",
        req: () => validatePaymentProfile(1, 2, handleANReq),
      },
      {
        name: "Subscriptions",
        endpoint: "AuthNet",
        status: "---",
        req: () => validateSubscriptions(1, 3, handleANReq),
      },
    ],
    //CRM
    [
      {
        name: "Get CRM Items",
        endpoint: "/crm",
        status: "---",
        req: () => CRMTestGET(user, handleReq, 2, 0, "Get CRM Items"),
      },
      {
        name: "Edit CRM Item",
        endpoint: "/crm",
        status: "---",
        req: () => CRMTestUPDATE(user, handleReq, 2, 1, "Edit CRM Items"),
      },
    ],
    //Faculty Bios
    [
      {
        name: "Search Faculty Bios",
        endpoint: "/fs",
        status: "---",
        req: () => searchFacultyTest(3, 0, handleReq, "Search Faculty Bios"),
      },
    ],
    //Papers
    [
      {
        name: "Process CV",
        endpoint: "/cv",
        status: "---",
        req: () =>
          ProcessCV(
            "https://puppyspeed.com/api/cvs/S2F0LVllYXJ5LUNWLTIwMjMtMDUtMjIuZG9jeA%3D%3D",
            478,
            user,
            saveUser,
            handleReq,
            () => {},
            () => {},
            4,
            0
          ),
      },
      {
        name: "Upload/Edit Paper",
        endpoint: "/nupdate",
        status: "---",
        req: () => UploadFiles(),
      },
      {
        name: "Remove Paper",
        endpoint: "/nremove",
        status: "---",
        req: () => RemovePaper(user, saveUser, {}),
      },
    ],
    //Profiles
    [
      {
        name: "Fetch Profile",
        endpoint: "/profile",
        status: "---",
        req: () =>
          GetUserAccount(
            user.current.scholarsift.profile,
            user,
            saveUser,
            handleReq,
            () => {},
            5,
            0,
            "Fetch Profile"
          ),
      },
      {
        name: "Edit Profile",
        endpoint: "/uprofile",
        status: "---",
        req: () =>
          UpdateProfile(
            user,
            saveUser,
            user.current.scholarsift.profile,
            user.current.scholarsift.profile,
            handleReq,
            5,
            1
          ),
      },
      /*       {
        name: "Delete Profile",
        endpoint: "/cancel",
        status: "---",
        req: () => testDeleteProfile(user, saveUser, handleReq, 5, 2),
      }, */
    ],
    //User Management
    [
      {
        name: "Verify Accounts",
        endpoint: "/verify",
        status: "---",
        req: () => testVerifyEmail(handleReq),
      },
      {
        name: "Reset Password",
        endpoint: "/pwreset",
        status: "---",
        req: () => testUpdateLogin("test@testmail.com", handleReq),
      },
      {
        name: "Create Login",
        endpoint: "/create_local",
        status: "---",
        req: () => testCreateLogin(handleReq),
      },
      {
        name: "Process Token",
        endpoint: "/token_local",
        status: "---",
        req: () => testProcessToken(user, handleReq),
      },
    ],
  ]);

  const handleANReq = (p, row, result, name) => {
    setItems((items) => {
      let newItems = [...items];
      newItems[p][row].status = result.messages
        ? result.messages.resultCode
        : result.message;
      //newItems[p][row].status = result.message;

      let newMessages = messages;
      newMessages.push(
        <>
          {" "}
          Testing <span id="text-label">{name}</span>. Result status{" "}
          <span
            id={
              result.messages.resultCode === "Ok"
                ? "text-success"
                : "text-error"
            }
          >
            {result.messages.resultCode}: {result.messages.message[0].code}.{" "}
            {result.messages.message[0].text}
          </span>
        </>
      );
      setMessages(newMessages);

      return newItems;
    });
  };

  const handleReq = (p, row, result, name) => {
    setItems((items) => {
      let newItems = [...items];

      newItems[p][row].status = result?.status;
      console.log(result);
      console.log(p, row, result);

      let newMessages = messages;
      newMessages.push(
        <>
          {" "}
          Testing <span id="text-label">{name}</span>. Result status{" "}
          <span
            id={
              result.data.error && result.status === 200
                ? "text-warning"
                : result.status === 200 || result.status === "Ok"
                ? "text-success"
                : name === "Create Login" &&
                  result.data === "Account already exists"
                ? "text-warning"
                : result.status === 200 || result.status === "Ok"
                ? "text-success"
                : warnings.includes(result.status)
                ? "text-warning"
                : errors.includes(result.status)
                ? "text-error"
                : "text-default"
            }
          >
            {result.status}: {result.statusText}
            {/* {result.data.error ? `. ${result.data.error}.` : "."} */}
            {name === "Create Login" &&
              ` ${result.data}. If account already exists, this is not a fatal error.`}
            {name === "Process Document" &&
              result.data.error === "invalid file type" &&
              " This is not a fatal error."}
          </span>
        </>
      );
      setMessages(newMessages);
      return newItems;
    });
  };

  const updateMerchantResponse = (p, row, result) => {
    setItems((items) => {
      let newItems = [...items];
      newItems[p][row].status = result.messages.resultCode;

      let newMessages = messages;

      newMessages.push(
        <>
          Testing merchant <span id="text-label">AuthNet API keys. </span>
          Result status{" "}
          <span
            id={
              result.messages.message[0].code === "E00007"
                ? "text-error"
                : "text-success"
            }
          >
            {result.messages.resultCode}: {result.messages.message[0].code}.{" "}
            {result.messages.message[0].text}
          </span>{" "}
          {result.messages.resultCode !== "Error" && "Production Mode: "}
          {result.isTestMode ? "Testing Only" : "Live"}
        </>
      );

      if (result.messages.resultCode !== "Ok") {
        newMessages.push(
          <>
            {" "}
            <span id="text-error">
              AuthNet Error {result.messages.message[0].code}
            </span>
            : {result.messages.message[0].text} Check your API and transaction
            keys.
          </>
        );
      }
      setMessages(newMessages);

      return newItems;
    });
  };

  const UploadFiles = () => {
    ["", "", ""].forEach((f) => {
      let d = {
        authors: user.current.scholarsift.name,
        type: "Journal",
        "file name": "Test.docx",
        profile: user.current.scholarsift.profile,
        hidden: true,
        "status id": uuid(),
      };
      testUploadPaper(user, saveUser, f, { paper: d }, () => {}, handleReq);
    });
  };

  const spring = useSpring({
    reverse: !logView,
    display: logView ? "block" : "none",
    width: logView ? width * 0.6 : 0,
    config: { easing: easings.easeInCirc },
  });

  const handleLogView = () => {
    setLogView((checked) => !checked);
  };

  const handleViewAll = () => {
    setViewAll((checked) => !checked);
  };

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  useEffect(() => {
    document.title = "ScholarSift - System Health";
    setTitle("System Health");
    return () => {
      setTitle("");
    };
  });

  useEffect(() => {
    let itms = items;
    console.log(itms);
    //console.log(itms[0][0].name);
    itms.map(
      (item, index) =>
        index !== 1 &&
        item.map((i) => (i.req ? i.req() !== undefined && i.req() : null))
    );
  }, []);

  const authData = {
    apiLoginID: creds.authorize.apiLogin,
    clientKey: creds.authorize.publicKey,
  };

  const environment = "PRODUCTION";
  const { dispatchData } = useAcceptJs({ environment, authData });

  const errors = [
    "E00003",
    "E_WC_01",
    "E_WC_03",
    "E_WC_08",
    "E_WC_10",
    "E_WC_13",
    "E_WC_14",
    "E_WC_18",
    "E_WC_19",
    "E_WC_21",
    500,
    502,
    503,
    504,
    501,
    400,
    401,
    403,
    404,
    429,
  ];

  const warnings = [
    "E_WC_02",
    "E_WC_04",
    "E_WC_05",
    "E_WC_06",
    "E_WC_07",
    "E_WC_15",
    "E_WC_16",
    "E_WC_17",
  ];

  const handleAcceptJs = (p, row, name) => {
    if(Debug()) console.log("Sending request...");
    dispatchData({
      cardNumber: "",
      cardCode: "",
      month: "",
      year: "",
      amount: 0,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setItems((items) => {
          let newItems = [...items];
          newItems[p][row].status = err.messages.message[0].code;

          let newMessages = messages;
          newMessages.push(
            <>
              Testing <span id="text-label">{name}</span>. Result status{" "}
              <span
                id={
                  warnings.includes(
                    err.messages.message[0].code ||
                      err.response.data === "Account already exists"
                  )
                    ? "text-warning"
                    : errors.includes(err.messages.message[0].code)
                    ? "text-error"
                    : "text-success"
                }
              >
                {err.messages.message[0].code}: {err.messages.message[0].text}
              </span>
            </>
          );

          if (errors.includes(err.messages.message[0].code)) {
            const msg = (
              <>
                AcceptJS code{" "}
                <span id="text-error">{err.messages.message[0].code}</span>is a
                fatal error.
              </>
            );
            //showToast(msg, "error");
            newMessages.push(msg);
          } else {
            newMessages.push(
              <>
                AcceptJS code {err.messages.message[0].code} is not a fatal
                error.{" "}
                <span id="text-success">AcceptJS connection successful.</span>
              </>
            );
          }
          return newItems;
        });
      });
  };

  const pages = [
    "Analyze",
    "AuthNet",
    "CRM",
    "Faculty Bios",
    "Papers",
    "Profiles",
    "User Management",
  ];

  return (
    <ViewPort>
      <div
        style={{
          minHeight: h.current,
          width: width,
        }}
      >
        <div className={styles.switch}>
          {width > 750 && (
            <Switch
              disabled={logView}
              label={
                <Text
                  size="small"
                  style={{
                    color: viewAll ? "var(--violet)" : "#000",
                    fontWeight: viewAll ? 600 : 400,
                  }}
                >
                  View All
                </Text>
              }
              checked={viewAll}
              onChange={handleViewAll}
            />
          )}
          <Switch
            label={
              <Text
                size="small"
                style={{
                  color: logView ? "var(--violet)" : "#000",
                  fontWeight: logView ? 600 : 400,
                }}
              >
                Log View
              </Text>
            }
            checked={logView}
            onChange={handleLogView}
          />
        </div>
        <main
          className={!logView ? styles.main : styles.logView}
          style={{ overflow: "auto" }}
        >
          {!viewAll && width > 750 && !logView ? (
            <Tabs pages={pages} items={items} />
          ) : (
            pages.map((p, index) => (
              <SystemCard
                key={index}
                items={items[index]}
                viewAll={viewAll}
                logView={logView}
                title={p}
              />
            ))
          )}
          <Log
            style={spring}
            a={a}
            setLogView={setLogView}
            messages={messages}
          />
        </main>
      </div>
    </ViewPort>
  );
};

export default SiteManagement;
