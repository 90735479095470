import { useEffect, useRef } from 'react';
import useLocationState from '../../hooks/useLocationState';
import ViewPort from './ViewPort';

const Bugs = props => {
    const { state } = useLocationState();
    const h = useRef();
    useEffect(() => {
        window.title = "ScholarSift - Bugs";
    })

    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f);
        if(v !== h.current)
            h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport])

    return  <ViewPort><div style={{minHeight: h.current, width: '100%'}}><iframe style={{height: h.current}} className="filled" src="/bugs" title="Bugs" aria-label="bug viewer" /></div></ViewPort>
}

export default Bugs;