import { useEffect, useState } from "react";
import { convertToHtml } from "mammoth/mammoth.browser";
import axios from "axios";

const DocxAsHtml = (props) => {
  const [html, setHTML] = useState();

  useEffect(() => {
    let file = props.file
      ? props.file
      : props.location && props.location.state
      ? props.location.state.file
      : undefined;
    let title = props.title
      ? props.title
      : props.location && props.location.state
      ? props.location.state.title
      : undefined;
    document.title = title ? title : "ScholarSift";
    if (file) {
      //Fetch File
      axios
        .get("/docs/" + file, { responseType: "blob" })
        .then((results) => results.data.arrayBuffer())
        .then((results) => convertToHtml({ arrayBuffer: results }))
        .then((v) => setHTML(v.value))
        .catch((err) => console.log(err));
    }
  }, [props]);

  return (
    <div className="display-panel">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default DocxAsHtml;
