import { useEffect } from "react";
import useUser from "../../hooks/useUser";
import ViewPort from "../Layout/ViewPort";
import SubmissionPanel from "./SubmissionPanel";

const Submissions = (props) => {
  const { setTitle } = useUser();

  useEffect(() => {
    document.title = "ScholarSift - Manage Submissions";
    setTitle("Manage Submissions");
    return () => {
      setTitle("");
    };
  });

  return props.account ? 
  <ViewPort>
      <SubmissionPanel {...props} />
  </ViewPort> :
  <SubmissionPanel {...props} />
};

export default Submissions;
