import { useState } from 'react';
import { Modal, PrimaryButton, TooltipHost } from '@fluentui/react';
import useUser from '../../../hooks/useUser';
import { useLocation } from 'react-router';
import useLocationState from '../../../hooks/useLocationState';
import axios from 'axios';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import Expedite from './Expedite';
import AddSubmissions from './AddSubmissions';
import Withdraw from './Withdraw';

const Title = ["Revise", "Expedite", "Submit", "Withdraw"]

const Actions = props => {
    const location = useLocation();
    const { dispatch } = useLocationState();
    const [open, setOpen] = useState(0);
    const { user, saveUser } = useUser();
    
    const doUpdate = (journals, status, notes, retries) => {
        if(retries && retries > 5)
            return;
        
        let data = {id: props.item.id, profile: user.current.scholarsift.profile, status: status, journals: journals, notes: notes};
        axios
            .post("/api/usubmission", data, {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" : user.current.scholarsift.coda
                }
            })
            .then(() => {
                props.update();
            })
            .catch(err => { 
                APIErrorHandler(err, user, saveUser, v => { 
                    let delay = retries ? (retries < 6 ? retries + 1 : retries) : 1;
                    setTimeout(doUpdate(delay), delay * 5000, journals, status, notes, retries); 
            })});
    }

    const Launch = screen => {
        dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: undefined});
        setOpen(screen);
    }

    const onCancel = () => { setOpen(0); }

    return  <>
                <div className="action-buttons">
                    <TooltipHost content="Revise Submission"><PrimaryButton className="action-button" text="Revise" onClick={props.revise}/></TooltipHost>
                    <TooltipHost content="Expedite Submission"><PrimaryButton className="action-button" text="Expedite" onClick={() => { Launch(1) }}/></TooltipHost>
                    <TooltipHost content="Submit to Additional Journals"><PrimaryButton className="action-button" text="Submit" onClick={() => Launch(2)}/></TooltipHost>
                    <TooltipHost content="Withdraw Submission"><PrimaryButton className="action-button" text="Withdraw" onClick={() => Launch(3)}/></TooltipHost>
                </div>
                <Modal
                    isOpen={open > 0}
                    onDismiss={() => setOpen(0)}
                    headerText={Title[open]}
                    closeButtonAriaLabel="Close"
                >
                    {
                        open === 1 && <Expedite {...props} onCancel={onCancel} onOK={setOpen} doUpdate={doUpdate} />
                    }
                    {
                        open === 2 && <AddSubmissions {...props} onCancel={onCancel} onOK={setOpen} />
                    }
                    {
                        open === 3 && <Withdraw {...props} onCancel={onCancel} onOK={setOpen} doUpdate={doUpdate} />
                    }
                </Modal>
            </>
}

export default Actions;