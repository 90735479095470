import useUser from "../../../hooks/useUser";
import { v4 as uuid } from "uuid";
import { htmlCite, sentenceCase, Debug } from "ss-lib";
import ResultPages from "../NewResults/ResultPages";
import {
  NotebookQuestionMark20Filled,
  DocumentEndnote20Filled,
} from "@fluentui/react-icons";
import { Button, ProgressBar, Tooltip } from '@fluentui/react-components';
import { useNavigate } from "react-router";

const CombinedListItem = (props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (props.item === undefined)
    return <div className="combined-list-item"></div>;

  const DoClick = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noopener noreferrer";
    a.click();
  };

  //let d = new Date().getFullYear();
  let item = props.item;

  if (item["custom download url"] && item["custom download url"].length > 0)
    item.url = item["custom download url"];
  if (item["curr url"] && item["curr url"].length > 0)
    item.url = item["curr url"];
  if (item.u) item.url = item.u;
  var keys = Object.keys(item);
  var v = item.authors ? item.authors : item.a ? item.a : "";
  v += (v.length ? " " : "") + (item.title ? item.title : item.t ? item.t : item.s && item.s.length ? item.s[0] : "");

  let newness = props.dateRange === undefined? 0 : item.year ? +item.year : item.y ? +item.y : item.d ? +item.d: 0;
  if(newness)
    newness = 1.0 - ((props.dateRange.max - newness) / (props.dateRange.max - props.dateRange.min));
  let ret = null;
  let url = item["url"] ? item["url"] : item["pdf url"];
  if (
    (item.type === "Book" || item.d !== undefined) &&
    item.title !== undefined
  ) {
    url =
      "https://www.worldcat.org/search?q=" +
      encodeURIComponent(item["title"].toLowerCase());
    if(Debug())
      console.log(url);
  }
  else if(url && item.type === "Journal")
    url = "/api/download/" + item.n + "." + user.current.scholarsift.profile;

  if(props.linkToPage)
    url = "/papers/" + item.n;

  let subs = undefined;
  if (item["sub topics"])
    subs = item["sub topics"]
      .sort((a, b) => a.localeCompare(b))
      .map((v, i) => (
        <div className="topics" key={uuid()}>
          {sentenceCase(v) + (i < item["sub topics"].length - 1 ? "," : "")}
        </div>
      ));

  if(item.avail === "Index Only")
    url = undefined;

  if(Debug())
    console.log(v);
  
  if ((v.length > 8 || keys.indexOf("j") > -1) && !v.startsWith("at "))
    ret = (
      <div>
        <div
          style={{
            height: 125, //Fixed Height to virtualize rendering
            backgroundColor:
              props.index % 2 ? "#FFF" : "rgba(96, 101, 116, 0.05)",
          }}
        >
          {" "}
          <div
            className={props.className ? props.className : "combined-list-item"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
                alignItems: "flex-start",
                marginRight: 10,
              }}
            >
              {
                props.item.cited ? 
                <DocumentEndnote20Filled
                  style={{ cursor: "default", color: "var(--dark-green)" }}
                /> :
                <NotebookQuestionMark20Filled
                  style={{ cursor: "default", color: "var(--berry)" }}
                />
              }
            </div>
            <div
              className="combined-item-data"
              data-n={user.current.scholarsift.isAdmin ? item.n : undefined}
              data-p={user.current.scholarsift.isAdmin ? item.p : undefined}
            >
              <div
                className={url ? "fake-link" : ""}
                onClick={() => {
                  if (url !== undefined) DoClick(url);
                }}
              >
                {htmlCite(item)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "center",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
              >
                {" "}
                {item["topics"] && (
                  <div className="topics" style={{ width: "fit-content" }}>
                    Topics:
                    <div className="topics right-spaced">
                      {sentenceCase(item["topics"])}
                      {subs && <div className="right-spaced">{subs}</div>}
                    </div>
                  </div>
                )}
                {
                  item.cosPages && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "right",
                        width: "fit-content",
                        alignSelf: "flex-end",
                      }}
                    >
                      <ResultPages
                        pages={item.cosPages}
                        url={item["url"] ? item["url"] : item["pdf url"]}
                      />
                    </div>
                  )}
              </div>
            </div>
            {
              props.analyze ? 
              <Button shape="rounded" appearance="primary" onClick={() => { navigate("/analyze/" + item.n, {state:{analyze:true}})}}>Analyze</Button>
              :
            <div>
              <div className="analyze-weight">
                <Tooltip content={"Score: " + (item.score * 100).toFixed(2)}>
                  <ProgressBar
                    shape="rounded"
                    thickness="large"
                    value={+item.score}
                  />
                </Tooltip>
              </div>
              {
                window.location.host.indexOf("scholarsift") === -1 &&
                item.g !== undefined &&
                <div className="analyze-weight">
                  <Tooltip content={"Gender Balance: " + item.g}>
                    <ProgressBar
                      shape="rounded"
                      thickness="large"
                      value={item.g}
                      color="success"
                    />
                  </Tooltip>              
                </div>
              }
              {
                window.location.host.indexOf("scholarsift") === -1 &&
                newness !== 0 &&
                <div className="analyze-weight">
                  <Tooltip content={"Newness: " + newness}>
                    <ProgressBar
                      shape="rounded"
                      thickness="large"
                      value={newness}
                      color="warning"
                    />
                  </Tooltip>
                </div>
              }
            </div>
          }
          </div>
        </div>
      </div>
    );

  return ret ? (
    ret
  ) : (
    <div
      className={props.className ? props.className : "combined-list-item"}
      style={
        props.side === undefined
          ? {}
          : props.side === 3 || props.side === 2
          ? { borderLeft: `0.25vmin var(--alt-color) solid` }
          : { borderRight: `0.25vmin var(--alt-color) solid` }
      }
    >
      &nbsp;
    </div>
  );
};

export default CombinedListItem;
