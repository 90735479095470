import { useEffect, useState } from "react";
import { Icon, Slider, TextField, TooltipHost } from "@fluentui/react";

const RangeableColumn = (props) => {
  const [sRange, setSRange] = useState(
    props.column.filter
      ? [Number(props.column.filter.sMin), Number(props.column.filter.sMax)]
      : props.itemRange
  );
  const [tOne, setTOne] = useState(sRange[0] + "");
  const [tTwo, setTTwo] = useState(sRange[1] + "");
  const [isSorted, setIsSorted] = useState(
    props.column && props.column.isSorted
  );

  useEffect(() => {
    if (props.column.filter)
      setSRange([props.column.filter.sMin, props.column.filter.sMax]);
    if (isSorted !== props.column.isSorted) setIsSorted(props.column.isSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.column]);

  useEffect(() => {
    if (
      props.column.filter &&
      props.column.filter.sMin === sRange[0] &&
      props.column.filter.sMax === sRange[1]
    )
      return;

    if (sRange[0] > sRange[1]) {
      setSRange([sRange[1], sRange[0]]);
      return;
    }

    if (props.itemRange[0] === sRange[0] && props.itemRange[1] === sRange[1])
      return;

    props.setSelectedRange({ sMin: sRange[0], sMax: sRange[1] });

    if (sRange[0] && tOne !== sRange[0].toString())
      setTOne(sRange[0].toString());
    if (sRange[1] && tTwo !== sRange[1].toString())
      setTTwo(sRange[1].toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sRange]);

  useEffect(() => {
    console.log("props.selectedRange");
    if (props.selectedRange === undefined) {
      console.log("Doing This...");
      setTOne(props.itemRange[0].toString());
      setTTwo(props.itemRange[1].toString());
      setSRange(props.itemRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRange]);

  const handleChange = (ev, newValue, selector) => {
    let x = Number(newValue);
    if (selector === "min") {
      if (newValue === tOne) return;

      if (x >= props.itemRange[0] && x <= props.itemRange[1])
        setSRange([x, sRange[1]]);
      else setTOne(newValue);
    } else {
      if (newValue === tTwo) return;

      setTTwo(newValue);
      if (x >= props.itemRange[0] && x <= props.itemRange[1])
        setSRange([sRange[0], x]);
    }
  };

  if (props.itemRange === undefined && props.unfilteredSize === undefined)
    return null;

  return (
    <div className="rangeable-column">
      <div className="column-range-view">
        <div className="column-range-entry">
          <TextField
            value={tOne}
            onChange={(ev, newValue) => {
              handleChange(ev, newValue, "min");
            }}
          />
          <div>{props.itemRange[0]}</div>
        </div>
        <div
          className="column-range-title"
          onClick={() => {
            props.processSort(props.column);
          }}
        >
          {props.column.name + " Range"}
          {isSorted ? (
            <Icon
              iconName={props.column.isSortedDescending ? "SortDown" : "SortUp"}
            />
          ) : null}{" "}
          {props.itemRange[0] !== sRange[0] ||
          props.itemRange[1] !== sRange[1] ? (
            <Icon
              iconName="StatusCircleErrorX"
              onClick={(e) => {
                e.stopPropagation();
                props.processRange(
                  props.column.key,
                  props.itemRange[0],
                  props.itemRange[1],
                  undefined,
                  undefined
                );
              }}
            />
          ) : null}
        </div>
        <div className="column-range-entry">
          <TextField
            value={tTwo}
            onChange={(ev, newValue) => {
              handleChange(ev, newValue, "max");
            }}
          />
          <div>{props.itemRange[1]}</div>
        </div>
      </div>
      <div className="column-range-group">
        {/*                     <div className="column-range-controls">
                        <Slider
                            ranged
                            showValue={false}
                            className="range-column"
                            onChange={(ev, r) => {
                                if(r[0] === r[1])
                                    return ;
                                if(r[0] !== sRange[0] || r[1] !== sRange[1])
                                    setSRange(r[0] === props.itemRange[0] && r[1] === props.itemRange[1] ? [undefined, undefined] : r);
                            }}
                            lowerValue={sRange[0]}
                            value={sRange[1]}
                            min={props.itemRange[0]}
                            max={props.itemRange[1]}
                        />
                    </div> */}
        {/*                     <div className="column-range-ends">
                        <TooltipHost content="Beginning"><Icon iconName="Previous" onClick={() => { setSRange(sRange[1] === props.itemRange[1] ? [undefined, undefined] : [props.itemRange[0], sRange[1]])}}/></TooltipHost>
                        <TooltipHost content="End"><Icon iconName="Next" onClick={() => {setTTwo(props.itemRange[1].toString()); setSRange(props.itemRange[0] === sRange[0] ? [undefined, undefined] : [sRange[0], props.itemRange[1]])}}/></TooltipHost>
                    </div> */}
      </div>
    </div>
  );
};

export default RangeableColumn;
