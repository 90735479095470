import axios from "axios";
import { APIErrorHandler } from "../actions/APIErrorHandler";

export const FillNodes = (cancel, user, saveUser, callBack, fatalError) => {
  axios
    .post(
      "/api/nodes",
      {},
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
        cancelToken: cancel.token,
      }
    )
    .then((res) => {
      console.log(res.data.nodes);
      callBack(res.data);
    })
    .catch((err) => {
      console.log(err);
      if (!axios.isCancel(err))
        APIErrorHandler(err, user, saveUser, (v) =>
          v
            ? fatalError(v)
            : FillNodes(cancel, user, saveUser, callBack, fatalError)
        );
    });
};

export const updateNode = (user, saveUser, formData, callBack, fatalError) => {
  const { code, title, section, jurisdiction, type } = formData;
  axios
    .post(
      "/api/nodes",
      {
        code,
        title,
        section,
        jurisdiction,
        type,
      },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((res) => {
      console.log(res.data);
      callBack(res.data);
    })
    .catch((err) => {
      console.log(err);
      /*       if (!axios.isCancel(err))
        APIErrorHandler(err, user, saveUser, (v) =>
          v
            ? fatalError(v)
            : FillNodes(cancel, user, saveUser, callBack, fatalError)
        ); */
    });
};
/* export const nodeQuery = (
  cancel,
  user,
  saveUser,
  nodeList,
  searchText,
  callBack,
  fatalError
) => {
  let q = {};

  if (searchText && searchText.length) q["search"] = searchText;

  if (nodeList && nodeList.length) q["list"] = nodeList;

  if (Object.keys(q).length > 0) {
    console.log(nodeList);
    console.log(searchText);
    //Lets run the query...
    return axios
      .post(
        "/api/nodes",
        { query: q },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
          cancelToken: cancel.token,
        }
      )
      .then((response) => {
        let data = {};
        console.log(q);

        data.nNodes = response.data.nNodes;
        data.rows = response.data.nodes;
        console.log(data);
        callBack(data);
      })
      .catch((err) => {
        if (!axios.isCancel(err))
          APIErrorHandler(err, user, saveUser, (v) =>
            v
              ? fatalError(v)
              : nodeQuery(
                  cancel,
                  user,
                  saveUser,
                  nodeList,
                  searchText,
                  callBack,
                  fatalError
                )
          );
      });
  }
}; */

export const nodeQuery = (
  cancel,
  user,
  saveUser,
  nodeList,
  searchText,
  callBack,
  fatalError
) => {
  let q = {};

  if (searchText && searchText.length) q["search"] = searchText;

  if (nodeList && nodeList.length) q["list"] = nodeList;

  if (Object.keys(q).length > 0) {
    console.log(nodeList);
    console.log(searchText);

    //Lets run the query...
    return axios
      .post(
        "/api/nodes",
        { query: q },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
          cancelToken: cancel.token,
        }
      )
      .then((response) => {
        let data = {};

        data.nNodes = response.data.nNodes;
        data.rows = response.data.nodes;
        console.log(response.data.nodes);
        callBack(data);
      })
      .catch((err) => {
        if (!axios.isCancel(err))
          APIErrorHandler(err, user, saveUser, (v) =>
            v
              ? fatalError(v)
              : nodeQuery(
                  cancel,
                  user,
                  saveUser,
                  nodeList,
                  searchText,
                  callBack,
                  fatalError
                )
          );
      });
  }
};
