import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChoiceGroup, Label, Toggle } from "@fluentui/react";
import { UpdateProfileField } from "../../../actions/Profiles";
import { GetUserAccount } from "../../../actions/Dashboard";
import useUser from "../../../hooks/useUser";
import Profile from "../../Profile";
import {PaperPage as Papers} from '../../Papers';
import AuthnetSubscriptionInfo from "./AuthnetSubscriptionInfo";
import GroupSubscriptionInfo from "./GroupSubscriptionInfo";
import ScholarSiftSubscriptionInfo from "./ScholarSiftSubscriptionInfo";
import { Debug } from 'ss-lib'

const paidAccounts = false;

const options = [
  {
    key: "0",
    text: "Basic User",
    disabled: false,
  },
  {
    key: "1",
    text: "Librarian/Group Admin",
    disabled: false,
  },
  {
    key: "2",
    text: "ScholarSift Admin",
    disabled: true,
  },
  {
    key: "3",
    text: "Law Review",
    disabled: false,
  },
];

const UserAccountSettings = (props) => {
  const { navigate } = useNavigate();
  const { user, saveUser } = useUser();
  const [showAccount, setShowAccount] = useState(true);
  const [profile, setProfile] = useState(props.profile);
  const [editMode, setEditMode] = useState(false);
  const [pEdit, setPEdit] = useState(false);
  const [ops, setOps] = useState([...options]);
  const [umode, setUmode] = useState("0");

  useEffect(() => {
    if (props.profile) {
      setEditMode(false);
      setPEdit(false);
      GetUserAccount(props.profile, user, saveUser, resetProfile, () => { if(navigate) navigate("/signin"); });
    }
    else 
      setProfile(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile]);

  const profileComp = useMemo(() => profile ? <Profile
                                  pdata={profile}
                                  readOnly={!editMode}
                                  isLawReview={umode === "3" || profile.roles === 3}
                                /> : undefined
  , [profile, editMode, umode])

  const paperComp = useMemo(() => profile ? <Papers name={profile.name} account={profile.profile} readOnly={!pEdit} /> : undefined
, [profile, pEdit])

  const resetProfile = (p) => {
    if(Debug())
      console.log(p)
    let o = [...options];
    o[2].disabled = p.isAdmin !== 1;
    setOps(o);
    if(p.uRole !== undefined)
      setUmode(p.uRole);
    setProfile(p);
  };

  const onChange = (ev, option) => {
    UpdateProfileField(
      user,
      saveUser,
      { key: "roles", value: Number(option.key), id: profile.id },
      profile.id,
      resetProfile
    );
  };

  return (
    !profile ? null :
    <div className="standard-section">
      <div className="profile-edit-toggle">
        <Toggle
          label="Show Account Settings"
          checked={showAccount}
          onText="On"
          offText="Off"
          onChange={(ev, checked) => {
            setShowAccount(checked);
          }}
          inlineLabel
        />
      </div>
      <div className="account-settings-wrapper">
        {showAccount && (
          <>
          <div className="display-header">
            <div className="display-name">Account Settings</div>
          </div>
          <div className="profile-settings">
            <ChoiceGroup
              label="User Mode"
              options={ops}
              onChange={(ev, op) => {
                onChange(ev, op);
                setUmode(op.key);
              }}
              selectedKey={umode}
            />
            {
              paidAccounts && 
              <div>
                <Label>Payment Status (Expiration - GMT)</Label>
                <div className="payment-group">
                  <AuthnetSubscriptionInfo {...profile} />
                  <GroupSubscriptionInfo {...profile} />
                  <ScholarSiftSubscriptionInfo
                    {...profile}
                    setProfile={setProfile}
                  />
                </div>
              </div>
            }
          </div>
          </>
        )}
      </div>
      {
        showAccount && (
        <>
          <div className="profile-edit-toggle">
            <Toggle
              label="Profile Edit Mode"
              checked={editMode}
              onText="On"
              offText="Off"
              onChange={(ev, checked) => {
                setEditMode(checked);
              }}
              inlineLabel
            />
          </div>
          <div className={!editMode ? "read-only-panel" : "editable-panel"}>
            {profileComp}
          </div>
        </>
      )}
      <div className="profile-edit-toggle">
        <Toggle
          label="Paper Edit Mode"
          checked={pEdit}
          onText="On"
          offText="Off"
          onChange={(ev, checked) => {
            setPEdit(checked);
          }}
          inlineLabel
        />
      </div>
      <div className={!pEdit ? "read-only-panel" : "editable-panel"}>
        { paperComp }
      </div>
    </div>
  );
};

export default UserAccountSettings;
