const TextInput = ({
  label,
  name,
  placeholder,
  required,
  error,
  value,
  onChange,
  style,
}) => {
  return (
    <div className="basic-input-wrapper">
      <label className="basic-input-label">{label}</label>
      <input
        className="basic-input"
        style={style}
        placeholder={placeholder}
        required={required}
        errormessage={error}
        value={value}
        name={name}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
