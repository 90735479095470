import { useState, useEffect } from "react";
import useUser from '../../../hooks/useUser';
import { useNavigate } from "react-router";
import { useTransition, animated, useSpringRef } from "react-spring";

import ViewPort from "../ViewPort";
import useLocationState from "../../../hooks/useLocationState";
import Slide from "./Slide";
import SlideControls from "./Slide/SlideControls";
import { AddFriendIcon } from "@fluentui/react-icons-mdl2";
import { NextRegular } from '@fluentui/react-icons'
import { useWindowDimensions } from "ss-lib";

const Splash = ({ animation }) => {
  const { setTitle } = useUser();
  const dim = useWindowDimensions();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [isLeft, setIsLeft] = useState(false);
  const transApi = useSpringRef();

  useEffect(() => {
    document.title = "ScholarSift";
    setTitle("");
  });

  useEffect(() => {
    transApi.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleClickRight = () => {
    setIndex((state) => (state + 1) % pages.length);
    setIsLeft(false);
  };

  const handleClickLeft = () => {
    setIndex((state) => (state - 1) % pages.length);
    setIsLeft(true);
  };

  const handlePricing = (e) => {
    e.preventDefault();

    navigate("/subscribe");
  };

  const TitlePage = ({ style }) => {
    const { state } = useLocationState();

    return (
      <section
        className="slide-container"
        style={{
          height: state.viewport.h - (state.viewport.m + state.viewport.f),
        }}
      >
        <animated.div className="slide-logo" style={{ ...style }}>
          {animation}
        </animated.div>
        <div className="splash-actions">
        <div className="splash-sub-headline">Analysis is now<span className="smol">Free</span>!</div>
            <button
              className="splash-button splash-button-margins"
              id="signup-splash-btn"
              onClick={() => navigate("/signin/create")}
            >
              Get Started <NextRegular id="btn-icon" />
            </button>
            <button
              className="splash-button splash-button-margins"
              id="about-splash-btn"
              onClick={handleClickRight}
            >
              Learn More
            </button>
            
            {
              /*
            <button
              className="splash-button"
              id="pricing-splash-btn"
              onClick={handlePricing}
            >
              Pricing
            </button>
            */
            }
          {
              dim.width < 800 &&    
              <p className="splash-text">
                We recommend using a larger screen to research more effectively
              </p>
            }
        </div>
      </section>
    );
  };
  const pages = [
    ({ style }) => (
      <div>
        <TitlePage style={style} />
      </div>
    ),
    ({ style }) => (
      <section>
        <Slide
          header={
            <animated.div style={{ ...style }} className="basic-row">
              Makes legal scholarship more
              <span> accessible.</span>
            </animated.div>
          }
          text={
            <animated.div style={{ ...style, fontWeight: 300 }}>
              <div id="slide-header-sm">
                Makes legal scholarship more
                <span> accessible.</span>
              </div>
              <p>ScholarSift offers a free submission platform. </p>
              <p>
                Authors can submit their work to multiple journals
                simultaneously - without the financial backing of a large
                university. And journals can provide a free option without the
                need to “switch” platforms or keep track of individual emails.
              </p>
              <p>
                With ScholarSift, your draft is your search. Find relevant
                scholarship for a fraction of the price of the major legal
                research platforms.
              </p>
            </animated.div>
          }
          slideControls={
            <SlideControls
              handleClickLeft={handleClickLeft}
              handleClickRight={handleClickRight}
            />
          }
        />
      </section>
    ),
    ({ style }) => (
      <section>
        <Slide
          header={
            <animated.div className="basic-row" style={{ ...style }}>
              Makes legal scholarship more <span>equitable.</span>
            </animated.div>
          }
          text={
            <animated.div style={{ ...style, fontWeight: 300 }}>
              <div id="slide-header-sm">
                Makes legal scholarship more
                <span> equitable.</span>
              </div>
              <p>
                ScholarSift gives journals insights that matter: Citation
                counts, missing relevant works, and possible preemption.
              </p>
              <p style={{ paddingTop: 10 }}>
                ScholarSift also allows authors to self-identify as members of
                underrepresented groups in legal scholarship, to facilitate{" "}
                greater equity and representation in legal scholarship
              </p>
            </animated.div>
          }
          slideControls={
            <SlideControls
              handleClickLeft={handleClickLeft}
              handleClickRight={handleClickRight}
            />
          }
        />
      </section>
    ),
    ({ style }) => (
      <section>
        <Slide
          header={
            <animated.div className="basic-row" style={{ ...style }}>
              Makes legal scholarship more <span>complete.</span>
            </animated.div>
          }
          text={
            <animated.div style={{ ...style, fontWeight: 300 }}>
              <div id="slide-header-sm">
                Makes legal scholarship more
                <span> complete.</span>
              </div>
              <p>
                ScholarSift helps authors find on-point literature that may be
                missing from their draft, making scholarship more robust, more
                inclusive, and more accountable.
              </p>
              <p>
                Simply drop in your draft, and ScholarSift analyzes the text and
                citations to identify relevant missing works.
              </p>
            </animated.div>
          }
          slideControls={
            <SlideControls
              handleClickLeft={handleClickLeft}
              handleClickRight={handleClickRight}
            />
          }
        />
      </section>
    ),
    ({ style }) => (
      <div>
        <Slide
          header={
            <animated.div className="basic-row" style={{ ...style }}>
              Makes legal scholarship <span>better.</span>
            </animated.div>
          }
          text={
            <animated.div
              style={{ ...style, display: "flex", flexDirection: "column" }}
            >
              <div id="slide-header-sm">
                Makes legal scholarship
                <span> better.</span>
              </div>
              <header className="slide-right-header">
                Unlock the potential of legal scholarship.
              </header>
              <button
                className="splash-button"
                onClick={() => navigate("/about")}
                style={{ width: 200, margin: "40px auto 10px auto" }}
              >
                About
              </button>
              {
                /*
              <button
                className="splash-button"
                onClick={() => navigate("/subscribe")}
                style={{ width: 200, margin: "auto" }}
              >
                Pricing
              </button>
              */
              }
              <button
                className="splash-button"
                onClick={() => navigate("/analyze")}
                style={{ width: 200, margin: "10px auto" }}
              >
                Analyze
              </button>
              <button
                className="splash-button"
                onClick={() => navigate("/signin")}
                style={{ width: 200, margin: "auto" }}
              >
                Sign up
                <AddFriendIcon style={{ marginLeft: 10 }} />
              </button>
            </animated.div>
          }
          slideControls={
            <SlideControls
              handleClickLeft={handleClickLeft}
              handleClickRight={handleClickRight}
            />
          }
        />
      </div>
    ),
  ];

  const transitions = useTransition(index, {
    ref: transApi,
    keys: null,
    from: isLeft
      ? { opacity: 0, transform: "translate3d(100%,0,0)" }
      : { opacity: 0, transform: "translate3d(-100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: isLeft
      ? { opacity: 0, transform: "translate3d(-50%,0,0)" }
      : { opacity: 0, transform: "translate3d(50%,0,0)" },
    exitBeforeEnter: true,
  });

  return (
    <ViewPort>
      {transitions((style, i) => {
        const Page = pages[i];
        return <Page style={style} />;
      })}
    </ViewPort>
  );
};

export default Splash;
