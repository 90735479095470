import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableSelectionCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useTableFeatures,
  useTableSelection,
  useTableSort,
  TableColumnDefinition,
  createTableColumn,
  TableColumnId,
} from "@fluentui/react-components";
import useLocationState from "../../../hooks/useLocationState";
import {v4 as uuid} from 'uuid';

type Item = {
  email: string;
  id: number;
  source: string;
}

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "email",
    compare: (a, b) => {
      return a.email.localeCompare(b.email);
    }
  }),
  createTableColumn<Item>({
    columnId: "profile",
    compare: (a, b) => {
      return a.id - b.id;
    }
  }),
  createTableColumn<Item>({
    columnId: "accountSource",
    compare: (a, b) => {
      if(a.source === b.source)
        return a.id - b.id;
      return Sources[a.source].localeCompare(Sources[b.source]);
    },
  }),
];

const columnNames: string[] = ["Email", "Profile Id", "Account Source"];

const Sources: string[] = ["Google", "Microsoft", "ScholarSift"];

const UserList = (props) => {
  const location = useLocation();
  const [originalItems] = useState(() =>
    props.items
      ? props.limit
        ? props.items.slice(0, Number(props.limit))
        : props.items
      : []
  );
  const [items, setItems] = useState([...originalItems]);
  const { profile } = props;
  const { state } = useLocationState();

  const {
    getRows,
    sort: {getSortDirection, toggleColumnSort, sort}, 
    selection: { toggleRow, isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: "profile", sortDirection: "descending" }
      }),
      useTableSelection({
        selectionMode: "single",
        defaultSelectedItems: new Set([]),
      })
    ]
  );

  const rows = sort(getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      onClick: e => { props.setProfile(row.item.id); toggleRow(e, row.rowId) },
      onKeyDown: e => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand" as const) : ("none" as const)
    };
  }));

  useEffect(() => {
    if (state[location.pathname].filter) {
      let itms = originalItems.filter(
        (item) =>
          item.email
            .toLowerCase()
            .indexOf(state[location.pathname].filter.toLowerCase()) >= 0 ||
          item.id.toString().indexOf(state[location.pathname].filter.toString()) > -1

      );
      if (profile && itms.map((v) => v.id).indexOf(profile.id) === -1)
        props.setProfile(undefined);
      setItems(itms);
    } else setItems([...originalItems]);
  }, [location.pathname, originalItems, profile, props, state]);

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId)
  });
  
  return (
          <div className="user-list">
            <Table sortable aria-label="User List">
              <TableHeader>
                <TableRow>
                  <TableSelectionCell type="radio" />
                  { columns.map((v, i) => <TableHeaderCell {...headerSortProps(v.columnId)} key={uuid()}>{columnNames[i]}</TableHeaderCell>) }
                </TableRow>
              </TableHeader>
              <TableBody>
                { rows.map(({item, selected, onClick, appearance}) => 
                  <TableRow
                    key={uuid()}
                    onClick={onClick}
                    appearance={appearance}
                  >
                    <TableSelectionCell checked={selected} type="radio" radioIndicator={{"aria-label":"Select row"}} />
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{Sources[item.source]}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        );
}

export default UserList;
