import axios from "axios";
import { Debug } from 'ss-lib';
import { APIErrorHandler } from "./APIErrorHandler";

export const FTS = (text, user, saveUser, callBack, fatalError) => {
  axios
    .post(
      "/api/fts",
      { text: text, profile: user.current.scholarsift.profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, (v) =>
        v ? fatalError(v) : FTS(text, user, saveUser, callBack)
      );
    });
};

export const testFTS = (user, text, callBack) => {
  axios
    .post(
      "/api/fts",
      { text: text, profile: user.current.scholarsift.profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      callBack(0, 3, response, "FTS");
    })
    .catch((error) => {
      callBack(0, 3, error.response, "FTS");
    });
};

export const testSearch = (user, text, callBack) => {
  axios
    .post(
      "/api/cite",
      { text: text, profile: user.current.scholarsift.profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => {
      callBack(0, 4, response, "Search");
    })
    .catch((error) => callBack(0, 4, error.response, "Search"));
};

export const DoSearch = (text, user, saveUser, callBack, fatalError) => {
  if(Debug())
    console.log("Searching: " + text)
  axios
      .post(
        "/api/search",
        { text: text },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
        if(Debug())
          console.log(response.data)
        callBack({search: response.data.ids});
      })
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) =>
          v ? fatalError(v) : DoSearch(text, user, saveUser, callBack)
        )
      );
};

export const processURL = (
  url,
  user,
  saveUser,
  callBack,
  fatalError,
  setInfo
) => {
  if(Debug())
    console.log("Processing URL: " + url);
  setInfo("Uploading URL");

  axios
    .post("/api/url", { url: url },{
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((res) => {
      updateStatus(res.data, user, saveUser, callBack, fatalError, setInfo);
    })
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) => {
        if (v && v === "data update")
          setInfo("Database is updating, processing will begin shortly");
        processURL(url, user, saveUser, callBack, fatalError, setInfo);
      })
    );
};

export const aproxyraisin = (callBack, user) => {
  axios
    .post("https://aproxyraisin.herokuapp.com/showcase", {
      url: "https://bjs.ojp.gov/content/pub/pdf/wfbcjsru.pdf",
    })
    .then((res) => {
      callBack(0, 0, res, "Proxy Server");
      testProcessURL(res.data.text, callBack);
      testFTS(user, res.data.text, callBack);
      testSearch(user, res.data.text, callBack);
    })
    .catch((error) => {
      console.log(error);
      callBack(0, 0, error, "Proxy Server");
    });
};

const testProcessURL = (text, callBack) => {
  axios
    .post("/api/showcase", { data: text })
    .then((res) => {
      callBack(0, 1, res, "Process URL");
    })
    .catch((error) => callBack(0, 1, error, "Process URL"));
};

export const testProcessDocument = (user, file, callBack) => {
  let data = new FormData();
  data.append("file", file);
  data.append("fn", file.name);

  axios
    .post("/api/file", data, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((response) => {
      console.log(response);
      callBack(0, 2, response, "Process Document");
    })
    .catch((error) => {
      console.log(error);
      callBack(0, 2, error.response, "Process Document");
    });
};

export const processDocument = (
  file,
  user,
  saveUser,
  callBack,
  fatalError,
  setInfo
) => {

  console.log(file)
  if(typeof file === "number") {
    axios
      .get("/api/analyze/" + file, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((response) =>
        updateStatus(
          { ...response.data },
          user,
          saveUser,
          callBack,
          fatalError,
          setInfo
        )
      )
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) => {
          if (v) {
            if (v === "data update") {
              setInfo("Database is updating, processing will begin shortly");
              processDocument(
                file,
                user,
                saveUser,
                callBack,
                fatalError,
                setInfo
              );
            } else if (v.toString().indexOf("Unable to parse token") > -1) {
              console.log("Login again, Process Doc");
            } else if (v.coda)
              processDocument(
                file,
                user,
                saveUser,
                callBack,
                fatalError,
                setInfo
              );
            else fatalError(v);
          } else
            processDocument(file, user, saveUser, callBack, fatalError, setInfo);
        })
      );
  }
  else if(file.submission) {
    axios
      .get("/api/analyze/s" + file.f, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((response) =>
        updateStatus(
          { ...response.data },
          user,
          saveUser,
          callBack,
          fatalError,
          setInfo
        )
      )
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) => {
          if (v) {
            if (v === "data update") {
              setInfo("Database is updating, processing will begin shortly");
              processDocument(
                file,
                user,
                saveUser,
                callBack,
                fatalError,
                setInfo
              );
            } else if (v.toString().indexOf("Unable to parse token") > -1) {
              console.log("Login again, Process Doc");
            } else if (v.coda)
              processDocument(
                file,
                user,
                saveUser,
                callBack,
                fatalError,
                setInfo
              );
            else fatalError(v);
          } else
            processDocument(file, user, saveUser, callBack, fatalError, setInfo);
        })
      );
  }
  else {
    let data = new FormData();
    data.append("file", file);
    data.append("fn", file.name);

    setInfo("Uploading " + file.name);

    axios
      .post("/api/file", data, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((response) =>
        updateStatus(
          { ...response.data, filename: file.name },
          user,
          saveUser,
          callBack,
          fatalError,
          setInfo
        )
      )
      .catch((error) =>
        APIErrorHandler(error, user, saveUser, (v) => {
          console.log(v)
              processDocument(
                file,
                user,
                saveUser,
                callBack,
                fatalError,
                setInfo
              );
        })
      );
    }
};

export const GetAuthored = (author, user, saveUser, callBack, fatalError) => {
  axios
    .post(
      "/api/authored",
      { author: author, profile: user.current.scholarsift.profile },
      {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      }
    )
    .then((response) => callBack(response))
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) => {
        console.log(v);
        if (v) fatalError(v);
        else GetAuthored(author, user, saveUser, callBack);
      })
    );
};

export const ExportResults = (user, saveUser, results, setMessage) => {
  axios
    .post("/api/exportresults", results, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
    .then((results) => {
      let cd = results.headers["content-disposition"];
      let fn = cd.substring(cd.lastIndexOf("=") + 2);
      fn = fn.substring(0, fn.length - 1);
      const blob = new Blob([results.data], {
        type: results.headers["content-type"],
        size: results.headers["content-length"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fn);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) => {
        console.log(v);
        if (v) setMessage(v);
        else ExportResults(user, saveUser, results, setMessage);
      })
    );
};

export const updateStatus = (
  json,
  user,
  saveUser,
  callBack,
  fatalError,
  setInfo
) => {
  //Update
  var keys = Object.keys(json);
  var status = json["status"];
  if (json["status"] === "Done") {
    callBack(json);
    return;
  } else if (
    status === "Parsing" &&
    keys.indexOf("page") > -1 &&
    keys.indexOf("nPages") > -1
  )
    status += " " + json["page"] + "/" + json["nPages"];
  setInfo(status);

  //Launch Timer to check again
  new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
    if(user.current.page)
      getStatus(json["id"], user, saveUser, callBack, fatalError, setInfo)
    }
  );
};

const getStatus = (id, user, saveUser, callBack, fatalError, setInfo) => {
  let data = { id: id };
  axios
    .post("/api/status", data, {
      headers: { Authorization: "guest" },
    })
    .then((response) => {
      var json = response.data;
      json["id"] = id;
      updateStatus(json, user, saveUser, callBack, fatalError, setInfo);
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        getStatus(id, user, saveUser, callBack, fatalError, setInfo);
      });
    });
};

export const analyzeSubmission = (
  id,
  user,
  saveUser,
  callBack,
  fatalError,
  setInfo
) => {
  axios
    .get(`/api/analyze/${id}`, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })

    .then((response) =>
      updateStatus(
        { ...response.data, filename: id },
        user,
        saveUser,
        callBack,
        fatalError,
        setInfo
      )
    )
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, (v) => {
        console.log(v);
        if (v) {
          if (v === "data update") {
            setInfo("Database is updating, processing will begin shortly");
            analyzeSubmission(
              id,
              user,
              saveUser,
              callBack,
              fatalError,
              setInfo
            );
          } else if (v.coda)
            analyzeSubmission(
              id,
              user,
              saveUser,
              callBack,
              fatalError,
              setInfo
            );
          else fatalError(v);
        } else
          analyzeSubmission(id, user, saveUser, callBack, fatalError, setInfo);
      })
    );
};

export const getScrapedText = (
  targetURL,
  user,
  saveUser,
  setData,
  setState,
  fatalError,
  callBack
) => {
  axios
    .post("https://aproxyraisin.herokuapp.com/showcase", {
      url: targetURL,
    })
    .then((res) => {
      if (res.data.text) {
        callBack(targetURL, res);
        user && saveUser
          ? processURL(
              res.data.text,
              `${res.data.title} - (${targetURL})`,
              user,
              saveUser,
              setData,
              fatalError,
              (v) => setState("info", v)
            )
          : processURL(
              res.data.text,
              `${res.data.title} - (${targetURL})`,
              null,
              null,
              setData,
              fatalError,
              (v) => setState("info", v)
            );
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
};
