import { useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import { Checkbox, Icon, Label, TooltipHost } from '@fluentui/react'
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';
import {AuthorCard} from 'ss-lib';

const SubmittedItem = props => {
    const [expand, setExpand] = useState(false);
    const { user, saveUser } = useUser();
    const [id, setID] = useState();
    const [dChecked, setDChecked] = useState(Number(props.item.demos | 0) > 0);
    const [showAbstract, setShowAbstract] = useState(props.item.abstract && props.item.abstract.length > 0);
    const [showCover, setShowCover] = useState(props.item.cover && props.item.cover.length > 0);
    const [showCV, setShowCV] = useState(props.item.cv && props.item.cv.length > 0);
    
    useEffect(() => {
        if(id) {
            document.getElementById(id)?.focus();
        }
    }, [id])

    useEffect(() => {
        setDChecked(Number(props.item.demos | 0) > 0);
        setShowAbstract(props.item.abstract && props.item.abstract.length > 0);
        setShowCover(props.item.cover && props.item.cover.length > 0);
        setShowCV(props.item.cv && props.item.cv.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item])

    const doUpdate = (checked, retries) => {
        if(retries && retries > 5)
            return;
        
        let data = {id: props.item.id, profile: props.user.scholarsift.profile, demos: checked};
        axios
            .post("/api/usubmission", data, {
                headers : {
                    "Authorization":"Bearer " + user.current.token.id,
                    "Coda" : user.current.scholarsift.coda
                }
            })
            .then(response => {
                console.log("Updated Item");
            })
            .catch(err => { 
                APIErrorHandler(err, user, saveUser, v => { 
                    let delay = retries ? (retries < 6 ? retries + 1 : retries) : 1;
                    setTimeout(doUpdate(delay), delay * 5000, checked, retries); 
            })});
    }

    const updateItem = (_, checked) => {
        setDChecked(checked);
        doUpdate(checked);
    }

    const checkRevision = (_, checked, type) => {
        if(type === "abstract")
            setShowAbstract(checked);
        else if(type === "cv")
            setShowCV(checked);
        else if(type === "cover")
            setShowCover(checked);

        if(props.item[type] && props.item[type].length > 0) 
            return ;
        
        //Process
    }

    let title = props.item.title;

    return  <div className="submitted-item-details">
                <div className="submitted-item-title-wrapper">
                    <div className="submitted-item-icon" onClick={() => {setExpand(!expand)}}>
                        <TooltipHost content={expand ? "Click to close information panel" : "Click to display information panel"}>
                            <Icon iconName={expand ? "ChevronDown" : "ChevronRight"}/>
                        </TooltipHost>
                    </div>
                    <div className="submitted-item-title" onDoubleClick={() => setExpand(!expand)}>
                        <TooltipHost content={props.item.title}>{title}</TooltipHost>
                    </div>
                </div>
                {
                    expand && props.item && props.item.authors ? props.item.authors.map((_v, i) => <AuthorCard item={props.item} index={i} setID={setID} setItem={(v) => {props.updateItemField(props.item.id, "authors", v.authors)}} />) : undefined
                    //<Authors authors={authors} setAuthors={v => { setAuthors(v); props.updateItemField(props.item.id, "authors", v);}}/>
                }
                        
                
                {expand && <Label>Options</Label> }
                {expand && <div className="submitted-item-ops">
                    <Checkbox label="Demographics" checked={dChecked} onChange={updateItem} />
                    <div className="rowgroup">
                        <Checkbox className="rgw" label="Abstract" defaultChecked={showAbstract} onChange={(ev, checked) => {checkRevision(ev, checked, "abstract")}}/>
                        {
                            showAbstract && 
                            <div className="hoverer">
                                <TooltipHost content="Revise Abstract"><Icon iconName="Refresh" onClick={() => {props.doRevision(props.item, "abstract")}} /></TooltipHost>
                            </div>
                        }
                    </div>
                    <div className="rowgroup">
                        <Checkbox className="rgw" label="Cover Letter" defaultChecked={showCover} onChange={(ev, checked) => {checkRevision(ev, checked, "cover")}}/>
                        {
                            showCover && 
                            <div className="hoverer">
                                <TooltipHost content="Revise Cover Letter"><Icon iconName="Refresh" onClick={() => {props.doRevision(props.item, "cover")}} /></TooltipHost>
                            </div>
                        }
                    </div>
                    <div className="rowgroup">
                        <Checkbox className="rgw" label="CV" defaultChecked={showCV} onChange={(ev, checked) => {checkRevision(ev, checked, "cv")}}/>
                        {
                            showCV && 
                            <div className="hoverer">
                                <TooltipHost content="Revise CV"><Icon iconName="Refresh" onClick={() => {props.doRevision(props.item, "cv")}} /></TooltipHost>
                            </div>
                        }
                    </div>
                </div>}
            </div>
}

export default SubmittedItem;