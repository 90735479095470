import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../Layout/Loading';
import axios from 'axios';

const DoReset = props => {
    const navigate = useNavigate();
    const { id } = useParams();
    
    useEffect(() => {
        window.title = "ScholarSift - Password Reset";
        axios
            .post("/api/lookupid", {id:id})
           .then(response => {
                if(response.data.id === -1)
                    navigate("/signin", { state:{msg: "Password has already been reset"} });
                navigate("/resetpassword", {state: response.data})
            })
            .catch(err => {
                console.log(err);
                navigate("/signin", { state:{msg: "Password has already been reset"} });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [id])

    return <Loading msg="Loading account information" />
}

export default DoReset;