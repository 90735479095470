import { useState } from "react";
import {
  CheckboxVisibility,
  DetailsList,
  FocusZone,
  FocusZoneDirection,
  SelectionMode,
  TextField,
} from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";

const GroupList = (props) => {
  const onColumnClick = (ev, column, columns) => {
    let curr = column;
    let cols = [...columns];

    cols.forEach((c) => {
      if (c.key === column.key) {
        c.isSortedDescending = !c.isSortedDescending;
        c.isSorted = true;
        curr = c;
      } else {
        c.isSorted = false;
        c.isSortedDescending = true;
      }
    });

    setItems(
      items
        .slice(0)
        .sort((a, b) =>
          (
            curr.isSortedDescending
              ? a[curr.fieldName] < b[curr.fieldName]
              : a[curr.fieldName] > b[curr.fieldName]
          )
            ? 1
            : -1
        )
    );
    setColumns(cols);
  };

  const originalItems = useConst([...props.groups]);
  const [items, setItems] = useState(originalItems);
  const [columns, setColumns] = useState([
    {
      key: "name",
      name: "Name",
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
    {
      key: "domain",
      name: "Domain",
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
    {
      key: "id",
      name: "Group Id",
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
  ]);

  if (originalItems.length === 0) return null;

  const resultCountText =
    items.length === originalItems.length
      ? ""
      : ` (${items.length} of ${originalItems.length} shown)`;

  const onFilterChanged = (_, text) => {
    setItems(
      originalItems.filter(
        (item) => item.email.toLowerCase().indexOf(text.toLowerCase()) >= 0
      )
    );
  };

  return (
    <>
      <div className="display-header">
        <div className="display-name">Groups</div>
      </div>
      <div className="user-list">
        <FocusZone direction={FocusZoneDirection.vertical}>
          <div className="active-user-selector-filter-w-button">
            <TextField
              className="active-user-selector-filter-input"
              label={"Filter" + resultCountText}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={onFilterChanged}
            />
            <div
              className="active-user-selector-refresh"
              onClick={props.UpdateList}
            >
              Refresh
            </div>
          </div>
          <DetailsList
            items={items}
            columns={columns}
            onRenderItemColumn={(item, _, column) => (
              <div>{item[column.key]}</div>
            )}
            onActiveItemChanged={(item) => {
              props.setGroup(item.id);
            }}
            checkboxVisibility={CheckboxVisibility.always}
            selectionMode={SelectionMode.single}
          />
        </FocusZone>
      </div>
    </>
  );
};

export default GroupList;
