import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { Checkbox } from "@fluentui/react";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import { GetUserList } from "../../../actions/Management";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { statusItems } from "../../../utils";
import ViewPort from "../../Layout/ViewPort";
import Loading from "../../Layout/Loading";
import { Debug } from 'ss-lib';
import SubmissionPortalPanel from './SubmissionPortalPanel'
import { Panel } from "@fluentui/react";

const defaultWSS = "wss://" + window.location.host + "/ws";
const columns = [
  "Title",
  "Type",
  "Status",
  "Topics",
  "Tags",
  "Notes",
  "Citations",
  "Above",
  "Below",
  "Abstract",
  "Cover",
  "CV",
  "Author Title(s)",
  "Role",
  "Gender",
  "Ethnicity",
  "Orientation",
];

//WS based on https://dev.to/fpeluso/a-simple-websocket-between-java-and-react-5c98 accessed 2021-09-13
const SubmissionPortal = (props) => {
  const { instance, accounts } = useMsal(); 
  const location = useLocation();
  const { dispatch, state } = useLocationState();
  const { user, saveUser, ws, setTitle } = useUser();

  const [checks, setChecks] = useState([]);
  const [checked, setChecked] = useState([]);
  const [items, setItems] = useState();
  const [omitted, setOmitted] = useState([]);
  const [open, setOpen] = useState(false);
  const [fetchingTags, setFetchingTags] = useState(false);
  const [order, setOrder] = useState();
  const [tags, setTags] = useState();
  const [lawReview, setLawReview] = useState();
  const alive = useRef(true);
  const fetching = useRef(false);

  const [editors, setEditors] = useState();
  const [fetchingEditors, setFetchingEditors] = useState(false);

  const [t, setT] = useState();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  const FetchTags = (itms, retries) => {
    if(fetchingTags && retries === 0)
      return ;
    
    if (Debug()) console.log("Fetching Tags");
    if (retries && retries > 5) return;

    setFetchingTags(true);
    let data = {
      profile:
        itms && itms.length > 0
          ? itms[0]["law review"]
          : user.current.scholarsift.profile,
    };
    axios
      .post("/api/lrtags", data, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((response) => {
        let tagz = Object.keys(response.data).map((v) => {
          //Add To Items
          itms.forEach((item) => {
            if (item.tags === undefined) item.tags = [];

            if (response.data[v].submissions.indexOf(item.id) > -1)
              item.tags.push(v);
          });

          //Return To Array
          return { name: v, id: response.data[v].tag };
        });
        itms.forEach((v) => {
          v.msg = statusItems[v.status];
        });

        setItems(itms);
        setTags(tagz);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, (v) => {
          let delay = retries ? (retries < 6 ? retries + 1 : retries) : 1;
          setTimeout(FetchTags, delay * 5000, itms, delay);
        });
      });
  };

  const FetchSubmissions = (retries) => {
    fetching.current = true;
    
    if(Debug())
      console.trace("Fetch Submissions")
    
    if (retries && retries > 5) return;

    let data = {
      profile: props.account
        ? props.account
        : user.current.scholarsift.limits
          ? user.current.scholarsift.profile
          : undefined,
    };
    axios
      .post(
        location.pathname.indexOf("todo") > -1
          ? "/api/lrtodo"
          : "/api/lrsubmissions",
        data,
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((response) => {
        if (response.data.omitted) setOmitted(response.data.omitted);
        if (response.data.order) setOrder(response.data.order);
        if (
          response.data.submissions &&
          response.data.submissions.length > 0 &&
          response.data.submissions[0]["law review"]
        )
          setLawReview(response.data.submissions[0]["law review"]);

        if (!fetchingTags) FetchTags(response.data.submissions);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, (v) => {
          let delay = retries ? (retries < 6 ? retries + 1 : retries) : 1;
          setTimeout(FetchSubmissions(delay), delay * 5000);
        });
      });
  };

  const FetchEditors = () => {
    if (Debug()) console.log("Fetching Editors");
    if (Debug()) console.log(props.account);
    if (Debug()) console.log(fetchingEditors);

    if (!props.account && !fetchingEditors) {
      setFetchingEditors(true);
      GetUserList(
        {...user, account: accounts[0] || {}, instance: instance},
        saveUser,
        (v) => {
          if (Debug()) console.log("EList");
          if (Debug()) console.log(v);
          setEditors(v.filter((vv) => vv.limits));
          setFetchingEditors(false);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //List Submissions
    if (items === undefined && !fetching.current) FetchSubmissions();

    if (
      editors === undefined &&
      !fetchingEditors &&
      location.pathname.indexOf("todo") === -1
    ) {
      if (Debug()) console.log("Fetching Editors");
      FetchEditors();
    }
  });

  useEffect(() => {
    document.title =
      location.pathname.indexOf("todo") === -1
        ? "ScholarSift - Submissions Portal"
        : "ScholarSift - To Do List";
    setTitle(
      location.pathname.indexOf("todo") === -1
        ? "Submissions Portal"
        : "To Do List"
    );
    return () => {
      setTitle("");
    };
  }, [location.pathname, setTitle]);

  useEffect(() => {
    if(items)
    fetching.current = false;
  }, [items]);

  //WS Helpers
  const ProcessOpen = () => {
    if (window.location.host.indexOf("scholar") === -1) console.log("WS OPEN");
    keepAlive();
  };

  const ProcessClose = (e) => {
    if (window.location.host.indexOf("scholar") === -1) {
      console.log("WS CLOSE");
      console.log(e);
    }
  };

  const setPKS = (key, payload) => {
    dispatch({
      type: "path-key-update",
      path: location.pathname,
      key: key,
      payload: payload,
    });
  };

  const ProcessMessage = (e) => {
    console.log(e);
    e.data
      .text()
      .then((res) => {
        if (window.location.host.indexOf("scholar") === -1) console.log(res);

        var js = JSON.parse(res);
        if (js.status || js.task) {
          let itms = [...items];
          itms.forEach((v) => {
            if (v.id === js.id) {
              if (window.location.pathname.indexOf("todo") === -1) {
                if (js.status) v.status = js.status;
                v.editors.forEach((vv) => {
                  if (vv.email === js.email) {
                    vv.status = js.task;
                  }
                });
              } else v.status = js.task;
            }
          });

          setItems(itms);
        }
        if (js.taskValue) {
          let itms = [...items];
          itms.forEach((v) => {
            if (v.id === js.id) {
              let e = {
                email: js.user.email,
                task: js.taskValue,
                status: "Assigned",
              };
              if (v.editors === undefined) v.editors = [];
              v.editors.push(e);
              v.status = 1;
            }
          });
          setItems(itms);
        }
        if (js.deleteTask) {
          let itms = [...items];
          itms.forEach((v) => {
            if (v.id === js.id) v.editors = [];
          });
          setItems(itms);
        }
        if (js.notes) setPKS("notesUpdate", js);
        if (js.tags) setPKS("tagsUpdate", js.tags);
        if (js.selected) setPKS("tagSelectionUpdate", js);
        if (js.update && !fetching) FetchSubmissions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const keepAlive = () => {
    if (t) clearTimeout(t);

    if (alive.current) {
      if (!(ws && ws.current && ws.current.readyState === WebSocket.OPEN)) {
        ws.current = new WebSocket(defaultWSS);
        setWSF();
        return;
      }

      if (ws && ws.current && ws.current.readyState === WebSocket.OPEN)
        ws.current.send(
          JSON.stringify({
            profile: lawReview ? lawReview : user.current.scholarsift.profile,
          })
        );

      setT(
        setTimeout(() => {
          keepAlive();
        }, 20000)
      );
    }
  };

  const setWSF = (onOpen, onClose, onMessage) => {
    ws.current.onopen = onOpen ? onOpen : ProcessOpen;
    ws.current.onclose = onClose ? onClose : ProcessClose;
    ws.current.onmessage = onMessage ? onMessage : ProcessMessage;
  };

  //Tag Effect
  useEffect(() => {
    setFetchingTags(false);
    if (
      !(
        ws &&
        ws.current &&
        (ws.current.readyState === WebSocket.OPEN ||
          ws.current.readyState === WebSocket.CONNECTING)
      )
    )
      ws.current = new WebSocket(defaultWSS);
    setWSF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, ws]);

  useEffect(() => {
    setChecked(columns.map((v, i) => omitted.indexOf(i) > -1));
  }, [omitted]);

  useEffect(() => {
    setChecks(
      columns
        .filter((v, i) => i !== 0)
        .map((v, i) => (
          <Checkbox
            className="submission-portal-column-item"
            label={v}
            key={uuid()}
            defaultChecked={checked[i]}
            onChange={(_, checkd) => {
              checked[i] = checkd;
            }}
          />
        ))
    );
  }, [checked]);

  const removeColumn = (column) => {
    if (omitted.indexOf(column) === -1) {
      let om = [...omitted, column];
      let d = {
        op: "Update View",
        user: user.current.scholarsift.profile,
        omitted: om,
      };
      processWS(d);
      setOmitted(om);
    }
  };

  const updateOrder = (v) => {
    console.log(v);
    let d = {
      op: "Update View",
      user: user.current.scholarsift.profile,
      order: v,
    };
    processWS(d);
    setOrder(v);
  };

  const resetView = () => {
    let d = {
      op: "Update View",
      user: user.current.scholarsift.profile,
      order: [],
      omitted: [],
    };
    processWS(d);
    setOrder(undefined);
    setOmitted([]);
  };

  const processWS = (data) => {
    data.profile = lawReview ? lawReview : user.current.scholarsift.profile;
    if (window.location.hostname.indexOf("scholar") === -1) {
      console.log("WS - PROCESSING: ");
      console.log(data);
    }
    if (ws.current && ws.current.readyState === WebSocket.OPEN)
      ws.current.send(JSON.stringify(data));
    else {
      ws.current = new WebSocket(defaultWSS);
      setWSF(
        () => {
          if (window.location.host.indexOf("scholar") === -1)
            console.log("WS OPEN");
          ws.current.send(JSON.stringify(data));
        },
        undefined,
        undefined
      );
      keepAlive();
    }
  };

  const DoFetchSubmissions = () => {
    if(!fetching.current)
      FetchSubmissions();
  }

  if (
    fetching.current ||
    (editors === undefined && location.pathname.indexOf("todo") === -1)
  ) {
    return (
      <ViewPort>
        <div className="loading" style={{ height: h.current }}>
          <Loading msg="Loading" />
        </div>
      </ViewPort>
    );
  }

  return (
    <ViewPort>
      {
        !Debug() ?
          <div
            className="loading"
            style={{
              height: state.viewport.h - (state.viewport.f + state.viewport.m),
              width: "80%",
            }}
          >
            We're signing up Law Reviews now, and are excited to show you this
            feature in the near future.
          </div> :
          <>
            <div
              style={{
                minHeight: state.viewport.h - (state.viewport.m + state.viewport.f),
              }}
            >
              <SubmissionPortalPanel
                lawReview={lawReview}
                processWS={processWS}
                checkWS={keepAlive}
                users={editors}
                items={items}
                setTags={setTags}
                tags={tags}
                omitted={omitted}
                order={order}
                setOrder={updateOrder}
                resetView={resetView}
                removeColumn={removeColumn}
                selectColumns={() => {
                  setOpen(true);
                }}
                FetchSubmissions={DoFetchSubmissions}
              />
            </div>
            <Panel
              isLightDismiss
              isOpen={open}
              onDismiss={() => {
                let om = [];
                checked.forEach((v, i) => {
                  if (v) om.push(i);
                });
                setOmitted(om);
                setOpen(false);
              }}
              headerText={"Hidden Columns"}
              closeButtonAriaLabel="Close"
            >
              {checks}
            </Panel>
          </>
      }
    </ViewPort>
  );
};

export default SubmissionPortal;
