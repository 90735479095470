import { ONE_DAY_IN_MS } from "@azure/msal-common";
import { Toast } from "@fluentui/react-components";
import axios from "axios";
import { getPlanType } from "../components/Payment/Plans";
import creds from "../creds.json";
import { Debug } from "ss-lib";
import { APIErrorHandler } from "./APIErrorHandler";

const APIURL = "https://api.authorize.net/xml/v1/request.api";
//const TESTURL = "https://apitest.authorize.net/xml/v1/request.api";

let d = new Date();
const start = d.toISOString().split("T")[0];
const ONE_DAY = 1000 * 60 * 60 * 24;

//Req PROFILE
export const getAuthNetProfile = (
  user,
  saveUser,
  showToast,
  CheckPurchase,
  callBack,
  handleError
) => {
  if(Debug()) console.log("Getting AuthNet Profile");
  if(Debug()) console.log(user);
  if (
    user === undefined ||
    user.current === undefined ||
    user.current.scholarsift === undefined ||
    user.current.scholarsift.profile === undefined
  )
    return;

  const profReq = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      merchantCustomerId: user.current.scholarsift.profile,
      includeIssuerInfo: "true",
    },
  };

  axios
    //.post(TESTURL, profReq)
    .post(APIURL, profReq)
    .then((res) => {
      let u = { ...user.current };

      if(Debug()) console.log("AuthNet Profile Request Response");
      if(Debug()) console.log(res);
      if(Debug()) console.log(u);

      const result = res.data;
      const { profile, messages } = result;
      const { resultCode } = messages;
      showToast(res);
      if (resultCode === "Ok") u.scholarsift.authnetProfile = profile;

      if (u.scholarsift.authnetProfile && CheckPurchase)
        CheckPurchases({ current: u }, saveUser, callBack);
      else checkSubscription({ current: u }, saveUser, callBack);
    })
    .catch((err) => {
      if(Debug()) console.log(err);
      if (handleError)
        handleError(err, user, saveUser, showToast, callBack, handleError);
    });
};

const completeQuery = (user, saveUser, callBack, data) => {
  if (saveUser) saveUser(user.current ? { ...user.current } : user);

  if (callBack) callBack(data);
};

export const checkSubscription = (user, saveUser, callBack) => {
  if(Debug()) console.log("Checking Subs");
  if(Debug()) console.log(user);
  if (
    !user ||
    !user.current ||
    !user.current.scholarsift ||
    !user.current.scholarsift.subscriptionId
  ) {
    if(Debug()) console.log("No Subscription Found");
    completeQuery(user, saveUser, callBack, user.current);
    return;
  }

  const req = {
    ARBGetSubscriptionStatusRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      subscriptionId: user.current.scholarsift.subscriptionId,
    },
  };
  axios.req
    .post(APIURL, req)
    .then((res) => {
      const { data } = res;
      let u = { ...user.current };
      if (data.status === "canceled") {
        let x = u.scholarsift.subscription.indexOf("(Auto");
        if (x > 1)
          u.scholarsift.subscription = u.scholarsift.subscription
            .substring(0, x)
            .trim();
        delete u.scholarsift.subscrptionId;
      }
      callBack(data);
      //completeQuery(u, saveUser, callBack, u);
    })
    .catch((err) => {
      console.error(err);
    });
};

const CheckTransactions = (transactions, user) => {
  if(Debug()) console.log("Checking Transactions");

  for (var i = 0; i < transactions.length; i++) {
    //Get Most Recent Transaction
    const { settleAmount, submitTimeUTC, subscription, transactionStatus } =
      transactions[i];

    if (transactionStatus === "voided") {
      if(Debug()) console.log("Found Voided Transaction");
      if(Debug()) console.log(transactions[i]);
      continue;
    }

    if (subscription && subscription.id) {
      if(Debug()) console.log("Check Subscription Status");
    }

    const subDate = new Date(submitTimeUTC).getTime();
    const currDate = new Date().getTime();
    let delta = 0;
    let paid = 0;

    //Needs Test For Renewal - Don't have one yet
    let subName = "";
    if (settleAmount === 9.95 || settleAmount === 1) {
      subName = "48 Hour Plan";
      delta = 2;
      paid = 1;
    } else if (
      (settleAmount >= 19.95 && settleAmount <= 24.95) ||
      (settleAmount >= 2 && settleAmount <= 3)
    ) {
      subName = "Monthly Plan";
      delta = 30;
      paid = 2;
    } else if (
      settleAmount >= 189.95 ||
      (settleAmount >= 5 && settleAmount <= 6)
    ) {
      subName = "Yearly Plan";
      delta = 365;
      paid = 3;
    }
    let expTime = subDate + delta * ONE_DAY;
    if(Debug()) console.log(expTime);
    if(Debug()) console.log(currDate);
    if (expTime >= currDate && expTime > user.current.scholarsift.expiration) {
      if (subName.length) user.current.scholarsift.subscription = subName;
      user.current.scholarsift.paid = paid;
      return paid;
    }
  }

  return 0;
};

export const CheckPurchases = (user, saveUser, callBack) => {
  if(Debug()) console.log("Checking Purchases User:");
  if(Debug()) console.log(user);

  const profTransReq = {
    getTransactionListForCustomerRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      customerProfileId:
        user.current.scholarsift.authnetProfile.customerProfileId,
      sorting: {
        orderBy: "submitTimeUTC",
        orderDescending: true,
      },
    },
  };

  let found = 0;

  axios
    .post(APIURL, profTransReq)
    //.post(TESTURL, profTransReq)
    .then((res) => {
      const { data } = res;
      const { totalNumInResultSet, transactions } = data;

      user.current.scholarsift.authnetProfile.description = "empty";

      if (totalNumInResultSet) {
        if(Debug()) console.log("Check Purchases Response:");
        if(Debug()) console.log(data);
        found = CheckTransactions(transactions, user);
      }

      if(Debug()) console.log("Found: " + found);

      if (!found) {
        const unsettledReq = {
          getUnsettledTransactionListRequest: {
            merchantAuthentication: {
              name: creds.authorize.apiLogin,
              transactionKey: creds.authorize.transactionKey,
            },
            sorting: {
              orderBy: "submitTimeUTC",
              orderDescending: true,
            },
          },
        };

        axios
          .post(APIURL, unsettledReq)
          //.post(TESTURL, unsettledReq)
          .then((res) => {
            const data = res.data;

            if(Debug()) console.log("Unsettled:");
            if(Debug()) console.log(data);

            if (data.totalNumInResultSet > 0) {
              let trans = data.transactions.filter(
                (v) =>
                  v.invoiceNumber &&
                  v.invoiceNumber.indexOf(user.current.scholarsift.profile) > -1
              );
              found = CheckTransactions(trans, user);
            }

            if(Debug()) console.log("Found2: " + found);
          })
          .catch((err) => {
            if(Debug())console.log(err);
          });
      } else checkSubscription(user, saveUser, callBack);
    })
    .catch((err) => {
      if(Debug())console.log(err);
    });
};

export const createTransaction = (
  user,
  saveUser,
  plan,
  renew,
  formData,
  opaqueData,
  dispatchToast,
  callBack
) => {
  Debug("Create Transaction Flow");

  const {
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zip,
    country,
    amount,
  } = formData;

  let profileObj = { createProfile: true };
  if (user.current.scholarsift.authnetProfile) profileObj.createProfile = false;

  Debug(profileObj);

  const scholarSiftId = user.current.scholarsift.profile;
  //let planObj = planObjects.filter((v) => v.id === plan)[0];

  Debug(plan);
  console.log(plan);

  const createTransactionRequest = {
    createTransactionRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      transactionRequest: {
        transactionType: "authCaptureTransaction",
        amount: amount,
        payment: {
          opaqueData,
        },
        profile: profileObj,
        order: {
          invoiceNumber:
            new Date().getTime() + "_" + scholarSiftId + "_" + plan.paid,
        },
        lineItems: {
          lineItem: {
            itemId: 1,
            name: plan.name,
            description:
              plan.name +
              " " +
              getPlanType(user) +
              (renew ? " (Auto Renew)" : ""),
            quantity: 1,
            unitPrice: amount,
          },
        },
        customer: {
          id: user.current.scholarsift.profile,
          email: email,
        },
        billTo: {
          firstName: firstName,
          lastName: lastName,
          address: address,
          city: city,
          state: state,
          zip: zip,
          country: country,
        },
        transactionSettings: {
          setting: {
            settingName: "testRequest",
            settingValue: "false",
          },
        },
        authorizationIndicatorType: {
          authorizationIndicator: "final",
        },
      },
    },
  };

  Debug("Posting to AN");
  Debug(createTransactionRequest);

  axios
    //.post(TESTURL, createTransactionRequest)
    .post(APIURL, createTransactionRequest)
    .then((res) => {
      Debug(res);
      const result = res.data;
      const { messages } = result;

      Debug(result);

      if (messages && messages.resultCode === "Error") {
        Debug("Errors: ");
        Debug(result.messages);
        if (dispatchToast) {
          const msg = `Error: ${result.messages.message[0].text}`;
          let intent = "error"
          return dispatchToast(<Toast>{msg}</Toast>, {intent});
        }

        return;
      }

      let u = {};
      u = { ...user.current };
      u.scholarsift.subscription = plan.name;
      u.scholarsift.paid = plan.paid;
      u.scholarsift.expiration =
        new Date().getTime() + ONE_DAY_IN_MS * plan.length;

      Debug("Saving User");
      saveUser(u);

      if (renew) {
        Debug("Creating Subscription From Profile");
        createSubFromProfile({ current: u }, saveUser, plan, amount, dispatchToast);
        if (callBack) return callBack();
        return;
      }

      Debug("Reporting Sale");
      reportSale(
        { current: u },
        saveUser,
        { ...plan, results: result },
        callBack
      );

      if (callBack) return callBack();
    })
    .catch((err) => {
      console.error(err);
      let intent = "error"
      dispatchToast(<Toast>{"Error: " + err}</Toast>, {intent});
    });
};

const reportSale = (user, saveUser, plan, callBack, fatalError) => {
  Debug("Reporting Sale - AXIOS");
  axios
    .post("/api/sale", plan, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((response) => {
      Debug("Sale Response: ");
      Debug(response.data);
      console.log("Success!");

      //if (callBack) callBack(response.data);
    })
    .catch((error) =>
      APIErrorHandler(error, (v) =>
        v
          ? fatalError(v)
          : reportSale(user, saveUser, plan, callBack, fatalError)
      )
    );
};

export const updateProfile = (user, showToast) => {
  Debug("Updating Profile Funct");
  const profileReq = {
    updateCustomerProfileRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      profile: {
        merchantCustomerId: user.scholarsift.profile,
        description: user.scholarsift.planId,
        email: user.scholarsift.email,
        customerProfileId: user.scholarsift.authnetProfile.customerProfileId,
      },
    },
  };

  Debug("UP AXIOS");
  axios
    //.post(TESTURL, profileReq)
    .post(APIURL, profileReq)
    .then((res) => {
      const result = res.data;
      Debug(result);
      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        showToast
      ) {
        const msg = `Error: ${result.messages.message[0].text}`;
        showToast(msg, "error");
        console.log(msg);
        return;
      }
    })
    .catch((err) => console.error(err));
};

//create subscription from customer profile
export const createSubFromProfile = (
  user,
  saveUser,
  plan,
  amount,
  dispatchToast,
  callBack
) => {
  Debug("Create Sub From Profile");
  Debug(plan);

  /**
   * We need to update the start date to be the day the subscription expires
   */

  const customerProfileId =
    user.current.scholarsift.authnetProfile.customerProfileId;
  const customerPaymentProfileId =
    user.current.scholarsift.authnetProfile.paymentProfiles[0]
      .customerPaymentProfileId;

  const startDate =
    user.current.scholarsift.expiration > new Date().getTime()
      ? new Date(user.current.scholarsift.expiration)
          .toISOString()
          .split("T")[0]
      : start;
  const req = {
    ARBCreateSubscriptionRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      subscription: {
        name: plan.name,
        paymentSchedule: {
          interval: {
            length: plan.length,
            unit: "days",
          },
          startDate: startDate,
          totalOccurrences: 999,
        },
        amount: amount,
        order: {
          invoiceNumber:
            "_" + user.current.scholarsift.profile + "_" + plan.length + "S",
          description: plan.name,
        },
        profile: {
          customerProfileId: customerProfileId,
          customerPaymentProfileId: customerPaymentProfileId,
        },
      },
    },
  };

  Debug("Axios");
  if (customerProfileId && customerPaymentProfileId) {
    axios
      .post(APIURL, req)
      .then((res) => {
        const result = res.data;

        Debug(result);

        if (
          result.messages &&
          result.messages.resultCode === "Error" &&
          dispatchToast
        ) {
          const msg = `Error: ${result.messages.message[0].text}`;
          let intent = "error";
          dispatchToast(<Toast>msg</Toast>, {intent});
          return;
        }

        //Update User
        let u = { ...user.current };
        u.scholarsift.expiration = new Date().getTime() + ONE_DAY_IN_MS * 30; //This may or may not be accurate but all that matters is that the current state is valid, login will fix w actual expiration
        u.scholarsift.subscription = plan.name;

        reportSale({ current: u }, saveUser, { ...plan, results: result });

        saveUser(u);

        if (callBack) callBack(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export const getAllSubscriptions = (showToast, item, row, callBack) => {
  let req = {
    ARBGetSubscriptionListRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      searchType: "subscriptionActive",
      sorting: {
        orderBy: "createTimeStampUTC",
        orderDescending: "false",
      },
      paging: {
        limit: "1000",
        offset: "1",
      },
    },
  };

  axios
    .post(APIURL, req)
    .then((res) => {
      const result = res;

      if (window.location.host.indexOf("scholarsift") === -1)
        console.log(result);

      callBack(item, row, result);
    })
    .catch((err) => console.error(err));
};

export const updateSubscription = (
  user,
  saveUser,
  plan,
  amount,
  showToast,
  callBack
) => {
  //Find Subscription
  const { authnetProfile } = user.current.scholarsift;
  const subscriptionId = authnetProfile.subscriptionId
    ? authnetProfile.subscriptionIds[0]
    : authnetProfile.subscriptionId;

  if (subscriptionId === undefined) {
    //Try to look up
    let req = {
      ARBGetSubscriptionListRequest: {
        merchantAuthentication: {
          name: creds.authorize.apiLogin,
          transactionKey: creds.authorize.transactionKey,
        },
        refId: "123456",
        searchType: "subscriptionActive",
        sorting: {
          orderBy: "createTimeStampUTC",
          orderDescending: "false",
        },
        paging: {
          limit: "1000",
          offset: "1",
        },
      },
    };

    Debug("Getting Subscriptions:");
    axios
      .post(APIURL, req)
      .then((res) => {
        const { data } = res;
        const { totalNumInResultSet, subscriptionDetails } = data;
        Debug("Got Subscriptions:");
        Debug(data);
        if (totalNumInResultSet === 0)
          createSubFromProfile(
            user,
            saveUser,
            plan,
            amount,
            showToast,
            callBack
          );
        else {
          var subs = subscriptionDetails.find(
            (v) =>
              v.customerProfileId ===
              user.current.scholarsift.authnetProfile.customerProfileId
          );
          if (subs) {
            Debug("Found Subscription, Updating");
            Debug(subs);
            doUpdateSubscription(
              user,
              saveUser,
              plan,
              subs,
              showToast,
              callBack
            );
          } else {
            Debug("Subscription not found, creating from profile");
            createSubFromProfile(
              user,
              saveUser,
              plan,
              amount,
              showToast,
              callBack
            );
          }
        }
      })
      .catch((err) => {
        Debug(err);
      });
  } else doUpdateSubscription(user, saveUser, plan, subscriptionId, showToast);
};

const doUpdateSubscription = (
  user,
  saveUser,
  plan,
  subscriptionId,
  showToast,
  callBack
) => {
  const startDate =
    user.current.scholarsift.expiration > new Date().getTime()
      ? new Date(user.current.scholarsift.expiration)
          .toISOString()
          .split("T")[0]
      : start;
  const putReq = {
    ARBUpdateSubscriptionRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      subscriptionId: subscriptionId,
      subscription: {
        name: plan.name,
        paymentSchedule: {
          startDate: startDate,
          totalOccurrences: 999,
        },
      },
    },
  };

  Debug("Update Subscription");
  Debug(plan);
  Debug(user.current.authnetProfile);
  Debug(putReq);

  axios
    .post(APIURL, putReq)
    //.post(TESTURL, putReq)
    .then((res) => {
      const result = res.data;
      Debug("Update Subscription Result");
      Debug(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        showToast
      ) {
        const msg = `Error: ${result.messages.message[0].text}`;
        showToast(msg, "error");
        return;
      }

      reportSale(user, saveUser, { ...plan, results: result }, (v) => {
        Debug(v);
      });

      let u = { ...user.current };
      u.scholarsift.subsription = plan.name;
      u.scholarsift.paid = plan.paid;
      u.scholarsift.expiration =
        new Date().getTime() + ONE_DAY_IN_MS * plan.length;

      saveUser(u);

      if (callBack) callBack(result);
    })
    .catch((err) => {
      console.error(err);
    });
};

//Update Profile
export const updatePaymentProfile = (user, planId, formData, showToast) => {
  const { firstName, lastName, address, city, state, zip, country } = formData;

  const updateReq = {
    updateCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      customerProfileId: user.current.scholarsift.customerProfileId,
      paymentProfile: {
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        state: state,
        zip: zip,
        country: country,
      },
      customerPaymentProfileId:
        user.current.scholarsift.customerPaymentProfileId,
    },
  };

  axios
    // .put(TESTURL, updateReq)
    //.put(APIURL, updateReq)
    .then((res) => {
      const result = res.data;

      if (window.location.host.indexOf("scholarsift") === -1) {
        console.log(result);
      }

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        showToast
      ) {
        const msg = `Error: ${result.messages.message[0].text}`;
        showToast(msg, "error");
        return;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

//Cancel subscription
export const cancelSubscription = (user, saveUser, dispatchToast, callBack) => {
  Debug("Cancel Request");

  if (user.current.scholarsift.subscriptionId === undefined) return;

  const cancelReq = {
    ARBCancelSubscriptionRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      subscriptionId: user.current.scholarsift.subscriptionId,
    },
  };

  axios
    .post(APIURL, cancelReq)
    //.post(TESTURL, cancelReq)
    .then((res) => {
      const result = res.data;
      Debug("Cancel Request Result");
      Debug(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        dispatchToast
      ) {
        const msg = `Error: ${result.messages.message[0].text}`;
        let intent = "error";
        dispatchToast(<Toast>{msg}</Toast>, { intent });
        return;
      }

      reportSale(user, saveUser, {
        cancel: user.current.scholarsift.subscriptionId,
      });

      if (callBack) callBack(result);
    })
    .catch((err) => {
      console.log(err);
    });
};

//Delete payment profile
export const deleteAuthNetProfile = (
  id,
  profileId,
  subscriptionId,
  cancelSubscription,
  showToast
) => {
  const deleteReq = {
    deleteCustomerProfileRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      clientId: id,
      customerProfileId: profileId,
    },
  };

  axios
    .post(APIURL, deleteReq)
    //.post(TESTURL, deleteReq)
    .then((res) => {
      const result = res.data;

      if (window.location.host.indexOf("scholarsift") === -1)
        console.log(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        showToast
      ) {
        const msg = "Error: " + result.messages.message[0].text;
        showToast(msg, "error");
        return;
      }

      console.log("Go to cancel");
      cancelSubscription(subscriptionId, getAuthNetProfile, showToast);
    })
    .catch((err) => {
      console.error(err);
    });
};

/* Testing */
export const getMerchantDetailsRequest = (item, row, callBack) => {
  const req = {
    getMerchantDetailsRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
    },
  };

  axios
    .post(APIURL, req)
    .then((res) => {
      const result = res.data;

      if (window.location.host.indexOf("scholarsift") === -1)
        console.log(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" /* &&
        showToast */
      ) {
        console.log(result.messages.message[0].code);
        const msg = "Error: " + result.messages.message[0].text;
        //showToast(msg, "error");
        callBack(item, row, result);
      }

      if (result.messages && result.messages.resultCode === "Ok") {
        const msg = `Success: ${
          result.messages.message[0].code
        }; Production Mode: ${result.isTestMode ? "Testing Only" : "Live"}`;

        // showToast(msg, "info");
        callBack(item, row, result);
      }

      return;
    })
    .catch((err) => {
      console.error(err);
      //showToast(err, "error");
    });
};

export const validatePaymentProfile = (item, row, callBack) => {
  const req = {
    getTransactionListForCustomerRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      customerProfileId: 646314307,
      customerPaymentProfileId: 647930697,
      sorting: {
        orderBy: "submitTimeUTC",
        orderDescending: false,
      },
      paging: {
        limit: 100,
        offset: 1,
      },
    },
  };

  axios
    .post(APIURL, req)
    .then((res) => {
      const result = res.data;

      if (window.location.host.indexOf("scholarsift") === -1)
        console.log(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        callBack
      ) {
        console.log(result.messages.message[0].code);
        return callBack(item, row, result, "Customer Payment Profiles");
      }

      callBack(item, row, result, "Customer Payment Profiles");
    })
    .catch((err) => {
      console.error(err);
    });
};

export const validateSubscriptions = (item, row, callBack) => {
  const req = {
    ARBGetSubscriptionListRequest: {
      merchantAuthentication: {
        name: creds.authorize.apiLogin,
        transactionKey: creds.authorize.transactionKey,
      },
      refId: "123456",
      searchType: "subscriptionActive",
      sorting: {
        orderBy: "id",
        orderDescending: false,
      },
      paging: {
        limit: 100,
        offset: 1,
      },
    },
  };

  axios
    .post(APIURL, req)
    .then((res) => {
      const result = res.data;

      if (window.location.host.indexOf("scholarsift") === -1)
        console.log(result);

      if (
        result.messages &&
        result.messages.resultCode === "Error" &&
        callBack
      ) {
        console.log(result.messages.message[0].code);
        return callBack(item, row, result, "Subscriptions");
      }

      callBack(item, row, result, "Subscriptions");
    })
    .catch((err) => {
      console.error(err);
    });
};
