import { useState } from "react";
import {
  CheckboxVisibility,
  DetailsList,
  Dropdown,
  FocusZone,
  FocusZoneDirection,
  SelectionMode,
  TextField,
} from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";

const ops = [
  { key: 0, text: "Basic User" },
  { key: 1, text: "Librarian/Group Admin" },
];

const onRenderItemColumn = (item, _, column) => {
  switch (column.key) {
    case "role":
      return (
        item.role !== undefined && (
          <Dropdown
            className="list-item"
            selectedKey={item.role}
            onChange={(ev, option, index) => {
              console.log("udpate... " + index);
              item.role = index;
            }}
            options={ops}
          />
        )
      );
    case "id":
      return item.id && <div className="list-item">{item[column.key]}</div>;
    default:
      return <div className="list-item">{item[column.key]}</div>;
  }
};

const GroupUserList = (props) => {
  const onColumnClick = (ev, column) => {
    let curr = column;
    let cols = [...columns];

    cols.forEach((c) => {
      if (c.key === column.key) {
        c.isSortedDescending = !c.isSortedDescending;
        c.isSorted = true;
        curr = c;
      } else {
        c.isSorted = false;
        c.isSortedDescending = true;
      }
    });

    setItems(
      items.slice(0).sort((a, b) => {
        //Descending
        if (curr.isSortedDescending) {
          if (a.id) {
            if (b.id) return a[curr.key] < b[curr.key] ? 1 : -1;
            return 1;
          }
          return b.id ? -1 : 0;
        }

        //Ascending
        if (a.id) {
          if (b.id) return a[curr.key] < b[curr.key] ? -1 : 1;
          return -1;
        }
        return b.id ? 1 : 0;
      })
    );
    setColumns(cols);
  };

  const originalItems = useConst([...props.items]);
  const [columns, setColumns] = useState([
    {
      key: "Name",
      name: "Name",
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
    {
      key: "Email",
      name: "Email",
      minWidth: 400,
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
    {
      key: "role",
      name: "Account Type",
      isSortedDescending: true,
      minWidth: 200,
      onColumnClick: onColumnClick,
    },
    {
      key: "id",
      name: "User ID",
      minWidth: 100,
      isSortedDescending: true,
      onColumnClick: onColumnClick,
    },
  ]);

  const [items, setItems] = useState(
    originalItems.sort((a, b) => {
      //Descending
      if (!columns[0].isSortedDescending) {
        if (a.id) {
          if (b.id) return a[columns[0].key] < b[columns[0].key] ? 1 : -1;
          return 1;
        }
        return b.id ? -1 : 0;
      }

      //Ascending
      if (a.id) {
        if (b.id) return a[columns[0].key] < b[columns[0].key] ? -1 : 1;
        return -1;
      }
      return b.id ? 1 : 0;
    })
  );

  if (originalItems.length === 0) return null;

  const resultCountText =
    items.length === originalItems.length
      ? ""
      : ` (${items.length} of ${originalItems.length} shown)`;

  const onFilterChanged = (_, text) => {
    setItems(
      originalItems.filter(
        (item) => item.email.toLowerCase().indexOf(text.toLowerCase()) >= 0
      )
    );
  };

  return (
    <>
      <div className="display-header">
        <div className="display-name">Group Users</div>
      </div>
      <FocusZone direction={FocusZoneDirection.vertical}>
        <div className="active-user-selector-filter-w-button">
          <TextField
            className="active-user-selector-filter-input"
            label={"Filter" + resultCountText}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onFilterChanged}
          />
          <div
            className="active-user-selector-refresh"
            onClick={props.UpdateList}
          >
            Refresh
          </div>
        </div>
        <DetailsList
          items={items}
          columns={columns}
          onRenderItemColumn={onRenderItemColumn}
          checkboxVisibility={CheckboxVisibility.always}
          selectionMode={SelectionMode.single}
        />
      </FocusZone>
    </>
  );
};

export default GroupUserList;
