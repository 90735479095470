import React from "react";

export const LocationStateContext = React.createContext();

const AnalyzeUncheckedSets = () => ({
  "Journal" : new Set(),
  "Book" : new Set(),
  "Case" : new Set()
})

const AnalyzeFilterColumns = () => ([
  {
      key: "year",
      name: "Year", 
      type: "range",
      isSortedDescending: false,
      isSorted: false
  }
])

export const defaultAnalyzeState = {
  results : {},
  text: "",
  last: undefined,
  info: undefined,
  warn: undefined,
  file: undefined,
  tab: "Journal",
  filters: AnalyzeFilterColumns(),
  unchecked: AnalyzeUncheckedSets(),
  uncheckedGroups: {},
  textFilter: "",
  wc: [],
  wcmode: false,
  wcwords: []
}

const defaultSignInState = {	
  showTwitter: true,
  loginError: null,
  panel: -1
}

const defaultMessageState = {
  info: undefined,
  warn: undefined,
  error: undefined
}

const defaultNodeState = {
  cancel: undefined,
  nodes: undefined,
  searchText: undefined,
  nodeList: undefined
}

const defaultPaperState = {
  cv: undefined,
  papers: undefined,
  newItems: undefined
}

const defaultUserState = {
  current: undefined,
  list: undefined,
  filter: undefined
}

const defaultPortalList = {
  notesUpdate: undefined,
  tagsUpdate: undefined,
  tagSelectionUpdate: undefined
}

const defaultUserSubmissionState = {
  items: undefined
}

export const defaultProfileState = {
  name: undefined,
  title: undefined,
  roles: undefined,
  institutions: undefined,
  categories: undefined,
  cv: undefined,
  faculty: undefined,
  genders: undefined,
  orientation:undefined,
  hispanic:undefined,
  nothispanic:undefined,
  hvalues:undefined,
  black: undefined,
  native: undefined,
  asian:undefined,
  islander: undefined,
  seana: undefined,
  white: undefined,
  tenure: undefined,
  opportunities: undefined,
  geogs: undefined
}

const defaultLocationState = () => {
    let j = {...defaultMessageState};
    j["viewport"] = { h: 0, m: 0, f: 0, v: 0, o: 0}
    j["/"] = {...defaultAnalyzeState};
    j["/welcome"] = {...defaultAnalyzeState};
    j["/nodes"] = {...defaultNodeState};
    j["/analyze"] = {...defaultAnalyzeState};
    j["/analyze/:id"] = {...defaultAnalyzeState};
    j["/signin"] =  {...defaultSignInState};
    j["/dashboard/accounts"] = {...defaultUserState};
    j["/papers"] = {...defaultPaperState};
    j["/manage/papers"] = {...defaultPaperState, ...defaultUserState};
    j["/manage/users"] = {...defaultProfileState, ...defaultUserState};
    j["/manage/submit"] = {...defaultPaperState, ...defaultUserState};
    j["/manage/submissions"] = {...defaultProfileState, ...defaultUserState};
    j["/profile"] = {...defaultProfileState, ...defaultUserState};
    j['/portal/list'] = {...defaultPortalList};
    j["/portal/editors"] = {ep: undefined, ...defaultPaperState, ...defaultUserState};
    j["/todo"] = {...defaultPortalList};
    j["/submissions"] = {...defaultUserSubmissionState};
    return j;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'path-key-update':
      return {
        ...state,
        [action.path] : { ...state[action.path], [action.key] : action.payload}
      }
    case "key-update":
      return {
        ...state,
        [action.key]: {...action.payload}
      };
    case 'message-update' :
      return {
        ...state,
        [action.key] : action.payload
      }
    case "reset":
    {
      let vp = {...state.viewport}
      let d = defaultLocationState();
      return {...d, viewport: {...vp}}
    }  
    default:
      throw new Error();
  }
};

export const LocationStateProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, {}, defaultLocationState);
  
  return (
    <LocationStateContext.Provider value={{state, dispatch}}>
      {props.children}
    </LocationStateContext.Provider>
  );
};

export const LocationStateConsumer = LocationStateContext.Consumer;
 
export default LocationStateContext;