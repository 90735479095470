import { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import useLocationState from '../../hooks/useLocationState';
import axios from 'axios';

const EmailVerifier = props => {
    let { id } = useParams();
    const[email, setEmail] = useState();
    const { dispatch } = useLocationState();

    useEffect(() => {
        axios
               .post("/api/emailverification", {email:id})
               .then(response => {
                    dispatch({type:'message-update', key: "error", payload: undefined});
                    setEmail(response.data.email);
                })
                .catch(err => {
                    let dec = decodeURIComponent(id);
                    dispatch({type:'message-update', key: "error", payload: "Could not verify " + Buffer.from(dec, 'base64')});
                });
    })

    if(!email)
        return <div className="display-panel"><div className="msg-wrapper"><div>Verifying account information</div></div></div>;
    
    return  <Navigate to={{
                            pathname: "/signin",
                            state: {email: email}
                        }}
            />;
}

export default EmailVerifier;