import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewPort from '../Layout/ViewPort';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import { ItemSEO } from '../NavBar/ItemSEO';
import { APIErrorHandler } from '../../actions/APIErrorHandler';
import { Debug, Preprint, useProfile } from 'ss-lib';
import axios from 'axios';

const WEEK = 1000 * 60 * 60 * 24 * 7;

const RepositoryPaper = props => {
    const navigate = useNavigate();
    const { name, pid, downloads, likes } = useProfile();
    const [lip, setLIP] = useState(false);
    const [lookup, setLookup] = useState(false);
    const [data, setData] = useState({});
    const [notFound, setNotFound] = useState(false);
    const { allowRoute, user, saveUser, setTitle } = useUser();
    const { state } = useLocationState();
    const { id } = useParams();

    const GetPaper = useCallback(async (v) => {
      if(lookup)
        return ;
      setLookup(true);
        axios
            .get("/api/paper/" + v, {
                headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
                },
            })
            .then((results) => {
                if(Debug())
                    console.log(results)
                if(results.data.item === undefined) {
                  setNotFound(true);
                  return ;
                }
                setData(results.data);
                var t = results.data.item.title;
                var parts = t.split(" ");
                window.title = t;
                setTitle(parts.length > 4 ? t.substr(0, t.indexOf(" " + parts[3] + " ")) + "...": t);
                setLookup(false);
            })
            .catch((err) => {
                if(Debug())
                    console.log(err);
                if(err && err.response && err.response.status === 404)
                  setNotFound(true);
                APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                  setLookup(false);
                  GetPaper();
                }
                );
            });
    },  [user, saveUser])

    useEffect(() => {
      if(Debug()) {
        if(!data) {
          console.log("No Data")
        }
        else if(!data.item) 
          console.log("No Item");
        else if(data.item.n !== +id)
          console.log("No Id")
      }
      if((!data || !data.item || data.item.n !== +id))
        GetPaper(id);
    }, [GetPaper, data, id]);
    
    const doDownload = () => {
      //Download Check
      if(!downloads[id] || downloads[id] + WEEK > Date.now()) {
        //If passes update user & count on screen 
        saveUser({...user.current, scholarsift: {...user.current.scholarsift, public: {...user.current.scholarsift.public, downloads: {...downloads, id: Date.now()}}}});
        setData({...data, stats:{...data.stats, d: (data.stats.d ? data.stats.d : 0) + 1}});
      }

      //Download File
      var a = document.createElement("a");
      a.href = "/api/download/" + id + "." + pid;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      a.click();
    }

    const toggleLike = () => {
      if(lip)
        return ;
      
      setLIP(true);

      //Unlike
      if(likes.indexOf(+id) > -1) {
        saveUser({...user.current, scholarsift: {...user.current.scholarsift, public: {...user.current.scholarsift.public, likes: likes.toSpliced(likes.indexOf(+id), 1)}}});
        setData({...data, stats:{...data.stats, l: data.stats.l - 1}});
        axios
            .get("/api/unlike/" + id, {
                headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
                },
            })
            .then((results) => {
              setLIP(false);
            })
            .catch((err) => {
                if(Debug())
                    console.log(err);
                if(err.status === 400)
                {
                  setLIP(false);
                  return ;
                }
                APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                    setLIP(false);
                    toggleLike();
                }
                );
            });
      }
      else {
        saveUser({...user.current, scholarsift: {...user.current.scholarsift, public: {...user.current.scholarsift.public, likes: [...likes, +id]}}});
        setData({...data, stats:{...data.stats, l: (data.stats.l ? data.stats.l : 0) + 1}});
        axios
            .get("/api/like/" + id, {
                headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
                },
            })
            .then((results) => {
              setLIP(false);
            })
            .catch((err) => {
                if(Debug())
                    console.log(err);
                if(err.status === 400) {
                  setLIP(false);
                  return;
                }
                APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                    setLIP(false);
                    toggleLike();
                }
                );
            });
      }
    }

    const analyze = () => {
      if(data && data.item)
        navigate("/analyze/" + data.item.n, {state:{title:data.item.title}});
    }

    const addComment = (v, callBack) => {
      if(Debug()) {
        console.log(v)
        console.log(callBack)
      }
      axios
      .post("/api/comment/", {...v, name: name, n: +id}, {
          headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
          },
      })
      .then((res) => {
        if(callBack)
          callBack();
        setData({...data, comments: res.data, stats:{...data.stats, c: res.data.length}})
      })
      .catch((err) => {
          if(Debug())
              console.log(err);
          if(err.status === 400) {
            return;
          }
          APIErrorHandler(
          err,
          user,
          saveUser,
          () => {
          }
          );
      });
    }

    const submitOffer = (v) => {
      console.log("Send Offer: " + v);
    }

    let showOffer = data.item && data.item.pubStatus === "Preprint" && allowRoute("lr");
    if(showOffer) {
      if(data && data.item && data.item.avail && data.item.avail.toLowerCase().indexOf("index only") > -1)
        showOffer = false;
    }
    
    return  <ViewPort>
                {
                  notFound ? 
                  <div style={{textAlign:"center", paddingTop:"30vh", height:state.viewport.h - (state.viewport.m + state.viewport.f)}}><h1>Not found</h1></div> :
                  <>
                    <ItemSEO item={data.item} />
                    <Preprint analyze={analyze} offer={showOffer ? submitOffer : undefined} doDownload={doDownload} toggleLike={toggleLike} addComment={addComment} {...data} height={state.viewport.h - (state.viewport.m + state.viewport.f + 48)} /> 
                  </>
                }
            </ViewPort>
}

export default RepositoryPaper;