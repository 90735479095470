import { useEffect, useRef, useState } from "react";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  PrimaryButton,
  SelectionMode,
  TooltipHost,
} from "@fluentui/react";
import ListFilter from "./ListFilter";
import useLocationState from "../../hooks/useLocationState";
import { getListStyle } from "../../utils";

const ScrollingFilterableList = (props) => {
  const { state } = useLocationState();
  const [ListStyle, setListStyle] = useState(
    getListStyle(
      state.viewport.h -
        (state.viewport.f + state.viewport.m + state.viewport.o)
    )
  );
  const hr = useRef();
  useEffect(() => {
    console.log(state.viewport);
    setListStyle(
      getListStyle(
        state.viewport.h -
          (state.viewport.m + state.viewport.f + state.viewport.o)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hr.current, state.viewport]);

  const onRenderDetailsHeader = (p, defaultRender) => {
    if (!props || !defaultRender) return null;

    const onRenderColumnHeaderTooltip = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />

    return (
      <div ref={hr}>
        <div className="floating-filter">
          {((props.omitted && props.omitted.length > 0) ||
            (props.order && props.order.length > 0) ||
            props.selectionCount > 1) && (
            <div className="submission-portal-controls">
              {props.omitted && props.omitted.length > 0 && (
                <PrimaryButton
                  className="portal-column-select"
                  text="Select Hidden Columns"
                  onClick={props.selectColumns}
                />
              )}
              {((props.omitted && props.omitted.length > 0) ||
                (props.order && props.order.length > 0)) && (
                <PrimaryButton
                  className="portal-column-select"
                  text="Reset View"
                  onClick={props.resetView}
                />
              )}
              {props.selectionCount > 1 && (
                <PrimaryButton
                  className="portal-column-select"
                  text="Change Selection Status"
                  onClick={() => {
                    props.processStatus();
                  }}
                />
              )}
            </div>
          )}
        </div>
        {defaultRender({...p, onRenderColumnHeaderTooltip })}
      </div>
    );
  };

  const getDragDropEvents = () => {
    return {
      canDrop: () => true,
      canDrag: () => true,
      onDragEnter: (item, event) => { console.log(event); return ; },
      onDragLeave: (item, event) => { console.log(event); return ; },
      onDrop: (item, event) => {
        console.log(event)
        console.log(item)
        if (this._draggedItem) {
          this._insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        console.log(event)
        console.log(item)
        this._draggedItem = item;
        this._draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        console.log(event)
        console.log(item)
        this._draggedItem = undefined;
        this._draggedIndex = -1;
      }
    }
  }

  const dragDropEvents = props.DnD ? getDragDropEvents() : undefined;

  return  <div className="user-submission-wrapper">
            <ListFilter
              onFilterChanged={props.onFilterChanged}
              resultCountText={props.resultCountText}
            />
            <DetailsList
              checkboxVisibility={CheckboxVisibility.hidden}
              selection={props.selection}
              items={props.items}
              columns={props.columns}
              columnReorderOptions={() => {}}
              onColumnHeaderClick={ props.onColumnClick ? props.onColumnClick : undefined }
              onRenderItemColumn={ props.onRenderItemColumn ? props.onRenderItemColumn : undefined }
              dragDropEvents={dragDropEvents}
              selectionMode={SelectionMode.multiple}
              selectionPreservedOnEmptyClick={true}
              detailsHeaderProps={{ paddingBottom: 10 }}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              onRenderDetailsHeader={ props.onRenderDetailsHeader ? props.onRenderDetailsHeader : onRenderDetailsHeader }
              styles={props.Styles ? props.Styles : ListStyle}
            />
        </div>
};

export default ScrollingFilterableList;
