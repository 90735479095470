import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Stack, ActionButton } from "@fluentui/react";
import Modal from "./Modal";
import { useSpring, config } from "react-spring";
import { v4 as uuid } from "uuid";
import useMeasure from "react-use-measure";
import useLocationState from "../../../hooks/useLocationState";
import DocxAsHtml from "../../NavBar/DocxAsHtml";
import Licenses from "../../NavBar/Licenses";

const footerLinks = [
  {
    file: <DocxAsHtml file="tos.docx" title="ScholarSift - Terms of Service" />,
    text: "Terms of Service",
    title: "ScholarSift - Terms of Service",
    url:"/terms"
  },
  {
    file: <DocxAsHtml file="cookies.docx" title="ScholarSift - Cookie Policy"/>,
    text: "Cookie Policy",
    title: "ScholarSift - Cookie Policy",
    url:"/cookies"
  },
  {
    file: <DocxAsHtml file="pp.docx" title="ScholarSift - Privacy Policy" />,
    text: "Privacy Policy",
    title: "ScholarSift - Privacy Policy",
    url: "/privacy"
  },
  {
    file: <DocxAsHtml file="security.docx" title="ScholarSift - Security Policy" />,
    text: "Security Policy",
    title: "ScholarSift - Security Policy",
    url: "/security"
  },
  {
    file: <Licenses />,
    text: "Third Party Notices",
    title: "ScholarSift - Third Party Notices",
    url: "/licenses"
  },
];

const Footer = () => {
  const location = useLocation();
  const { dispatch, state } = useLocationState();
  const [ref, { height }] = useMeasure();
  const [t, setT] = useState(0);
  const h = useRef();

  useEffect(() => {
    if (t !== state.viewport.m) setT(state.viewport.m);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport.m]);

  useEffect(() => {
    if (height !== h.current) {
      h.current = Math.ceil(height);
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "f",
        payload: h.current,
      });
    }

    return () => {
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "f",
        payload: 0,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  let item = footerLinks.filter(v => v.url === location.pathname);
  let link = item.length === 0 ? -1 : footerLinks.indexOf(item[0]);
  const [show, setShow] = useState(link > -1);
  const [doc, setDoc] = useState(link === -1 ? footerLinks[0] : footerLinks[link]);

  const closeModal = () => setShow(false);
  const showModal = (d) => {
    setDoc(d);
    setShow(true);
  };

  const spring = useSpring({
    opacity: show ? 1 : 0,
    height: show ? "100%" : "0%",
    config: config.gentle,
  });

  return (
    <>
      <div
        ref={ref}
        className="footer-wrapper"
        style={{
          top: t ? (window.innerHeight < state.viewport.h ? t - 1 : t) : t,
        }}
      >
        <Stack horizontalAlign="center" horizontal className="footer-bar">
          {footerLinks.map((item) => (
            <Fragment key={uuid()}>
              <Stack.Item className="footer-links">
                <ActionButton
                  onClick={() => showModal(item)}
                  text={item.text}
                  className="footer-redirect"
                />
              </Stack.Item>
            </Fragment>
          ))}
        </Stack>
      </div>
      <Modal isOpen={show} style={spring} closeModal={closeModal} item={doc} />
    </>
  );
};

export default Footer;
