import { useState } from "react";
import { Checkbox } from '@fluentui/react';
import { useLocation } from 'react-router';
import useLocationState from '../../../hooks/useLocationState';
import { v4 as uuid} from "uuid";

const ColumnLength = 15;

const getItems = (items, update) => {
    if(items === undefined)
        return [];
    let panels;
    if(items.length < ColumnLength)
        panels = [<div key={uuid()} className="selection-column">{items.map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>]
    else if(items.length < 2 * ColumnLength)
    {
        let half = Math.floor(items.length / 2);
        panels = [
                    <div key={uuid()} className="selection-column">{items.slice(0, half).map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>, 
                    <div key={uuid()} className="selection-column">{items.slice(half).map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>
                ]
    }    
    else {
        let third = Math.floor(items.length / 3);
        panels = [
                    <div key={uuid()} className="selection-column">{items.slice(0, third).map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>,
                    <div key={uuid()} className="selection-column">{items.slice(third, items.length - third).map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>,
                    <div key={uuid()} className="selection-column">{items.slice(items.length - third).map(v => <Checkbox key={uuid()} className="padded" checked={v.checked} label={v.name} onChange={(_, checked) => update(v, checked)} />)}</div>
                ]
    }
        
    return  <div key={uuid()} className="selection-panel">
                {panels}
            </div>
}

const SelectionPanel = props => {
    const location = useLocation();
    const { state, dispatch } = useLocationState();
    const [masterCheck, setMasterCheck] = useState(false);

    const update = (item, checked) => {
        let itms = state[location.pathname].items.map(v => v.name === item.name ? {...v, checked: checked } : v);
        dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: itms});
    }

    const ToggleCheck = checked => {
        dispatch({type:'path-key-update', path:  location.pathname , key: "items", payload: state[location.pathname].items.map(v => ({...v, checked: checked}))});
        setMasterCheck(checked);
    }

    return  <div className="selection-panel-wrapper">
                <div className="selection-panel">
                    {getItems(state[location.pathname].items, update)}
                </div>
                <div className="selection-master-check-wrapper">
                    <Checkbox checked={masterCheck} label={masterCheck ? "Uncheck All" : "Check All"} onChange={(_, checked) => { ToggleCheck(checked); }} />
                </div>
            </div>
}

export default SelectionPanel;