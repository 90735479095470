import React, { useEffect, useRef, useState } from "react";
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import creds from "../creds.json";
import { Debug, defaultProfile, useProfile } from "ss-lib";

const UserContext = React.createContext();

const defaultToken = {
  type: undefined,
  id: undefined,
  refresh: undefined,
};

const defaultScholarSiftInfo = {
  coda: undefined,
  email: undefined,
  start: undefined,
  isAdmin: undefined,
  name: undefined,
  paid: undefined,
  customerProfile: undefined,
  customerPaymentProfile: undefined,
  planId: undefined,
  profile: undefined,
  transId: undefined,
  subscriptionId: undefined,
  role: undefined,
  roles: undefined,
  source: undefined,
  zip: undefined,
  announcements: undefined,
};

const config = {
  auth: {
    clientId: creds.msftClientId,
    postLogoutRedirectUri: "https://" + window.location.host,
  },
};

export const defaultUser = {
  window: null,
  token: defaultToken,
  scholarsift: defaultScholarSiftInfo,
};

const extractUser = () => {
  let v = localStorage.getItem("scholarsift.user");
  if (v === "undefined") {
    localStorage.removeItem("scholarsift.user");
    return defaultUser;
  } else if (v === null) return defaultUser;

  let now = Date.now() / 1000;
  try {
    let u = JSON.parse(localStorage.getItem("scholarsift.user"));
    if (!u.token || !u.token.id) return defaultUser;

    let strs = u.token.id.split(".");
    let j = JSON.parse(atob(strs[1]));

    if (
      u.token.type === "Microsoft" ||
      u.token.type === "Google" ||
      u.token.type === "Local"
    ) {
      if (j.exp > now)
        return { ...u };
      localStorage.removeItem("scholarsift.user");
      return defaultUser;
    } else {
      //      console.log(u);
      //      console.log(j)
    }
  } catch (error) {
    console.log(error);
  }
  return defaultUser;
};

export const pca = new PublicClientApplication(config);
pca.initialize().then(()=> {
  if(Debug()) {
    console.log(pca.getActiveAccount())
    console.log(pca.getAllAccounts())
  }
});

//Axios Section
export const UserProvider = ({ children, user }) => {
  const [currentUser, setCurrentUser] = useState(user || extractUser());
  const [currentTitle, setCurrentTitle] = useState();
  const [warningDisplayed, setWarningDisplayed] = useState(false);
  const { setProfile } = useProfile();
  const userRef = useRef(currentUser);
  const titleRef = useRef();
  const wsRef = useRef();

  useEffect(() => {
    pca.addEventCallback((message) => {
      if(message.eventType === EventType.POPUP_OPENED)
        currentUser.window = message.payload.popupWindow;
    })

    if(Debug())
      console.log(currentUser)

    if(currentUser.scholarsift && currentUser.scholarsift.public)
      setProfile(currentUser.scholarsift.public);
    else
      setProfile(defaultProfile);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const setTitle = (v) => {
    titleRef.current = v;
    setCurrentTitle(v);
  };

  const saveUser = (values) => {
    if (values) {
      localStorage.setItem("scholarsift.user", JSON.stringify(values));
      userRef.current = values;
      setCurrentUser(values);
    } else {
      localStorage.removeItem("scholarsift.user");
      userRef.current = defaultUser;
      setCurrentUser(defaultUser);
    }
  };

  const allowRoute = (type) => {
    let ss = currentUser.scholarsift;
    if (ss === undefined) return false;
    if (ss.profile === null || ss.profile === undefined) return false;
    if (type === "paid") return ss.paid || (ss.limits && ss.limits.length > 0) || ss.roles === 3;
    if (type === "gadmin") return ss.isAdmin || ss.roles === 1 || ss.roles === 2;
    if (type === "admin") return ss.isAdmin;
    if (type === "lr") {
      if (ss.limits && ss.limits.length > 0) {
        if(Debug())
          console.log("Law Review User")
        return true;
      }
      if(ss.roles === 3) {
        if(Debug())
          console.log("Law Review Admin");
        return true;
      }
      if(ss.isAdmin) {
        if(Debug())
          console.log("SS Admin")
        return true;
      }
      if(Debug())
        console.log("Not LR");
      return false;
    }
    return true;
  };

  return (
    <MsalProvider instance={pca}>
      <UserContext.Provider
        value={{
          user: userRef,
          currentUser,
          saveUser,
          title: titleRef,
          currentTitle,
          setTitle,
          ws: wsRef,
          allowRoute,
          warningDisplayed,
          setWarningDisplayed
        }}
      >
        {children}
      </UserContext.Provider>
    </MsalProvider>
  );
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;
