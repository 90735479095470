import { useEffect, useState } from 'react';
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import ViewPort from '../Layout/ViewPort';
import useLocationState from '../../hooks/useLocationState';
import useUser from '../../hooks/useUser';
import { Debug, Submit } from 'ss-lib';
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';
import { ssToast } from '../../utils';

const SubmitComponent = props => {
    const { dispatchToast } = useToastController(ssToast);
    const { state } = useLocationState();
    const { user, saveUser, setTitle } = useUser();
    const [item, setItem] = useState({step: "type"});
    const [fetching, setFetching] = useState(false);
    const [journals, setJournals] = useState();
    const debug = Debug();

    useEffect(() => {
        setTitle("Submit a Paper");
        if(window.location.pathname.indexOf("submit") > -1)
            document.title = "ScholarSift - Submit a Paper";

        if(journals === undefined)
            GetJournals();
    })

    const GetJournals = () => {
        if(fetching)
            return ;

        setFetching(true);
        axios.get("/api/lslr", {
                    headers: {
                    Authorization: "Bearer " + user.current.token.id,
                    Coda: user.current.scholarsift.coda,
                    },
                })
                .then(res => {
                    if(debug)
                        console.log(res.data)
                    setJournals(res.data);
                    setFetching(false);
                })
                .catch(err => {
                    APIErrorHandler(err, user, saveUser, () => { setFetching(false); GetJournals(); });
                })
    }
    
    const ProcessFile = (f, type) => {
        if(debug) {
            console.log(f)
            console.log(type)
        }
        var data = new FormData();
        data.append("file", f);
        data.append("fn", f.name);
        axios.post("/api/chksub", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            if(debug)
                console.log(res)
            if(res.data.processing)
            {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name} is already in the Submission Queue</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            else if(res.data.Submitted) {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name + " " + res.data.Submitted}</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            var itm = {...res.data};
            itm.file = f;
            itm.step = "information";
            itm.type = type;
            if(debug)
                console.log(itm)
            setItem(itm);
        })
        .catch(err => { 
            if(debug) {
                console.log("Error")
                console.log(err)
            }
            APIErrorHandler(err, user, saveUser, () => { ProcessFile(f) });
        })
    }

    const SelectFile = e => {
        if(debug)
            console.log(e)
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.target.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.target.files[0], e.item.type);
    }
    
    const onDrop = e => {
        if(debug)
            console.log(e)
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.dataTransfer.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.dataTransfer.files[0], e.item.type);
    }

    const Clear = () => {
        setItem({step:"type"})
        setFetching(false)
    }

    const DoSubmit = (data) => {

        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Submitting {data.file.name}</ToastBody></Toast>, {intent: "info"});

        //Update This
        //Prep Form Data
        console.log("Journals:")
        console.log(journals);

        console.log("Original SUBMIT DATA:")
        console.log(data);

        //Set Metadata
        var md = {...data};

        var d = new FormData();
        if(md.cover) {
            d.append("cover", md.cover);
            md.cover = md.cover.name;
        }

        //Set File
        md.file = md.file.name;

        //Process Schools & Separate Info
        md.schools = md.schools.map((k, i) => {
            if(md[k]) {
                if(md[k].cover) {
                    d.append("cover", md[k].cover);
                    md[k].cover = md[k].cover.name;
                }
            }
            var j = journals.filter(v => v.name === k);
            var sd = {
                id: j[0].id,
                name: k
            }
            return sd;
        })

        //Process Authors
        const { authors } = md;
        for(var i = 0; i < authors.length; i++) {
            if(authors[i].cv && authors[i].cv instanceof File) {
                d.append("cv-" + i, authors[i].cv);
                authors[i].cv = authors[i].cv.name;
            }
        }

        //Add Metadata
        d.append("metadata", JSON.stringify(md));

        //Send & Process
        axios.post("/api/submit", d, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            Clear();

            //Toast
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have successfully submitted {data.title}</ToastBody></Toast>, {intent: "success"}) 
            
        })
        .catch(err => { 
            APIErrorHandler(err, user, saveUser, () => { DoSubmit(data) });
        })
    }

    if(!debug)
        return <div
                    className="submission-fill"
                    style={{
                    height: state.viewport.h - (state.viewport.f + state.viewport.m),
                    }}
                >
                    <div
                    className="loading"
                    style={
                        !props.management
                        ? {
                            height:
                                state.viewport.h - (state.viewport.f + state.viewport.m),
                            }
                        : undefined
                    }
                    >
                    We're signing up Law Reviews now, and are excited to show you this
                    feature in the near future.
                    </div>
                </div>

    if(debug)
        console.log(item)

    return  props.unwatched === undefined ? 
            <ViewPort>
                <div style={{ width:"80vw", minWidth:"400px", margin: "auto", minHeight: state.viewport.h - (state.viewport.m + state.viewport.f)}} >
                    <Submit 
                        item={item}
                        journals={journals ? journals.map(v => ({[v.name]:v.settings})) : []}
                        profile={user.current ? user.current.scholarsift : undefined}
                        Clear={Clear}
                        DoSubmit={DoSubmit}
                        SelectFile={SelectFile}
                        onDrop={onDrop}
                    />
                </div>
            </ViewPort> :
            <Submit 
                item={item}
                journals={journals}
                profile={user.current ? user.current.scholarsift : undefined}
                Clear={Clear}
                DoSubmit={DoSubmit}
                SelectFile={SelectFile}
                onDrop={onDrop}
            />
}

export default SubmitComponent;