import { useEffect, useRef } from 'react';
import useMeasure from 'react-use-measure';
import useLocationState from '../../hooks/useLocationState';

const ViewPort = props => {
    const [ref, { height }] = useMeasure();
    const { dispatch, state } = useLocationState();
    const h = useRef(0);

    useEffect(() => {
        let r = Math.ceil(height);
        if (h.current !== r) {
            h.current = r;
            dispatch({ type: 'path-key-update', path: 'viewport', key: 'v', payload: h.current });
        }

        return () => { dispatch({type: 'path-key-update', path:'viewport', key: 'v', payload: 0}) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height])

    return <div className="fixed-view-port" style={{top: state.viewport.m}}  ref={ref} >{props.children}</div>
}

export default ViewPort;