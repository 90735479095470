import axios from "axios";
import { updateStatus } from "./Analyze";
import { APIErrorHandler } from "./APIErrorHandler";

export const ProcessCV = (
  cv,
  account,
  user,
  saveUser,
  callBack,
  setInfo,
  setError,
  p,
  row
) => {
  let data = { cv: cv };
  if (account) data.profile = account;
  axios
    .post("/api/cv", data, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((result) => {
      if (p) {
        console.log(result.response);
        return callBack(p, row, result, "Process CV");
      }
      updateStatus(result, user, saveUser, callBack, setError, setInfo);
    })
    .catch((error) => {
      if (p) {
        console.log(error);
        return callBack(p, row, error.response, "Process CV");
      }
      APIErrorHandler(error, user, saveUser, (v) => {
        if (v === "login") {
          setError("login");
          return;
        }
        ProcessCV(cv, account, user, saveUser, callBack, setInfo, setError);
      });
    });
};

export const UpdatePaper = (
  user,
  saveUser,
  item,
  callBack,
  setInfo,
  setError,
  p
) => {
  let data = { ...item };

  axios
    .post("/api/nupdate", data, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((result) => {
      if (p) {
        console.log("made it here");
        return callBack(4, 1, result, "Update Paper");
      }
      callBack(result.data);
    })
    .catch((error) => {
      console.log(error.response);
      if (p) {
        return callBack(4, 1, error.response, "Update Paper");
      }
      APIErrorHandler(error, user, saveUser, (v) => {
        if (v === "login") {
          setError("login");
          return;
        }
        UpdatePaper(user, saveUser, item, callBack);
      });
    });
};

export const RemovePaper = (user, saveUser, item) => {
  axios
    .post("/api/nremove", item, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, (v) => {
        RemovePaper(user, saveUser, item);
      });
    });
};

export const UploadPaper = (
  user,
  saveUser,
  file,
  value,
  progressUpdate,
  callBack
) => {
  let fd = new FormData();
  fd.append("file", file);
  fd.append("cite", JSON.stringify(value));

  axios
    .post("/api/nupload", fd, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
      onUploadProgress: (p) => {
        progressUpdate(Math.round((100 * p.loaded) / p.total));
      },
    })
    .then((results) => {
      callBack(results.data);
    })
    .catch((error) =>
      APIErrorHandler(error, user, saveUser, () => {
        UploadPaper(user, saveUser, file, value, progressUpdate, callBack);
      })
    );
};

export const testUploadPaper = (
  user,
  saveUser,
  file,
  value,
  progressUpdate,
  callBack
) => {
  let fd = new FormData();
  fd.append("file", file);
  fd.append("cite", JSON.stringify(value));

  axios
    .post("/api/testupload", fd, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then((results) => {
      callBack(4, 0, results, "Upload/Edit Paper");
    })
    .catch((error) => callBack(4, 0, error.response, "Upload/Edit Paper"));
};
