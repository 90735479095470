const TextInputArea = ({
    label,
    name,
    cols,
    rows,
    placeholder,
    required,
    error,
    value,
    onChange }) => {

    return (
        <div className="basic-input-wrapper">
        <div className="basic-input-label">{label}</div>
            <textarea
                className="basic-textarea"
                placeholder={placeholder}
                required={required}
                errormessage={error}
                value={value}
                name={name}
                cols={cols ? cols : 80}
                rows={rows ? rows : 4}
                onChange={onChange}
            />
        </div>
    )
}

export default TextInputArea;