import { useEffect } from "react";
import { a, config, useSpring } from "react-spring";
import { IconButton } from "@fluentui/react";
import { Debug } from "ss-lib";

import Portal from "./Portal";

const Modal = ({ show, children, handleClose, type }) => {
  const spring = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? `translateY(0)` : `translateY(-10%)`,
    config: config.gentle,
  });

  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if(Debug()) console.log(type);
    const lightDismiss = (e) =>
      e.target.parentNode && e.target.parentNode.id === "portal-container"
        ? handleClose()
        : null; /* e.target.parentNode === "portal-container" ? handleClose() : null; */
    document.body.addEventListener("click", lightDismiss);
    return () => {
      document.body.removeEventListener("click", lightDismiss);
    };
  }, [handleClose, type]);

  if (!show) return null;

  return (
    <Portal wrapperId="portal-container">
      <a.div className="modal-overlay">
        <a.div className="modal-card" id={type} style={spring}>
          <a.div>
            <IconButton
              id="modal-dismiss"
              ariaLabel="Close popup modal"
              onClick={handleClose}
              iconProps={{ iconName: "Cancel" }}
              title="Close"
            />
          </a.div>
          <a.div id="modal-body">{children}</a.div>
        </a.div>
      </a.div>
    </Portal>
  );
};

export default Modal;
