import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { APIErrorHandler } from "../../actions/APIErrorHandler";
import SubmissionList from "./SubmissionList";
import useUser from "../../hooks/useUser";
import useLocationState from "../../hooks/useLocationState";
import axios from "axios";
import Loading from "../Layout/Loading";

const SubmissionPanel = (props) => {
  const location = useLocation();
  const { state } = useLocationState();
  const { user, saveUser } = useUser();
  const [list, setList] = useState();
  const [lr, setLR] = useState();
  const [isListing, setIsListing] = useState(false);

  const listSubmissions = (retries) => {
    if(isListing && !retries)
      return ;
    if (retries && retries > 5) return;

    if(!isListing)
      setIsListing(true);

    //Fetch List
    axios
      .post(
        "/api/submissions",
        { profile: props.account },
        {
          headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
          },
        }
      )
      .then((results) => {
        setList(results.data.submissions);
        if (results.data["law reviews"]) 
          setLR(results.data["law reviews"]);
        setIsListing(false);
      })
      .catch((error) => {
        APIErrorHandler(error, user, saveUser, () => {
          listSubmissions(retries ? retries + 1 : 1);
        });
      });
  };

  useEffect(() => {
    if (list === undefined)
      listSubmissions(0);
  });

  useEffect(() => {
    if(props.account)
      listSubmissions(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account]);

  if (location.pathname.indexOf("manage") > -1) {
    if (!props.selected) return null;
    if (props.account === undefined) return <div>Create account</div>;
  }
  if (list === undefined) return <Loading msg="Getting submissions" />;
  if (list.length === 0)
    return (
      <div
        className="loading"
        style={
          !props.management
            ? {
                height:
                  state.viewport.h - (state.viewport.f + state.viewport.m),
              }
            : undefined
        }
      >
        No submissions were found for your account
      </div>
    );
  return (
    <div
      className="submission-fill"
      style={{
        height: state.viewport.h - (state.viewport.f + state.viewport.m),
      }}
    >
      <SubmissionList items={list} lawreviews={lr} update={listSubmissions} />
    </div>
  );
};

export default SubmissionPanel;
