import { useEffect, useState } from 'react';
import useUser from '../../hooks/useUser';
import { Checkbox, MaskedTextField, PrimaryButton, TextField } from '@fluentui/react';
import axios from 'axios';

const Invest = props => {
    const { user } = useUser();
    const[name, setName] = useState(user.current && user.current.scholarsift ? user.current.scholarsift.name : undefined);
    const[email, setEmail] = useState(user.current && user.current.scholarsift ? user.current.scholarsift.email : undefined);
    const[phone, setPhone] = useState(user.current && user.current.scholarsift ? user.current.scholarsift.phone : undefined);
    const[accred, setAccred] = useState(false);
    const[verificationConsent, setVC] = useState(false);
    const[canSubmit, setCanSubmit] = useState(true);
    const[msg, setMsg] = useState();

    useEffect(() => {document.title = "Invest in ScholarSift"}); 

    const validatePhone = () => {
        var n = 0;
        for(var i = 0; i < phone.length; i++)
            if(phone[i] >= '0' && phone[i] <= '9')
                ++n;
        return n === 10;
    }

    const onClick = v => {
        setMsg('Submitting information');
        setCanSubmit(false);
        axios.post("/api/invest", {name: name, profile: user.current.scholarsift.profile, email: email, phone: phone, attestation: true})
            .then(result => { setMsg('Thank you for submitting your information. If you meet our criteria we will contact you with offering materials shortly.')})
            .catch(err => { setMsg('There was an error submitting your information')})
    }

    return  <div className="display-panel">
                <h2>ScholarSift LLC is collecting indications of interest in a Rule 506(c) offering open to <a href="https://www.sec.gov/smallbusiness/exemptofferings/faq#faq2">accredited investors</a> only. If you would like us to contact you for more information, please fill out the form below.</h2>
                <div className="center-wrapper">
                    <div className="invest-panel">
                        <TextField label="Name" aria-label="Name" value={name} onChange={v => setName(v.target.value)} />
                        <TextField label="Email" aria-label="Email" value={email} onChange={v => setEmail(v.target.value)} />
                        <MaskedTextField label="Phone" aria-label="Phone" mask="(999) 999 - 9999" title="A 10 digit number" onChange={v => setPhone(v.target.value) } value={phone}/>
                        <Checkbox className="invest-cb" label="I am an accredited investor" aria-label="Accredited Status Confirmation" checked={accred} onChange={v => setAccred(!accred)}/>
                        <Checkbox className="invest-cb" label="I am able and willing to document my accredited investor status to a third-party verification service" aria-label="Verification Consent" checked={verificationConsent} onChange={v => setVC(!verificationConsent)}/>
                        {
                            accred && 
                            verificationConsent && 
                            name && name.length && 
                            email && email.length && email.indexOf("@") > 0 && email.lastIndexOf(".") > email.indexOf("@") && email.lastIndexOf(".") !== email.length - 1 && 
                            validatePhone() &&  
                            <PrimaryButton text="Submit" onClick={onClick} disabled={!canSubmit} />
                        }
                    </div>
                    <div className="message-panel">
                        {msg}
                    </div>
                </div>
            </div>
}

export default Invest;