import { useEffect, useState } from "react";
import { v4 as uuid} from "uuid";
import StatusItem from './StatusItem';

const processStatus = (id, status, lawReviews, update) => {
    let ret = [];
    const m = new Map();
    status.forEach(v => {
        if(v.status === -1 && ret.length === 0)
            ret.push(<div key={uuid()}>Previously Published</div>)
        if(!m.has(v.status))
            m.set(v.status, []);
        m.get(v.status).push(lawReviews[v.pub]); 
    })
    for (const [key, value] of m)
        ret.push(<StatusItem id={id} type={key} details={value} key={uuid()} update={update}/>);
    return ret;
}

const Status = props => {
    const [status, setStatus] = useState(processStatus(props.id, props.status, props.lawreviews, props.update));

    useEffect(() => {
        setStatus(processStatus(props.id, props.status, props.lawreviews, props.update));
    }, [props.id, props.status, props.lawreviews, props.update])

    return  <div className="submitted-item-status">
                {status}
            </div>
}

export default Status;