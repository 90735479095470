import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import useLocationState from "../../../hooks/useLocationState";
import { ResponsiveBar } from "@nivo/bar";
import { UsageLabels } from "./index";
import { UpdateUserList } from "../../../actions/Dashboard";
import { delay } from "../../../utils";

const generateData = (state, items) => {
  let keys = new Set();
  let ret = new Map();

  if (state && state.list && items) {
    Object.keys(items).forEach((v) => {
      var name = state.list.filter((e) => Number(e.id) === Number(v));
      name.forEach((n) => {
        items[v].forEach((count, i) => {
          if (count) {
            let key = n.email + "_" + n.id;
            keys.add(key);
            if (!ret.has(UsageLabels[i])) ret.set(UsageLabels[i], {});
            let x = ret.get(UsageLabels[i]);
            x.index = UsageLabels[i];
            x[key] = count;
          }
        });
      });
    });
  }

  return { items: [...ret.values()], keys: [...keys] };
};

const UsageBarChart = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, saveUser } = useUser();
  const { state, dispatch } = useLocationState();
  const [data, setData] = useState({ items: [], keys: [] });
  const keyPath = location.pathname;

  useEffect(() => {
    if (state[keyPath]) setData(generateData(state[keyPath], props.items));
    else {
      UpdateUserList(user, saveUser, (v) => setState("list", v), fatalError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state, props.items]);

  const setState = (key, value) => {
    dispatch({
      type: "path-key-update",
      path: keyPath,
      key: key,
      payload: value,
    });
  };

  const fatalError = (e, callBack) => {
    if (e === "login") {
      dispatch({
        type: "message-update",
        key: "info",
        payload: "Please login again.",
      });
      navigate("/signin", { goback: true, callBack: callBack });
    } else if (e === "server down") {
      let ct = 0;
      while (ct < 30)
        // eslint-disable-next-line no-loop-func
        delay(1000).then(() => {
          ct++;
          dispatch({
            type: "message-update",
            key: "error",
            payload: "Server is down.  Retrying in..." + (30 - ct),
          });
        });
      callBack();
    }
  };

  if (!props.items) return null;

  /*     <div
      className="usage-report"
      onClick={(e) => props.toggleSize(e, props.index)}
      key={props.index}
      name={props.index}
    >
      <div className={props.displayClass}> */

  /* <VerticalStackedBarChart /> */

  return (
    <ResponsiveBar
      valueScale={{ type: props.checked ? "symlog" : "linear" }}
      labelSkipHeight={12}
      indexBy="index"
      data={data.items}
      groupMode="stacked"
      keys={data.keys}
      layout={props.layout}
      margin={{ top: 10, right: 0, bottom: 30, left: 0 }}
      padding={0.2}
      innerPadding={0}
      colors={{ scheme: "set2" }}
      /* motionConfig={{ mass: 1, tension: 170, friction: 26 }}
          onClick={props.toggleSize}
          data={data.items}
          keys={data.keys}
          indexBy="index"
          margin={{ top: 10, right: 0, bottom: 70, left: 0 }}
          padding={0.5}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{
            scheme: "set2",
          }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legendPosition: "middle",
            legendOffset: 32,

          }}
          axisLeft={null}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
         */
    />
    /*       </div>
    </div> */
  );
};

export default UsageBarChart;
/*
import { TickFormatter, UsageLabels } from './index'

const getSeries = items => {
    let keys = Object.keys(items);
    let d = {max: 0, series:[]};

    keys.forEach((v, i) => {
        let arr = items[v];
        let a2 = [];
        arr.forEach((vv, ii) => {
            if(vv) {
                a2.push({x: UsageLabels[ii].toString(), y:vv})
            }
        })

        if(a2.length)
        {
            if(a2.length > d.max)
                d.max = a2.length;
            d.series.push(<VerticalBarSeries data={a2} key={i}/>);    
        }    
    });
    
    return d;
}

const C = (props) => {
    if(props.items === null || props.items === undefined)
        return null;

    console.log(props.items);

    let data = getSeries(props.items);
    if(data.series.length === 0)
        return null;

    return  <div className="usage-day">
                <div className="usage-title">{props.name}</div>
                    <XYPlot width={100 * (data.max + .1)} height={300} margin={{bottom:50}} xType="ordinal" stackBy="y">
                        <VerticalGridLines/>
                        <HorizontalGridLines/>
                        <XAxis tickFormat={TickFormatter}/>
                        <YAxis/>
                        {data.series}
                    </XYPlot>
            </div>;
}

export default C;
*/
