import { useState, useEffect } from "react";

import ViewPort from "./Layout/ViewPort";
import Logo from "./Layout/Logo/Logo";
import ReverseLogo from "./Layout/Logo/ReverseLogo";
import { useWindowDimensions } from "ss-lib";

const StudentAmbassador = () => {
  const [isScroll, setIsScroll] = useState(true);

  const { width, height } = useWindowDimensions();

  return (
    <ViewPort
      style={{
        height: height,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: width,
          height: height - 133,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: isScroll ? "#fff" : "var(--darkest-blue)",
            width: width,
            maxHeight: "45%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isScroll ? (
            <Logo
              style={{
                width: "100%",
                maxWidth: 900,
                margin: "auto",
              }}
            />
          ) : (
            <ReverseLogo
              style={{
                width: "100%",
                maxWidth: 900,
                margin: "auto",
              }}
            />
          )}
        </div>
        <div
          style={{
            width: 850,
            maxWidth: "100%",
            backgroundColor: "var(--dark-blue)",
            margin: "-40px auto",
            //must create mix-in for this
            color: "#FFF",
            boxShadow: "var(--box-shadow)",
            padding: "20px 40px",
            borderRadius: "4px",
          }}
        >
          <h2
            className="display-title"
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: "24pt",
            }}
          >
            Be a ScholarSift Student Ambassador
          </h2>
          <p
            style={{
              textAlign: "center",
              fontSize: "14pt",
              lineHeight: 1.5,
              fontWeight: 600,
              width: "90%",
              margin: "auto",
            }}
          >
            Join ScholarSift's Student Ambassador Program and help us build the
            future of legal scholarship.
          </p>
        </div>
        <div
          style={{
            width: 850,
            maxWidth: "100%",
            backgroundColor: "#FFF",
            margin: "80px auto",
            //must create mix-in for this
            color: "var(--dark-blue)",
            boxShadow: "var(--box-shadow)",
            padding: "40px 0 0 0",
            borderRadius: "4px",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: "15.75pt",
              color: "var(--darkest-blue)",
            }}
          >
            Ready to impact legal research? Submit your resume to
            ______@scholarsift.com
          </h3>
          <div style={{ padding: "50px 40px 80px 100px" }}>
            <h3 style={{ fontWeight: 700, fontSize: "16pt" }}>Benefits</h3>
            <ul style={{ margin: "auto 80px 30px 80px" }}>
              <li
                style={{
                  fontWeight: 400,
                  fontSize: "14pt",
                  lineHeight: 1.75,
                  color: "#000",
                  listStyleType: "none",
                }}
              >
                Develop leadership and communication skills
              </li>
              <li
                style={{
                  fontWeight: 400,
                  fontSize: "14pt",
                  lineHeight: 1.75,
                  color: "#000",
                  listStyleType: "none",
                }}
              >
                Network with students on a national scale
              </li>
              <li
                style={{
                  fontWeight: 400,
                  fontSize: "14pt",
                  lineHeight: 1.75,
                  color: "#000",
                  listStyleType: "none",
                }}
              >
                Showcase your legal research expertise to potential employers
              </li>
              <li
                style={{
                  fontWeight: 400,
                  fontSize: "14pt",
                  lineHeight: 1.75,
                  color: "#000",
                  listStyleType: "none",
                }}
              >
                Exclusive access to ScholarSift perks and newest features
              </li>
            </ul>
            <div>
              <h3 style={{ fontWeight: 700, fontSize: "16pt" }}>Duties</h3>
              <ul style={{ margin: "auto 80px 30px 80px" }}>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Spread the word about ScholarSift's innovative features
                  through in-person and virtual community engagement
                </li>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Foster a community of knowledge-sharing among peers
                </li>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Collaborate with the ScholarSift team to enhance the student
                  user experience
                </li>
              </ul>
            </div>
            <div>
              <h3 style={{ fontWeight: 700, fontSize: "16pt" }}>
                Requirements
              </h3>
              <ul style={{ margin: "auto 80px 30px 80px" }}>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  1L/2L at an accredited law school
                </li>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Passion for legal research and writing
                </li>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Familiar with Bluebook citation rules
                </li>
                <li
                  style={{
                    fontWeight: 400,
                    fontSize: "14pt",
                    lineHeight: 1.75,
                    color: "#000",
                    listStyleType: "none",
                  }}
                >
                  Journal and/or Law Review memberships is a plus
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ViewPort>
  );
};

export default StudentAmbassador;
